import { memo } from 'react';
import { TwilioProvider } from 'lib/twilio';
import { MainLayout } from 'components/atoms';
import { AuthProvider } from 'contexts/auth/AuthContext';
import { WorkspaceListSidebar } from 'components/organisms/navigation';

function RestoreLayout({ children }: any) {
  return (
    <AuthProvider>
      <TwilioProvider>
        <MainLayout>
          <WorkspaceListSidebar />
          <div>{children}</div>
        </MainLayout>
      </TwilioProvider>
    </AuthProvider>
  );
}

export default memo(RestoreLayout);
