import { Menu } from 'antd';
import { useContext, memo } from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom';

import * as path from 'constants/routes';
import { getRoleId, roles } from 'constants/roles';
import { AuthContext } from 'contexts/auth/AuthContext';
import { KrispcallVersionMark } from 'components/molecules/krispcall-version';

import {
  UserSquare,
  CallIcon,
  SimcardIcon,
  BlockIcon,
  MemberIcon,
  MonitorIcon,
  BillIcon,
  HeadphoneIcon,
  NotifyIcon,
  TeamIcon,
  CreditIcon,
  TagIcon,
  GlobeIcon,
  IntegrationIcon,
  DeveloperIcon,
  ReportsIcon,
  BugIcon,
} from 'components/atoms/icon';
import * as S from './Styles';

import { sidebar } from './menus';

function SettingsSidebar() {
  const history = useHistory();
  const { accessToken, isSubscriptionExpired } = useContext(AuthContext);
  const location = useLocation();

  const accessTokenDecoded = accessToken && jwtDecode<any>(accessToken);
  const role: string = accessTokenDecoded?.user_claims?.role;

  const roleID = getRoleId(role);
  const protectedPath = [path.CREDIT];

  const onMenuItemClick = ({ key, item }: any) => {
    const openUrl = item?.props?.['data-open-url'];
    if (openUrl) {
      return window.open(openUrl, '_blank');
    }
    if (isSubscriptionExpired) {
      return history.replace(`/settings${path.PLAN_SUBS}`);
    }
    history.replace(`/settings${key}`);
    // FORCE REFRESH TO PREVENT USER FROM VIEWING THE PAGE IF BLOCKED IN CLOUDFARE
    if (process.env.REACT_APP_TESTING_ENV && protectedPath.includes(key)) history.go(0);
    return null;
  };

  const mapIcon = (key: string) => {
    const icons: any = {
      [path.PROFILE]: UserSquare,
      [path.NON_VOIP_NUMBERS]: SimcardIcon,
      [path.NUMBERS]: CallIcon,
      [path.MEMBERS]: MemberIcon,
      [path.BLOCK]: BlockIcon,
      [path.TEAM]: TeamIcon,
      [path.TAGS]: TagIcon,
      [path.WORKSPACE]: MonitorIcon,
      [path.CREDIT]: CreditIcon,
      [path?.PLAN_SUBS]: BillIcon,
      [path?.NONVOIP_SUBS]: BillIcon,
      [path.DEVICES]: HeadphoneIcon,
      [path.NOTIFICATION]: NotifyIcon,
      [path.REPORTS]: ReportsIcon,
      [path.LANGUAGE_TIMEZONES]: GlobeIcon,
      [path.INTEGRATION]: IntegrationIcon,
      [path.DEVELOPER]: DeveloperIcon,
      bugReport: BugIcon,
    };
    return icons[key];
  };

  return (
    <S.SettingsMenu className='settings-menu'>
      <div className='sticky sticky-header'>
        <h6
          data-cy='setting-menu-title'
          className='text-lg text-gray-600 font-bold leading-6 mb-4.75 pl-7 lg:pl-2'
        >
          Settings
        </h6>
      </div>
      <Menu
        className={isSubscriptionExpired ? 'cancel-subscription' : ''}
        defaultOpenKeys={['/profile']}
        selectedKeys={[`/${location.pathname.split('/')[2]}`]}
        onClick={onMenuItemClick}
        mode='inline'
      >
        {sidebar?.map((item, index) => {
          const isLastItem = sidebar.length - 1 === index;
          const shouldHideGroup = !item.roleList.includes(roleID) || item.hidden;

          if (shouldHideGroup) {
            return null;
          }

          return (
            <Menu.ItemGroup
              key={item.group}
              title={item.group}
              className={isLastItem ? 'mb-4' : 'mb-1'}
            >
              {item?.menus?.map(menuItem => {
                const hasNonVoipNumber = menuItem?.key === path.NON_VOIP_NUMBERS;
                const shouldHideItem =
                  (roleID === roles.MEMBER && menuItem?.isProtected) || menuItem?.hidden;
                if (shouldHideItem) {
                  return null;
                }
                const Icon = mapIcon(menuItem?.key);
                return (
                  <Menu.Item
                    className='flex items-center'
                    key={menuItem?.key}
                    icon={<Icon />}
                    data-open-url={menuItem?.openUrl} // Pass openUrl as a data attribute
                  >
                    <h6 className='text-13 text-gray font-semibold truncate'>{menuItem?.label}</h6>
                    {hasNonVoipNumber && <span className='tag-new ml-3'>New</span>}
                  </Menu.Item>
                );
              })}
            </Menu.ItemGroup>
          );
        })}
      </Menu>
      <KrispcallVersionMark className='mt-auto ml-2 ' />
    </S.SettingsMenu>
  );
}

export default memo(SettingsSidebar);
