import { useState, useEffect } from 'react';
import uuid from 'components/utils/uuid';

/**
 * useDeviceId is a hook that retrieves a deviceId from local storage or generates a new one.
 *
 * @returns {Object} An object with two properties:
 * - deviceId: The retrieved or generated unique identifier.
 * - generateDeviceId: A function that generates a new unique identifier and stores it.
 */
export function useDeviceId() {
  const [deviceId, setDeviceId] = useState<string>(() => {
    try {
      return localStorage.getItem('deviceId') || '';
    } catch {
      return '';
    }
  });

  const generateDeviceId = () => {
    const newId = uuid();
    localStorage.setItem('deviceId', newId);
    setDeviceId(newId);
    return newId;
  };

  useEffect(() => {
    if (!deviceId) {
      generateDeviceId();
    }
  }, [deviceId]);

  return { deviceId, generateDeviceId };
}
