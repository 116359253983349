import AutoCompleteField from 'components/molecules/fields/AutoCompleteField';
import { Tooltip } from 'components/atoms';

import { AutoCompleteFieldWrapper, Wrapper } from './Styles';
import { ContactAutoCompleteOption } from './ContactAutoCompleteOption';
import CountrySelect from '../country-select';
import usePhoneInputContext from '../../hooks/usePhoneInputContext';

const PhoneAutoCompleteInput = () => {
  const {
    searchedText,
    control,
    handleSearch,
    handleSelect,
    autoCompleteContacts,
    selectedContact,
    onLoadMore,
  } = usePhoneInputContext();

  const options = autoCompleteContacts?.map((data: any) => {
    const { number, profilePicture, name, id } = data?.node || {};
    return {
      key: id,
      value: number,
      label: (
        <ContactAutoCompleteOption
          number={number}
          searchedText={searchedText}
          name={name}
          profilePicture={profilePicture}
        />
      ),
      data: { name, number },
    };
  });

  const handleOnPopupScroll = (event: any) => {
    event.preventDefault();
    onLoadMore?.();
  };

  return (
    <Wrapper>
      <CountrySelect />
      <AutoCompleteFieldWrapper>
        <AutoCompleteField
          placeholder='Enter phone number or contact name'
          control={control}
          name='number'
          options={searchedText ? options : []}
          onSelect={handleSelect}
          onSearch={handleSearch}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ minWidth: 293 }}
          listHeight={300}
          onPopupScroll={handleOnPopupScroll}
        />
      </AutoCompleteFieldWrapper>
      {selectedContact?.name && (
        <Tooltip title={selectedContact?.name}>
          <div className='client-name w-48 truncate text-center'>{selectedContact?.name}</div>
        </Tooltip>
      )}
    </Wrapper>
  );
};
export default PhoneAutoCompleteInput;
