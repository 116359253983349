import { onError } from '@apollo/client/link/error';
import SecureLS from 'secure-ls';
import { datadogLogs } from '@datadog/browser-logs';

import { encodeValueFernet, encryptKeysDatadog } from 'services/datadog/helper';
import { useHandleTokenExpiry } from './useHandleTokenExpiry';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const getUserInfoLocalstorage = () => {
  const user = ls.get('user');
  const userObj = user ? JSON.parse(user) : {};
  const lsEmail = userObj?.details?.userProfile?.email;
  return { lsEmail };
};

export default function useErrorLink() {
  const { handleLogoutWithoutHook } = useHandleTokenExpiry();
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: any) => {
    const { operationName, variables } = operation || {};
    const authorizationToken = operation.getContext().headers?.authorization;
    const { lsEmail } = getUserInfoLocalstorage();

    if (process.env.NODE_ENV === 'production' && graphQLErrors) {
      datadogLogs.logger.error(`GraphQL Error ${operationName} `, {
        dataDogError: graphQLErrors,
        context: 'graphql error',
        requestPayload: {
          operationName,
          variables: encryptKeysDatadog(variables),
        },
        authorizationToken: encodeValueFernet(authorizationToken),
        lsEmail,
      });
    }

    if (graphQLErrors?.length > 0 && graphQLErrors[0]?.extensions?.code === 'Expired') {
      handleLogoutWithoutHook({
        event: graphQLErrors[0]?.extensions?.code,
        message: 'GraphqlError Token Expired',
        additionalInfo: {
          requestPayload: {
            operationName,
            variables: encryptKeysDatadog(variables),
          },
          authorizationToken: encodeValueFernet(authorizationToken),
        },
      });
    }
    if (networkError) {
      const { status, error } = networkError?.result || {};
      if (
        status === 401 &&
        (error?.error_key === 'token_expired' || error?.errorKkey === 'token_expired')
      ) {
        // HANDLE_LOGOUT_FOR_DUPLICATE_LOGIN
        handleLogoutWithoutHook({
          event: error?.error_key,
          message: 'NetworkError Token Expired',
          additionalInfo: {
            requestPayload: {
              operationName,
              variables: encryptKeysDatadog(variables),
            },
            authorizationToken: encodeValueFernet(authorizationToken),
          },
        });
        return forward(operation);
      }
      datadogLogs.logger.error(`Network Error ${operationName} `, {
        errorMessage: networkError?.message,
        datadogError: { error: networkError, errorMessage: networkError?.message },
        context: 'network error',
        requestPayload: {
          operationName,
          variables: encryptKeysDatadog(variables),
        },
        authorizationToken: encodeValueFernet(authorizationToken),
        lsEmail,
      });
    }
    return forward(operation);
  });

  return {
    errorLink,
  };
}
