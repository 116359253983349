// import './wdyr';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import { Crisp } from 'crisp-sdk-web';

import { useEffect } from 'react';
import theme from 'themes';
import { App } from 'components';
import useApolloService from 'services/apollo';
import GlobalStyles from 'assets/globalStyles';
import FallbackSpinner from 'components/atoms/spinner/Spinner';
import { isProduction } from 'services/tracking-and-reporting';
import { initDatadog } from 'services/datadog';
import { initPartnerStack } from 'services/partner-stack';
import { useChatBot } from 'hooks/useChatBot';

import './i18n';
import { version } from '../package.json';
import { CacheBuster } from './CacheBuster';
import 'assets/styles/antd.less';

const tagGm = process.env.REACT_APP_GOOGLE_TAG_MANAGER;
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
const DATADOG_APP_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID;
const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const PARTNER_STACK_KEY = process.env.REACT_APP_PS_KEY;

export default function Index() {
  // generate and save deviceId in localstorage during app load
  const { client } = useApolloService();

  const crispId = process.env.REACT_APP_CRISP_WEBSITE_ID;
  const { initCrispChat } = useChatBot();

  useEffect(() => {
    if (tagGm) {
      TagManager.initialize({
        gtmId: tagGm,
      });
    }
    if (mixpanelToken) {
      if (process.env.REACT_APP_DEBUG) console.log('Initializing mixpanel panel');
      mixpanel?.init(mixpanelToken);
      mixpanel?.track('Web App Page View', {
        'Page Path': window.location.pathname,
      });
    }
    if (DATADOG_APP_ID && DATADOG_CLIENT_TOKEN) {
      initDatadog();
    }

    if (crispId) {
      initCrispChat(undefined);
    }

    if (PARTNER_STACK_KEY) {
      initPartnerStack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  Crisp.session.onLoaded = (sessionId: any) => {
    console.log('crisp session', sessionId);
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        <ThemeProvider theme={theme.default}>
          <GlobalStyles />
          <CacheBuster
            currentVersion={version}
            isEnabled={isProduction}
            isVerboseMode={true}
            loadingComponent={<FallbackSpinner />}
          >
            <App />
          </CacheBuster>
        </ThemeProvider>
      </Router>
    </ApolloProvider>
  );
}
