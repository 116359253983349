import { useTranslation } from 'react-i18next';

import { Button } from 'components/atoms';
import { refreshPage } from 'components/utils/helpers';
import NotFound from 'assets/images/404.png';
import ServerErrorImg from 'assets/images/500.png';

import ErrorPage from './ErrorPage';

interface PageNotFoundProps {
  action?: () => void;
}

export const PageNotFoundError = ({ action }: PageNotFoundProps) => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      title={t('errorPage.title.404', '404 Error')}
      message={t(
        'errorPage.note.404',
        'Looks like the link is either broken or the page doesn’t exist. Please try again at a later time.',
      )}
      extra={
        <Button type='primary' size='large' onClick={action}>
          {t('takeMeHome', 'Take me Home')}
        </Button>
      }
      wrapperClassName='not-found'
      imageSrc={NotFound}
    />
  );
};

interface ServerErrorProps {
  action?: () => void;
  hasFeedback?: boolean;
  onReport?: () => void;
}
export const ServerError = ({ action, hasFeedback, onReport }: ServerErrorProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div />
      <ErrorPage
        title={t('errorPage.title.server', 'Internal Server Error')}
        message={t(
          'errorPage.note.server',
          'Relax! It’s not you, it’s us. Something seems to be wrong with our server. Please try again later.',
        )}
        extra={
          <Button type='primary' size='large' onClick={action || refreshPage}>
            {t('refresh', 'Refresh')}
          </Button>
        }
        wrapperClassName='server-error'
        imageSrc={ServerErrorImg}
        hasFeedback={hasFeedback}
        onReport={onReport}
      />
    </>
  );
};
