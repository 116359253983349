/* eslint-disable no-param-reassign */
import { datadogLogs } from '@datadog/browser-logs';
import { produce } from 'immer';
import fernet from 'fernet';

interface AnyObject {
  [key: string]: any;
}

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// LOG ERROR IN DATADOG
export const logInfoDatdog = (err: any, additionalInfo: any) => {
  datadogLogs.logger.warn(additionalInfo?.errText, {
    dataDogError: err,
    additionalInfo,
    context: additionalInfo?.context,
  });
};

export const encodeValueFernet = (value?: string) => {
  if (!value) return null;
  const secret = new fernet.Secret(SECRET_KEY);
  const token = new fernet.Token({
    secret,
    ttl: 0,
  });
  return token.encode(value);
};

// Recursive function to find and encrypt matching keys
export const encryptKeysDatadog = (obj: AnyObject): AnyObject => {
  const keysToEncrypt = [
    'password',
    'passwordConfirm',
    'oldPassword',
    'newPassword',
    'newPasswordConfirm',
    'body',
    'authToken',
    'token',
  ];
  return produce(obj, draft => {
    const recurse = (current: AnyObject): void => {
      Object.keys(current).forEach(key => {
        if (keysToEncrypt.includes(key) && typeof current[key] === 'string') {
          current[key] = encodeValueFernet(current[key]);
        } else if (typeof current[key] === 'object' && current[key] !== null) {
          recurse(current[key]);
        }
      });
    };
    recurse(draft);
  });
};
