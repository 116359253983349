import { useHistory } from 'react-router-dom';
import SecureLS from 'secure-ls';
import Cookies from 'js-cookie';
import { Crisp } from 'crisp-sdk-web';

import { LOGIN } from 'constants/routes';
import { useChatBot } from './useChatBot';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });
const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;

export function useClearAllSession() {
  const { resetCrispSession } = useChatBot();

  const clearAllUserSession = async () => {
    const sessionId = Crisp?.session?.getIdentifier();
    const deviceId = localStorage.getItem('deviceId');
    const hideStatusChangePrompt = localStorage.getItem('hideStatusChangePrompt');
    const dailerDefaultCountry = localStorage.getItem('_dialer-default-country');
    const crispSessionSentMsgInfo = localStorage.getItem(`_kc-im-${sessionId}`);
    Cookies.remove(`${COOKIE_PREFIX}_kc_auth_tk`);
    ls.clear();
    sessionStorage.clear();
    if (deviceId && deviceId !== 'null') localStorage.setItem('deviceId', deviceId as string);
    localStorage.setItem('hideStatusChangePrompt', hideStatusChangePrompt as string);
    localStorage.setItem(`_kc-im-${sessionId}`, crispSessionSentMsgInfo as string);
    if (dailerDefaultCountry)
      localStorage.setItem('_dialer-default-country', dailerDefaultCountry as string);
    window.location.replace(`${LOGIN}`);
    resetCrispSession();
  };
  return {
    clearAllUserSession,
  };
}
