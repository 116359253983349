import { useRef } from 'react';
import { Call } from '@twilio/voice-sdk';
import { useReactiveVar } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';

import {
  activeCampaignCallsDurationVar,
  activeCampaignDurationVar,
  campaignReattemptVar,
  isNetworkAvailable,
} from 'services/apollo/reactiveVars';
import { ToastMessage } from 'components/atoms';

import { ACTIONS, callEvent, CALL_WIDGET_STATUS, CAMPAIGN_STATUS } from '../constants';
import { getCallParams } from '../utils';
import { TWILIO_ERRORS } from '../error';

interface IProps {
  dispatch: React.Dispatch<any>;
  state: any;
}

export function useIncomingCallHandler(props: IProps) {
  const { dispatch, state } = props;

  const callStateRef: any = useRef();
  const activeTransferToId: any = useRef();
  const unSavedCallWidgetActionRef = useRef<boolean>();
  const reattemptCampaignVisibleRef = useRef<boolean>();
  const internetConnectionRef = useRef<boolean>();

  const { t } = useTranslation();
  const reattemptCampaignVisible = useReactiveVar<any>(campaignReattemptVar);
  const internetConnection = useReactiveVar(isNetworkAvailable);

  callStateRef.current = state;
  reattemptCampaignVisibleRef.current = reattemptCampaignVisible;
  internetConnectionRef.current = internetConnection;

  const handleDeviceIncoming = (call: Call) => {
    const { showPhoneWidget, showDialer, callEnded, salesDialerWidget, campaignStatus } =
      callStateRef.current || {};
    const isCampaignCall = call?.customParameters?.get('isCampaignCall');
    if (
      (salesDialerWidget && !isCampaignCall && campaignStatus !== CAMPAIGN_STATUS.PAUSED) ||
      reattemptCampaignVisibleRef.current
    ) {
      // IGNORE INCOMING CALL IF POWER DIALER WIDGET IS OPEN AND CAMPAIGN IS NOT IN PAUSED STATE
      // IGNORE INCOMING CALL IF POWER DIALER CAMPAIGN REATTEMPT MODAL IS OPEN
      call.reject();
      return;
    }
    if (showPhoneWidget && !showDialer && !isCampaignCall && !callEnded) {
      // IGNORE INCOMING CALL IF PHONE WIDGET IS OPEN FOR NORMAL VOICE CALL
      call.ignore();
      return;
    }
    call.on(callEvent.CANCEL, () => {
      dispatch({
        type: ACTIONS.INCOMING_CALL_CANCEL,
        callWidgetVisible: unSavedCallWidgetActionRef.current,
      });
    });

    call.on(callEvent.DISCONNECT, async (conn: any) => {
      if (isCampaignCall) {
        if (!internetConnectionRef.current) {
          dispatch({
            type: ACTIONS.CAMPAIGN_DISCONNECT_INTERNET,
            data: conn,
          });
          return;
        }
        const { nextCallQueue, prevCallSkipped, nextQueueAfterSkip } = callStateRef.current || {};
        const { id: nextSkippedCallId } = nextCallQueue || {};
        // Check if the user has skipped the call at list once and has next call in queue
        const nextQueue = conn?.customParameters?.get('nextQueue');
        const isReattemptedCampaign = conn?.customParameters?.get('isReattempt');
        // nextQueue is not updated in conn data received in this disconnect callback fn after skip.
        // So, its latest value is maintained in our local state
        const nextCampaignCallQueueId = prevCallSkipped ? nextQueueAfterSkip : nextQueue;
        // call is marked as last campaign call if nextCampaignCallQueueId is null or none
        const isLastCampaignCall = !nextCampaignCallQueueId || nextCampaignCallQueueId === 'None';
        if (isLastCampaignCall) {
          activeCampaignDurationVar(0); // reset campaign duration on campaign end
          activeCampaignCallsDurationVar(0); // reset campaign dialed calls duration on campaign end
          dispatch({
            type: ACTIONS.CAMPAIGN_COMPLETED,
            data: conn,
          });
          if (isReattemptedCampaign !== 'True') campaignReattemptVar(true);
          return;
        }
        dispatch({
          type: ACTIONS.CAMPAIGN_CALL_ENDED,
          data: {},
        });
        return;
      }
      dispatch({
        type: ACTIONS.INCOMING_CALL_DISCONNECT,
        callWidgetVisible: unSavedCallWidgetActionRef.current,
      });
    });

    call.on(callEvent.ACCEPT, (conn: any) => {
      if (conn?.customParameters?.get('isCampaignCall')) return;
      dispatch({
        type: ACTIONS.ANSWER_INCOMING_CALL,
      });
    });

    call.on(callEvent.REJECT, () => {
      dispatch({
        type: ACTIONS.REJECT_INCOMING_CALL,
        updateConversationStatus: true,
      });
    });

    call.on(callEvent.ERROR, (error: any) => {
      dispatch({
        type: ACTIONS.INCOMING_CALL_ERROR,
        status: 'error',
      });
      datadogLogs.logger.error('Twilio Error : Incoming Call', {
        datadogError: error,
        additionalInfo: {
          callParams: getCallParams(call),
        },
        context: 'call',
      });
      if (TWILIO_ERRORS[error.code] !== undefined) {
        ToastMessage({
          content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
          type: 'danger',
        });
      }
    });
    call.on('messageReceived', message => {
      const { status, message: callStatusMessage } = message?.content || {};
      if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED) {
        dispatch({
          type: ACTIONS.CALL_TRANSFER_FAILED,
          data: {
            transferTo: activeTransferToId.current,
          },
        });
      }

      if (status === CALL_WIDGET_STATUS.CAMPAIGN_CALL_CONNECTED) {
        dispatch({
          type: ACTIONS.CAMPAIGN_CALL_CONNECTED,
          data: {
            voicemailDropEnabled: true,
          },
        });
      }

      if (status === CALL_WIDGET_STATUS.CAMPAIGN_CALL_DISCONNECTED) {
        dispatch({
          type: ACTIONS.CAMPAIGN_CALL_DISCONNECTED,
          data: callStatusMessage,
        });
      }
    });
    if (isCampaignCall) {
      call?.accept();
      dispatch({
        type: ACTIONS.CAMPAIGN_CALL_INITATED,
        data: call,
        channelId: call?.customParameters?.get('channel_sid'),
      });
      return;
    }
    dispatch({
      type: ACTIONS.INCOMING_CALL,
      data: call,
      channelId: call?.customParameters?.get('channel_sid'),
    });
  };

  return {
    handleDeviceIncoming,
  };
}
