import { useRef } from 'react';
import { Device } from '@twilio/voice-sdk';
import { useReactiveVar } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';

import uuid from 'components/utils/uuid';
import useAppContext from 'hooks/useAppContext';
import { useTriggerMixpanelEvent } from 'services/mixpanel/hooks/useTriggerMixpanelEvent';
import { MixpanelEvent, MixpanelPayload } from 'services/mixpanel/types';

import {
  isNetworkAvailable,
  transferToVar,
  webRtcPeerConnectionEnabled,
} from 'services/apollo/reactiveVars';
import { ToastMessage } from 'components/atoms';
import useCallMessageEligibilityCheck, {
  EligibilityCheckPayload,
} from 'hooks/useCallMessageEligibilityCheck';
import { parsePhoneNumber } from 'components/utils/phone-lib';

import useWebRtcTest from './useWebRtcTest';
import { ACTIONS, callEvent, CALL_WIDGET_STATUS } from '../constants';
import { OutgoingProps } from '../types';
import { getCallParams } from '../utils';
import { TWILIO_ERRORS } from '../error';

interface IProps {
  dispatch: React.Dispatch<any>;
  deviceInstance: Device | null;
}

export function useOutgoingCallHander(props: IProps) {
  const { dispatch, deviceInstance } = props;

  const webRtcPeerConnectionRef: any = useRef();
  const activeTransferToId: any = useRef();
  const unSavedCallWidgetActionRef = useRef<boolean>();
  const internetConnectionRef = useRef<boolean>();

  const { t } = useTranslation();
  const { unSavedCallWidgetAction } = useAppContext();
  const { checkPeerConnection } = useWebRtcTest();
  const { checkCallMessageEligibility } = useCallMessageEligibilityCheck();
  const { trackActivityInMixpanel } = useTriggerMixpanelEvent();

  const webRtcPeerConnection = useReactiveVar<any>(webRtcPeerConnectionEnabled);
  const internetConnection = useReactiveVar(isNetworkAvailable);

  webRtcPeerConnectionRef.current = webRtcPeerConnection;
  activeTransferToId.current = useReactiveVar(transferToVar);
  unSavedCallWidgetActionRef.current = unSavedCallWidgetAction;
  internetConnectionRef.current = internetConnection;

  const handleDeviceOutgoing = async (params: OutgoingProps) => {
    if (deviceInstance) {
      if (!internetConnectionRef.current) {
        ToastMessage({
          content: `${t(
            'toast.callFailedNoInternetConnection',
            "Can't make call. Please check your internet connection",
          )}`,
          type: 'danger',
        });
        return;
      }
      checkPeerConnection();
      if (!webRtcPeerConnectionRef.current) {
        ToastMessage({
          content: t(
            'error.networkConnectivityError',
            'Unable to connect. Some third party tools might be affecting your network connectivity',
          ),
          type: 'danger',
        });
        return;
      }
      if (deviceInstance?.state === 'destroyed') {
        ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
        datadogLogs.logger.error('Twilio Error : Unable to make Outbound Call', {
          datadogError: {
            message: 'Device is offline. Unable to make Outbound Call',
            description: 'DeviceInstance state is destroyed',
          },
          additionalInfo: {
            callParams: {
              parameters: params,
            },
          },
          context: 'twilio device',
        });
        return;
      }
      const eligibilityCheckPayload: EligibilityCheckPayload = {
        originationNumber: params.From || '',
        destinationNumber: params.To || '',
        eventType: 'Call',
      };
      const isEligibleForCall = await checkCallMessageEligibility(eligibilityCheckPayload);
      if (!isEligibleForCall) return;
      const callParams: any = {
        ...params,
        From: parsePhoneNumber(params?.From || '')?.number,
        To: parsePhoneNumber(params?.To || '')?.number,
        hubspot_client: true,
        conversation_sid: `CF${uuid()}`,
        platform: 'web',
      };

      const mixpanelOutboundCallPayload: MixpanelPayload = {
        event: MixpanelEvent.OutboundCallMade,
        data: {
          outboundCallMade: {
            agentId: callParams?.agent_id,
            workspaceId: callParams?.workspace_sid,
            callFrom: callParams?.From,
            callTo: callParams?.To,
            platform: 'web',
          },
        },
      };
      dispatch({
        type: ACTIONS.OUTGOING_CALL_ENABLED,
        callParams: params,
      });
      try {
        const call = await deviceInstance.connect({ params: callParams });
        trackActivityInMixpanel(mixpanelOutboundCallPayload);
        dispatch({
          type: ACTIONS.OUTGOING_CALL_INITIATED,
          data: call,
          channelId: params?.channel_sid,
        });

        call.on('reconnected', () => {
          console.log('reconnected');
        });

        call.on(callEvent.RINGING, (val: boolean) => {
          if (val) {
            dispatch({
              type: ACTIONS.OUTGOING_CALL_RINGING,
            });
          }
        });

        call.on(callEvent.CANCEL, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_DISCONNECT,
            callWidgetVisible: unSavedCallWidgetActionRef.current,
          });
        });

        call.on(callEvent.DISCONNECT, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_DISCONNECT,
            callWidgetVisible: unSavedCallWidgetActionRef.current,
          });
        });

        call.on(callEvent.ACCEPT, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_ANSWERED,
          });
        });

        call.on(callEvent.REJECT, () => {
          dispatch({
            type: ACTIONS.OUTGOING_CALL_REJECTED,
            callWidgetVisible: unSavedCallWidgetActionRef.current,
          });
        });

        call.on(callEvent.ERROR, (error: any) => {
          datadogLogs.logger.error('Twilio Error : Outgoing Call', {
            datadogError: error,
            additionalInfo: {
              callParams: getCallParams(call),
            },
            context: 'call',
          });
          if (TWILIO_ERRORS[error.code] !== undefined) {
            ToastMessage({
              content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
              type: 'danger',
            });
          }
        });
        call.on('messageReceived', message => {
          const { status } = message?.content || {};
          if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED) {
            dispatch({
              type: ACTIONS.CALL_TRANSFER_FAILED,
              data: {
                transferTo: activeTransferToId.current,
              },
            });
          }
        });
      } catch (error) {
        datadogLogs.logger.warn('Twilio Error : Unable to make Outbound Call', {
          datadogError: {
            message: 'Device is offline',
            description: 'DeviceInstance not created',
          },
          additionalInfo: {
            error,
            callParams: {
              parameters: params,
            },
          },
          context: 'twilio device',
        });
        dispatch({
          type: ACTIONS.OUTGOING_CALL_DISCONNECT,
          callWidgetVisible: unSavedCallWidgetActionRef.current,
        });
      }
      return;
    }
    datadogLogs.logger.warn('Twilio Error : Device is offline', {
      datadogError: {
        message: 'Device is offline',
        description: 'DeviceInstance not created',
      },
      additionalInfo: {
        callParams: {
          parameters: params,
        },
      },
      context: 'twilio device',
    });
    ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
  };

  return {
    handleDeviceOutgoing,
  };
}
