import { useMutation } from '@apollo/client';

import { TRIGGER_MIXPANEL } from 'graphql/mixpanel';

import { MixpanelPayload } from '../types';

export const useTriggerMixpanelEvent = () => {
  const [triggerMixpanelEvent, { loading }] = useMutation(TRIGGER_MIXPANEL);

  const trackActivityInMixpanel = async (payload: MixpanelPayload) => {
    await triggerMixpanelEvent({
      variables: { data: payload },
    });
  };

  return {
    trackActivityInMixpanel,
    loading,
  };
};
