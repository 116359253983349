/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, memo } from 'react';
// import { useIntercom } from 'react-use-intercom';
import SecureLS from 'secure-ls';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import * as path from 'constants/routes';
import { roles, getRoleId } from 'constants/roles';
import useLocalStorage from 'hooks/useLocalStorage';
// import { useLogoutSession } from 'hooks/useLogoutSession';
import { useChatBot } from 'hooks/useChatBot';
// import { getAllUrlParams } from 'components/organisms/common/utils';

import AppLayout from './AppLayout';
import PublicLayout from './PublicLayout';
import SupportLayout from './SupportLayout';
import RestoreLayout from './RestoreLayout';
import useRouteChecker from './useRouteChecker';
import DialerLayout from './DialerLayout';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });
const Layouts = {
  PUBLIC: PublicLayout,
  MAIN: AppLayout,
  SUPPORT: SupportLayout,
  RESTORE: RestoreLayout,
  DIALER: DialerLayout,
};

function IndexLayout({ children }: any) {
  const history = useHistory();

  const { hideChat, updateUserRole, setCrispUser } = useChatBot();
  // const { logoutSession } = useLogoutSession();
  const { pathname, state, search } = useLocation();
  const { from }: any = state || {};

  const crispId = process.env.REACT_APP_CRISP_WEBSITE_ID;
  const [user] = useLocalStorage('user', '');
  // TODO: need to verify if tokens can be retrieved using useLocalStorage hooks here
  let tokens: any = {};
  // added try catch here to handle error
  try {
    const data = ls.get('_tokens') ?? JSON.stringify('');
    tokens = data && JSON?.parse?.(data);
  } catch (e) {
    console.warn(e);
  }

  const refreshToken = tokens?.refreshToken;
  const userHash = user?.intercomIdentity;
  const accessToken = tokens?.accessToken;

  const accessTokenDecoded = accessToken && jwtDecode<JwtPayload>(accessToken);

  const ROLE: string = accessTokenDecoded?.user_claims?.role;
  const isRoleSupport = getRoleId(ROLE) === roles.SUPPORT;
  let landingPath = '';

  useEffect(() => {
    landingPath = pathname;
  }, []);

  // TODO: Need to verify the logic for refresh token expiry. Remove if not needed
  // useEffect(() => {
  //   if (isRefreshTokenExpired) {
  //     logoutSession();
  //     history.push(path.LOGIN);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isRefreshTokenExpired]);

  // TODO: CLEAUP BELOW CODE AFTER INTERCOM IS COMPLETELY REMOVED FROM KRISPCALL
  // const { update, hardShutdown } = useIntercom();
  // const updateWithProps = useCallback((props: any) => update(props), [update]);
  // useEffect(() => {
  //   if (userHash && !isRoleSupport) {
  //     const { firstname, lastname, email, userId } = user?.details?.userProfile || {};
  //     const intercomProps = {
  //       name: `${firstname} ${lastname}`,
  //       email,
  //       userHash,
  //     };
  //     updateWithProps(intercomProps);
  //     datadogRum.setUser({
  //       id: userId,
  //       name: `${firstname} ${lastname}`,
  //       email,
  //     });
  //     datadogLogs.setUser({
  //       id: userId,
  //       name: `${firstname} ${lastname}`,
  //       email,
  //     });
  //   }
  //   if (isRoleSupport) {
  //     hardShutdown();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userHash, updateWithProps, isRoleSupport]);

  useEffect(() => {
    if (userHash && !isRoleSupport) {
      const { workspaces, userProfile } = user?.details || {};
      const { firstname, lastname, email, profilePicture, userId, defaultWorkspace } =
        userProfile || {};
      // const intercomProps = {
      //   name: `${firstname} ${lastname}`,
      //   email,
      //   userHash,
      // };
      // updateWithProps(intercomProps);
      (window as any)?.clarity?.('identify', email);
      (window as any)?.clarity?.('set', 'workspaces', [user?.details?.workspaces]);
      const workspaceData = workspaces?.find((data: any) => data.id === defaultWorkspace);
      const userDetail: any = {
        ...userProfile,
        __typename: undefined,
        email: undefined,
        userId: undefined,
      };
      const workspace: any = {
        ...workspaceData,
        __typename: undefined,
      };
      datadogRum.setUser({
        id: userId,
        name: `${firstname} ${lastname}`,
        email,
        userDetail,
        workspace,
      });
      datadogLogs.setUser({
        id: userId,
        name: `${firstname} ${lastname}`,
        email,
        userDetail,
        workspace,
      });
      setCrispUser(email, `${firstname} ${lastname}`, profilePicture, userId);
      updateUserRole(ROLE);
      if (isRoleSupport) {
        hideChat();
      }
      /*
       * Already init Crisp after login success in useLogin hook
       * use this section to init Crisp only if the crisp initialization in useLogin hook shows error
       *
       */
      // if (crispId) {
      //   if (userId) {
      //     initCrispChat(userId);
      //   }
      //   setCrispUser(email, `${firstname} ${lastname}`, profilePicture, userId);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, isRoleSupport]);

  const {
    isAppPage,
    isAuthPage,
    isSupportPage,
    isRestorePage,
    isPublicPage,
    isDialerPage,
    isMemberInvitePage,
  } = useRouteChecker({
    pathname,
  });

  // Layout Rendering
  const getLayout = () => {
    if (isRestorePage) {
      return Layouts.RESTORE;
    }
    if (isAppPage) {
      return Layouts.MAIN;
    }
    if (isDialerPage) {
      return Layouts.DIALER;
    }
    if (isSupportPage) {
      return Layouts.SUPPORT;
    }
    return Layouts.PUBLIC;
  };

  const BootstrappedLayout = () => {
    const Container = getLayout();
    const token = tokens?.accessToken;
    const isUserAuthorized = !!token && !!getRoleId(ROLE);
    const authRequired = !tokens?.authToken && (isRestorePage || isAuthPage);
    const redirectToLogin =
      !isAuthPage && !isUserAuthorized && !isPublicPage && !isRestorePage && !isMemberInvitePage;

    // redirect to login page if current is not login page and user not authorized
    if (redirectToLogin || authRequired) {
      return <Redirect to={{ pathname: path.LOGIN, state: { from: pathname }, search }} />;
    }
    // redirect to main dashboard when user on login page and authorized
    if (isPublicPage && isUserAuthorized) {
      let redirectPath = landingPath;
      switch (getRoleId(ROLE)) {
        case roles.OWNER:
        case roles.ADMIN:
        case roles.MEMBER:
          redirectPath = path.DASHBOARD;
          break;
        case roles.SUPPORT:
          redirectPath = path.PURCHASE;
          break;
        case roles.DEVELOPER:
          redirectPath = path.PLANSETUP;
          break;
        default:
          redirectPath = landingPath || path.DASHBOARD;
      }
      landingPath = '';
      // Forcefully redirect to dialer page if the window is openned from chrome extension
      if (from === '/dialer') redirectPath = from;
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: from || pathname },
            search,
          }}
        />
      );
    }
    // in other case render previously set layout

    return <Container>{children}</Container>;
  };

  return BootstrappedLayout();
}

export default memo(IndexLayout);
