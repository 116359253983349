import { useLogoutSession } from 'hooks/useLogoutSession';

export default function useLogout() {
  const { logoutSession } = useLogoutSession();

  const logout = () => {
    logoutSession();
  };

  return { logout };
}
