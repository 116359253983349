import { createContext, useState, useEffect, useMemo } from 'react';
import { useDeviceId } from 'hooks/useDeviceId';

type IAppContext = {
  deviceId?: string;
  generateDeviceId?: () => void;
  chooseFreeNumberVisible?: boolean;
  unSavedCallWidgetAction?: boolean;
  setChooseFreeNumberVisible?: (val: boolean) => void;
  setUnSavedCallWidgetAction?: (val: boolean) => void;
};

const AppContext = createContext<IAppContext>({
  chooseFreeNumberVisible: false,
});

function AppProvider({ children }: any) {
  const { deviceId, generateDeviceId } = useDeviceId();
  const [chooseFreeNumberVisible, setChooseFreeNumberVisible] = useState(false);
  const [unSavedCallWidgetAction, setUnSavedCallWidgetAction] = useState(false);

  // Memoize context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      deviceId,
      generateDeviceId,
      chooseFreeNumberVisible,
      unSavedCallWidgetAction,
      setChooseFreeNumberVisible,
      setUnSavedCallWidgetAction,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceId, chooseFreeNumberVisible, unSavedCallWidgetAction], // Only update when these change
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}

export { AppContext, AppProvider };
