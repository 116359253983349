import { useContext, useEffect } from 'react';
import { OnSubscriptionDataOptions, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { datadogLogs } from '@datadog/browser-logs';

import Suspense from 'services/Suspense';
import { useTwilioContext } from 'lib/twilio';
import {
  defaultWorkspaceVar,
  loadingSwitchWorkspace,
  userOnlineStatus,
} from 'services/apollo/reactiveVars';
import { Workspace } from 'generated/graphql';
import * as path from 'constants/routes';
import { AppContext } from 'contexts/app/AppContext';
import { ActionsModal, EditFieldModal } from 'components/organisms';
import { Tooltip, Spinner, Icon } from 'components/atoms';
import { LogoIcon, AddIcon } from 'components/atoms/icon';
import womanAvatar from 'assets/icons/woman.svg';
import SwitchIcon from 'assets/icons/switch.svg';
import chooseFreeNumberImg from 'assets/icons/mobile-chat-img.svg';
import { useChatBot } from 'hooks/useChatBot';
import { useAllWorkspaceNumbers } from 'hooks/useAllWorkspaceNumbers';
import { ENABLE_ADD_WORKSPACE_FEATURE } from 'constants/featureFlags';
import { AuthContext } from 'contexts/auth/AuthContext';
import { kycVerifyText } from 'components/organisms/sticky-reminders/kyc/constants';
import { refreshPage } from 'components/utils/helpers';
import { USER_PROFILE_SUBSCRIPTION } from 'graphql/user';
import SubscriptionHandler from 'services/apollo/SubscriptionHandler';

import * as S from './Styles';
import UserSettings from './UserSettings';
import WorkspaceProfile from './WorkspaceProfile';
import useWorkspaceList from './useWorkspaceList';

export default function Index() {
  const { t } = useTranslation();
  const history = useHistory();
  const { numbers, loading: loadingNumbers } = useAllWorkspaceNumbers();
  const { chooseFreeNumberVisible, setChooseFreeNumberVisible } = useContext(AppContext);
  const { isSubscriptionActive, userAuthId } = useContext(AuthContext);
  const loadingWorkspace = useReactiveVar(loadingSwitchWorkspace);
  const { state: twilliowState } = useTwilioContext();

  const {
    activeWorkspaceId,
    openStayAwayModel,
    isDeletedWorkspace,
    workspaceSwitchModal,
    toggleSwitchWorkspaceModal,
    handleWorkspaceSwitch,
    onSwitchAllowded,
    workspaceList,
    onStayAway,
    goOnline,
    handleDontShowPrompt,
    handleDragEnd,
    addNewWks,
  } = useWorkspaceList();

  const { verifyChat } = useChatBot();

  const { checkAndSyncWorkspace } = useWorkspaceList();

  useEffect(() => {
    const channel = new BroadcastChannel('loading-workspace-switch');
    // Listen for messages from other tabs
    channel.onmessage = event => {
      if (event.data?.loadingSwitchWorkspace) loadingSwitchWorkspace(true);
    };
    return () => {
      channel.close();
    };
  }, []);

  const hasNoNumber = numbers?.length <= 0;
  const onLater: any = () => {
    setChooseFreeNumberVisible?.(false);
  };
  const gotoBuyNumber = () => {
    history.push(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`);
    setChooseFreeNumberVisible?.(false);
  };
  const goToDashboard = () => {
    history.push(`${path.DASHBOARD}`);
  };

  const kycUnverifiedtoolTipMsg = () => {
    return (
      <div className='py-1.5 max-w-182'>
        <p className='text-gray-100 text-13  font-heebo leading-4.5 mb-2'>{kycVerifyText}</p>
        <button
          type='button'
          onClick={verifyChat}
          className='rounded text-13 font-semibold font-manrope  leading-tight w-full h-7 flex items-center justify-center text-gray-100 border border-gray-600'
        >
          Verify Now
        </button>
      </div>
    );
  };

  const onSubscriptionDataCallback = (arg: OnSubscriptionDataOptions<any>) => {
    const { event, message } = arg?.subscriptionData?.data?.userProfile;
    const { stayOnline, defaultWorkspace, platform } = message;
    if (event === 'update_user_profile') {
      userOnlineStatus(stayOnline);
      defaultWorkspaceVar(defaultWorkspace);
      return;
    }
    if (event === 'MEMBER_LOGIN') {
      // Session storage used to handle case for workspace switch if multiple tab openned in same browser.
      const activeTabDefaultWks = sessionStorage.getItem('_activeWorkspaceId');
      if (!twilliowState?.showPhoneWidget && defaultWorkspace !== activeTabDefaultWks) {
        loadingSwitchWorkspace(true);
        sessionStorage.setItem('_activeWorkspaceId', defaultWorkspace);
        if (platform === 'web') {
          setTimeout(() => {
            // Reload the page if workspace switched in other tabs openned in same browser
            datadogLogs.logger.info('Auto page reload on workspace switch from mobile or web', {
              prevWorkspaceId: activeTabDefaultWks,
              newWorkspaceId: defaultWorkspace,
              subscriptionData: message,
              context: 'workspace switch',
            });
            loadingSwitchWorkspace(false);
            history.push('/');
            refreshPage();
          }, 3000);
          return;
        }
        // check and sync active workspace with other loggedIn device
        checkAndSyncWorkspace(defaultWorkspace);
      }
    }
  };
  const draggableWorkspaceList = workspaceList?.map((workspace: Workspace, index: number) => {
    const { kycVerified, id } = workspace || {};
    // const currentActive = activeWorkspaceId ? activeWorkspaceId === id : defaultWorkspace === id;
    const currentActive = activeWorkspaceId === id;

    return (
      <Draggable
        key={id}
        draggableId={id}
        index={index}
        isDragDisabled={workspace.status === 'Inactive'}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className='mb-4 relative'
          >
            {!kycVerified && (
              <Tooltip title={kycUnverifiedtoolTipMsg()} placement='bottomRight'>
                <Icon
                  name='exclamation-circle-fill'
                  className='absolute z-1 -top-0.75 right-1.5 cursor-default'
                />
              </Tooltip>
            )}
            <WorkspaceProfile
              id={id}
              onClick={handleWorkspaceSwitch(workspace)}
              isActive={currentActive}
              workspace={workspace}
            />
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <>
      {/*
      TODO: If we check loadingWorkspace here websocket for web api, websocket will be in closed state
      and we couldn't receieve the workspace change event that is sent from backend in websocket.
      It will impact the case for workspace switch in multiple tab and from mobile apps
      Might be fixed if separate ws connection endpoint is used for auth and access token
      */}

      {/* {!loadingWorkspace && userAuthId && (
        <SubscriptionHandler
          subscription={USER_PROFILE_SUBSCRIPTION}
          variables={{
            user: userAuthId,
          }}
          onSubscriptionData={(arg: any) => onSubscriptionDataCallback(arg)}
        />
      )} */}

      {userAuthId && (
        <SubscriptionHandler
          subscription={USER_PROFILE_SUBSCRIPTION}
          variables={{
            user: userAuthId,
          }}
          onSubscriptionData={(arg: any) => onSubscriptionDataCallback(arg)}
        />
      )}
      <div className='w-full h-full overflow-hidden'>
        <S.LogoWrapperBtn onClick={goToDashboard}>
          <LogoIcon className='mx-auto mb-4' />
        </S.LogoWrapperBtn>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable'>
            {provided => (
              <S.StyledScrollbar className='w-full'>
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Suspense fallback={<Spinner />}>{draggableWorkspaceList}</Suspense>
                  {provided.placeholder}
                </div>
              </S.StyledScrollbar>
            )}
          </Droppable>
        </DragDropContext>
        {ENABLE_ADD_WORKSPACE_FEATURE && (
          <Tooltip title={t('addWorkspace', 'Add Workspace')} placement='right'>
            <div className='h-8 w-8 mx-auto'>
              <button
                data-cy='add-new-workspace'
                className='h-8 w-8 p-2.5 rounded-9 hover:bg-primary-500 transition duration-200 ease-out'
                type='button'
                onClick={addNewWks}
              >
                <AddIcon />
              </button>
            </div>
          </Tooltip>
        )}
      </div>
      <UserSettings />
      {openStayAwayModel && !isDeletedWorkspace && (
        <EditFieldModal
          isModalOpen={openStayAwayModel && !isDeletedWorkspace}
          className='set-yourself-online'
          onOk={goOnline}
          onCancel={onStayAway}
          subTitle={t(
            'goOnlinePromptMessage',
            'You’re currently set to away. Do you want to update your availability?',
          )}
          title={
            <S.ModalHead>
              <img src={womanAvatar} alt='woman avatar krispcall' className='cy-wks-avatar' />
              <div>{t('setYourselfOnline', 'Set yourself online?')}</div>
            </S.ModalHead>
          }
          buttonText={t('goOffline', 'Go Online')}
          buttonTextCancel={t('stayOffline', 'Stay Offline')}
        >
          <S.CheckboxStyled onChange={handleDontShowPrompt} name='Don’t ask again.'>
            {t('dontAskAgain', 'Don’t ask again.')}
          </S.CheckboxStyled>
        </EditFieldModal>
      )}

      {!loadingWorkspace &&
        !loadingNumbers &&
        !openStayAwayModel &&
        chooseFreeNumberVisible &&
        isSubscriptionActive &&
        hasNoNumber && (
          <EditFieldModal
            isModalOpen={chooseFreeNumberVisible}
            className='choose-one-free-number'
            width='388px'
            onCancel={onLater}
            onOk={gotoBuyNumber}
            subTitle='Welcome to your workspace! Get started by selecting one free number from the US, Canada, or UK. You can always purchase more numbers later from Settings > My Numbers'
            title={
              <S.ModalHead>
                <img src={chooseFreeNumberImg} alt='Choose Free Number' />
                <div>Choose One Free Number</div>
              </S.ModalHead>
            }
            buttonTextCancel='Later'
            buttonText='Choose Number'
          />
        )}

      {workspaceSwitchModal && (
        <ActionsModal
          type='primary'
          isModalOpen={workspaceSwitchModal}
          onOk={onSwitchAllowded}
          onCancel={toggleSwitchWorkspaceModal}
          onModalCancel={toggleSwitchWorkspaceModal}
          image={SwitchIcon}
          title={t('workspaceSwitchTitle', 'Switch Workspace?')}
          info={t(
            'workspaceSwitchInfo',
            'Are you sure you want to switch workspace? The call in progress will end if you switch.',
          )}
          btnText='Switch'
        />
      )}
    </>
  );
}
