export enum MixpanelEvent {
  SignupFailed = 'SignupFailed',
  OutboundCallMade = 'OutboundCallMade',
  OutboundSms = 'OutboundSms',
}

type OutboundCallMade = {
  agentId: string;
  workspaceId: string;
  callFrom: string;
  callTo: string;
  platform: string;
};

type OutboundSms = {
  agentId: string;
  workspaceId: string | undefined;
  smsFrom: string;
  smsTo: string;
  platform: string;
};

type SignupFailed = {
  email: string;
  errorMessage: string;
};

type PayloadData = {
  outboundSms?: OutboundSms;
  outboundCallMade?: OutboundCallMade;
  signupFailed?: SignupFailed;
};

export interface MixpanelPayload {
  event: MixpanelEvent;
  data: PayloadData;
}
