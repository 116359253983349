import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { USER_PROFILE_FRAGMENT } from './fragments';

const MFA_LOGIN = gql`
  mutation mfaLogin($data: LoginInputData!) {
    mfaLogin(data: $data) {
      status
      data {
        token
        intercomIdentity
        details {
          id
          workspaces {
            id
            memberId
            title
            role
            status
            plan {
              remainingDays
              subscriptionActive
            }
          }
          userProfile {
            ...UserProfile
          }
        }
      }
      error {
        ...Error
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

const SUPPORT_LOGIN = gql`
  mutation supportLogin($data: LoginInputData!) {
    supportLogin(data: $data) {
      status
      data {
        token
        intercomIdentity
        details {
          id
          workspaces {
            id
            memberId
            title
            role
            status
            plan {
              remainingDays
              subscriptionActive
            }
          }
          userProfile {
            ...UserProfile
          }
        }
      }
      error {
        ...Error
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

const REGISTER = gql`
  mutation signup($data: RegisterInputData!) {
    signup(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const SIGN_UP_EMAIL = gql`
  mutation signupEmail($email: String!) {
    signupEmail(email: $email) {
      status
      data {
        id
        email
        retryCount
        resendCount
        resendLimitExceeded
        retryLimitExceeded
        resendTimeLimit
        otpCode
        isValidated
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
const RESEND_OTP = gql`
  mutation resendOtp($data: OtpInputData!) {
    resendOtp(data: $data) {
      status
      data {
        id
        email
        retryCount
        resendCount
        resendLimitExceeded
        retryLimitExceeded
        resendTimeLimit
        isValidated
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const VALIDATE_OTP = gql`
  mutation validateOtp($data: ValidateOtpInputData!) {
    validateOtp(data: $data) {
      status
      data {
        id
        email
        retryCount
        resendCount
        resendLimitExceeded
        retryLimitExceeded
        resendTimeLimit
        otpCode
        isValidated
        otpCode
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInputData!) {
    changePassword(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      status
      error {
        ...Error
      }
      data {
        message
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      status
      data {
        message
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const CHANGE_EMAIL = gql`
  mutation changeEmail($data: ChangeEmailInputData!) {
    changeEmail(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      status
      error {
        ...Error
      }
      data {
        accessToken
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const MEMBER_LOGIN = gql`
  mutation memberLogin($data: MemberLoginInputData!) {
    memberLogin(data: $data) {
      status
      data {
        accessToken
        refreshToken
        cardInfo
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
const VERIFY_EMAIL = gql`
  mutation verifyEmail($data: VerifyEmailInput!) {
    verifyEmail(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const CHANGE_PROFILE_NAME = gql`
  mutation changeProfileNames($data: ChangeProfileNames!) {
    changeProfileNames(data: $data) {
      status
      data {
        ...UserProfile
      }
      error {
        ...Error
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

const CHANGE_PROFILE_PICTURE = gql`
  mutation changeUserProfilePicture($photoUpload: Upload!) {
    changeUserProfilePicture(photoUpload: $photoUpload) {
      status
      data {
        ...UserProfile
      }
      error {
        ...Error
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

const REGISTER_DEVICE_FCM = gql`
  mutation deviceRegister($data: DeviceRegisterInputData!) {
    deviceRegister(data: $data) {
      status
      data {
        id
        version
        fcmToken
        platform
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const TOGGLE_ONLINE_STATUS = gql`
  mutation updateOnlineStatus($data: EditOnlineStatusInputData!) {
    updateOnlineStatus(data: $data) {
      status
      data {
        id
        onlineStatus
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const SET_USER_DND = gql`
  mutation setUserDND($data: UserDNDInput!) {
    setUserDND(data: $data) {
      status
      data {
        dndEnabled
        dndEndtime
        dndRemainingTime
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const CHECK_LOGIN_CREDENTIALS = gql`
  mutation checkLoginCredentials($data: LoginInputData!) {
    checkLoginCredentials(data: $data) {
      status
      data {
        success
        rememberMe
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

const LOGOUT = gql`
  mutation revoke($data: RevokeInputData!) {
    revoke(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export {
  MFA_LOGIN,
  SUPPORT_LOGIN,
  REGISTER,
  RESEND_OTP,
  VALIDATE_OTP,
  SIGN_UP_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
  REFRESH_TOKEN,
  MEMBER_LOGIN,
  VERIFY_EMAIL,
  CHANGE_PROFILE_NAME,
  CHANGE_PROFILE_PICTURE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  REGISTER_DEVICE_FCM,
  TOGGLE_ONLINE_STATUS,
  SET_USER_DND,
  CHECK_LOGIN_CREDENTIALS,
  LOGOUT,
};
