import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import { CAMPAIGN_STATUS_RESPONSE } from 'lib/twilio/constants';
import { activeCallCampaignStatus, isNetworkAvailable } from 'services/apollo/reactiveVars';
import DialerWidgetV3 from 'components/organisms/widgets-v3';

import { useCampaignHandlers } from '../sales-dialer/campaigns/hooks/useCampaignHandlers';
import { CAMPAIGN_ACTIONS } from '../sales-dialer/campaigns/constants';
import useForceCampaignPause from './hooks/useForceCampaignPause';

const DialerCallWidget = () => {
  const {
    state: { connection, showPhoneWidget, showDialer, salesDialerWidget },
  } = useTwilioContext();

  const { forcePauseCampaign } = useForceCampaignPause();
  const { handleCampaignRun } = useCampaignHandlers();

  const activeCampaignStatus = useReactiveVar<any>(activeCallCampaignStatus);
  const internetConnection = useReactiveVar(isNetworkAvailable);

  const { INPROGRESS } = CAMPAIGN_STATUS_RESPONSE;
  const campaignId = connection?.customParameters?.get('campaignId') || '';
  const conversationId = connection?.customParameters?.get('campaignConversationId') || '';

  // const enableV3Dialer = showToggleV3DialerOption ? v3DialerVisible : ENABLE_DIALER_V3;

  useEffect(() => {
    if (internetConnection) {
      if (campaignId && activeCampaignStatus === INPROGRESS) {
        handleCampaignRun(campaignId, CAMPAIGN_ACTIONS.pause, conversationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetConnection]);

  // eslint-disable-next-line consistent-return
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (showPhoneWidget && !showDialer) {
      event.preventDefault();
      const message = 'A call is currently in progress.';
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;
      return message;
    }
  };

  const handleUnload = async (event: Event) => {
    if (showPhoneWidget && salesDialerWidget && campaignId) {
      event.preventDefault();
      const payload = {
        id: campaignId,
        conversationId,
        action: CAMPAIGN_ACTIONS.pause,
      };
      await forcePauseCampaign(payload);
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPhoneWidget, showDialer, salesDialerWidget]);

  return (
    <>
      {showPhoneWidget && (
        <>
          <DialerWidgetV3 />
        </>
      )}
    </>
  );
};
export default DialerCallWidget;
