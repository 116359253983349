import { useApolloClient } from '@apollo/client';

import { resetWSLink } from 'services/apollo';
import { useFcm } from 'components/pages/authentication/login/useFcm';
import { useLogoutMutation } from './useLogoutMutation';
import { useClearAllSession } from './useClearSession';

export function useLogoutSession() {
  const { clearAllUserSession } = useClearAllSession();
  const { clearFcmInstance } = useFcm();

  const client = useApolloClient();

  const onSuccessCallback = async () => {
    resetWSLink(async () => {
      clearAllUserSession();
      try {
        if (client) await client.clearStore();
      } catch (e) {
        console.error(e);
      }
      clearFcmInstance();
    });
  };

  const { logout } = useLogoutMutation({ callback: onSuccessCallback });

  const logoutSession = async () => {
    await logout();
  };

  return {
    logoutSession,
  };
}
