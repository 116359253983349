import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import packageJson from '../../../package.json';

const {
  REACT_APP_DATADOG_ENV,
  REACT_APP_DATADOG_APPLICATION_ID = '',
  REACT_APP_DATADOG_CLIENT_TOKEN = '',
  REACT_APP_DATADOG_SITE,
  REACT_APP_DATADOG_SERVICE,
  REACT_APP_DATADOG_RECORDING_ENABLED,
  REACT_APP_DATADOG_RUM_DISABLED,
  REACT_APP_DATADOG_TRACING_URLS,
} = process.env;

const initDatadogRum = () => {
  datadogRum.init({
    applicationId: REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: REACT_APP_DATADOG_SITE,
    service: REACT_APP_DATADOG_SERVICE,
    env: REACT_APP_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: packageJson?.version,
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    sessionReplaySampleRate: 20, // Optional: Session Replay (adjust as needed)
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: REACT_APP_DATADOG_TRACING_URLS?.split(','),
  });
};

const initDatadogLogs = () => {
  datadogLogs.init({
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
    site: REACT_APP_DATADOG_SITE,
    service: REACT_APP_DATADOG_SERVICE,
    env: REACT_APP_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: packageJson?.version,
    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
    beforeSend: event => {
      const { http, message, error } = event || {};
      if (event.error?.stack) {
        if (
          event.error?.stack &&
          event.error.stack.includes("Cannot read properties of undefined (reading 'error')")
        ) {
          // Error thrown from third party packages used in web app. Skipped log in datadog
          return false;
        }
      }
      // discard 404 network errors and aborted API requests
      if (http && [404, 502, 0].includes(http.status_code)) {
        return false;
      }
      // ignore ResizeObserver loop warnings
      if (message && message.includes('ResizeObserver loop')) {
        return false;
      }
      // discard console errors
      if (error?.origin === 'console') return false;
      // Ignore errors from google tag manager
      if (error && error?.stack?.includes('www.googletagmanager.com')) {
        return false;
      }
      return undefined;
    },
  });
};

export function initDatadog() {
  // Check env variable for initialization of datadogRum. Rum is disabled in qa env for automation testing
  if (REACT_APP_DATADOG_RUM_DISABLED !== 'true') initDatadogRum();
  initDatadogLogs();

  datadogRum.onReady(() => {
    if (process.env.REACT_APP_DEBUG) console.log('initialized datadog RUM');
    if (REACT_APP_DATADOG_RECORDING_ENABLED === 'true') datadogRum.startSessionReplayRecording();
  });

  datadogLogs.onReady(() => {
    if (process.env.REACT_APP_DEBUG) console.log('initialized datadog log');
    datadogLogs.setGlobalContext({ env: REACT_APP_DATADOG_ENV });
  });
}
