import { ReactElement, useEffect } from 'react';
import { useReactiveVar, OnSubscriptionDataOptions } from '@apollo/client';

import ClockOutline from 'components/atoms/icon/mage-icons/ClockOutline';
import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS, CAMPAIGN_STATUS_RESPONSE } from 'lib/twilio/constants';
import { UPDATE_CREDIT_SUBSCRIPTION } from 'graphql/sales-dialer/campaign';
import { Icon, ToastMessage } from 'components/atoms';
import { CAMPAIGN_ACTIONS } from 'components/pages/sales-dialer/campaigns/constants';
import { UsersIcon } from 'components/atoms/icon';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { activeCallCampaignStatus } from 'services/apollo/reactiveVars';
import useAuthContext from 'hooks/useAuthContext';
import useUpdateCache from 'hooks/useUpdateCache';
import SubscriptionHandler from 'services/apollo/SubscriptionHandler';
import useUpdateCampaignCache from 'components/pages/dialer-widget/hooks/useUpdateCampaignCache';

import useCallWidgetContext from '../hooks/useCallWidgetContext';
import useActiveCallWidgetContext from '../hooks/useActiveCallWidgetContext';
import { useCoolOffTimer } from '../hooks/useCoolOffTimer';
import { CampaignWidgetContainer } from './styles';
import { BannerType } from '../types';
import { WidgetActionButton } from '../call-active/Styles';

export default function CampaignWidget(): ReactElement {
  const {
    state: { connection, status, callEnded, voicemailDropEnabled },
  } = useTwilioContext();
  const { userAuthId } = useAuthContext();
  const { setBanner } = useCallWidgetContext();
  const { updateCampaignStatus } = useUpdateCampaignCache();
  const { updateCreditCache } = useUpdateCache();
  const {
    handleExecuteCampaignAction,
    handleSkipCampaignCallQueue,
    handleResetCallWidget,
    handleDropVoicemail,
  } = useActiveCallWidgetContext();

  const { INPROGRESS } = CAMPAIGN_STATUS_RESPONSE;
  const activeCampaignStatus = useReactiveVar<any>(activeCallCampaignStatus);
  const campaignId = connection?.customParameters?.get('campaignId') || '';
  const coolOffPeriod = connection?.customParameters?.get('coolOffPeriod') || 10;
  const nextQueue = connection?.customParameters?.get('nextQueue') || '';
  const conversationId = connection?.customParameters?.get('campaignConversationId') || '';
  const lastQueueCall = connection?.customParameters?.get('lastQueue') || '';
  const queueCallId = connection?.customParameters?.get('nextCampaignConversationId');
  const isSecondLastCall =
    nextQueue &&
    lastQueueCall &&
    nextQueue.replace(/\+/g, '').trim() === lastQueueCall.replace(/\+/g, '').trim();
  const isCampaignPaused = activeCampaignStatus === 'PAUSED';

  const { nationalNumber, flagUrl, country } = parsePhoneNumber(nextQueue);

  const { seconds: coolOffTime, startCoolOffTimer, resetCoolOffTime } = useCoolOffTimer({
    time: coolOffPeriod,
  });

  useEffect(() => {
    if (callEnded) {
      startCoolOffTimer();
      return;
    }
    resetCoolOffTime(coolOffPeriod);
    handleResetCallWidget?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEnded]);

  const handleClickDropVoicemail = () => {
    handleDropVoicemail?.(conversationId, campaignId);
  };

  const handleCampaignAction = () => {
    const action = isCampaignPaused ? CAMPAIGN_ACTIONS.resume : CAMPAIGN_ACTIONS.pause;
    handleExecuteCampaignAction?.(campaignId, action);
  };

  const handleSkipQueueCall = () => {
    if (queueCallId) handleSkipCampaignCallQueue?.(queueCallId, campaignId);
  };

  const onSubscriptionDataCallback = (arg: OnSubscriptionDataOptions<any>) => {
    const { message } = arg?.subscriptionData?.data?.updateCredit || {};
    if (message) {
      const { creditAmount, isCallDisconnected, campaignStatus } = message || {};
      updateCreditCache(creditAmount);
      if (creditAmount < 1 && !isCallDisconnected && campaignStatus === INPROGRESS) {
        ToastMessage({
          content: 'Your credit is low! Please recharge soon to avoid service interruptions.',
          type: 'warning',
        });
        return;
      }
      if (isCallDisconnected) {
        ToastMessage({
          content: 'Call disconnected. Your credit is low!',
          type: 'danger',
        });
      }
      updateCampaignStatus(campaignStatus);
    }
  };

  useEffect(() => {
    if (isCampaignPaused) {
      setBanner?.({
        title: 'Campaign has been paused',
        closable: true,
        type: BannerType.warning,
        icon: <Icon name='pause-line' />,
        showIcon: true,
      });
      return;
    }
    setBanner?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCampaignStatus]);

  useEffect(() => {
    return () => {
      setBanner?.({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {userAuthId && (
        <SubscriptionHandler
          subscription={UPDATE_CREDIT_SUBSCRIPTION}
          variables={{
            user: userAuthId,
          }}
          onSubscriptionData={(arg: any) => onSubscriptionDataCallback(arg)}
        />
      )}
      <CampaignWidgetContainer className='border-t p-4'>
        <WidgetActionButton
          type='primary'
          className='w-full btn-success'
          icon={<Icon name='voicemail' />}
          onClick={handleClickDropVoicemail}
          disabled={!voicemailDropEnabled}
        >
          Voicemail Drop
        </WidgetActionButton>
        <div className='my-4 font-heebo'>
          <div className='flex items-center'>
            <span className='flex flex-1 items-center '>
              <ClockOutline className='text-text-300' />
              <p className='text-text-300 ml-1.5 mr-1 text-13 leading-none'>Cool Off:</p>
              <h3 className='text-text-300 text-13 leading-none font-normal'>{coolOffTime} s</h3>
            </span>
            {/* Hide skip button because we couldn't skip the cooloff time */}
            {/* <button type='button' className='skip-btn'>
            Skip
          </button> */}
          </div>
          <div className='flex items-center mt-4'>
            <UsersIcon className='text-text-300' />
            <span className='flex flex-1 items-center'>
              <p className='text-text-300 ml-1.5 mr-1 text-13 leading-none'>In a queue:</p>
              <h3 className='text-text-300 text-13 leading-none font-normal'>
                {queueCallId ? nationalNumber : '-'}
              </h3>
              <img src={flagUrl} alt={country} title={country} className='ml-1.5' />
            </span>
            {/* Hide skip button for second last call */}
            {nationalNumber && queueCallId && !isSecondLastCall && (
              <button type='button' className='skip-btn' onClick={handleSkipQueueCall}>
                Skip
              </button>
            )}
          </div>
        </div>
        <WidgetActionButton
          className={`w-full ${isCampaignPaused ? 'btn-success-outlined' : 'btn-white'}`}
          onClick={handleCampaignAction}
          disabled={status !== CALL_WIDGET_STATUS.ENDED}
        >
          {isCampaignPaused ? 'Resume' : 'Pause'} Campaign
        </WidgetActionButton>
      </CampaignWidgetContainer>
    </>
  );
}
