import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const TRIGGER_MIXPANEL = gql`
  mutation triggerMixpanelEvent($data: MixpanelEvent!) {
    triggerMixpanelEvent(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
