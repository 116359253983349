import { ReactElement } from 'react';

import { useCallScriptQuery } from '../../hooks/useCallScriptQuery';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import { WidgetActionButton } from '../Styles';

type Contact = {
  name: string;
  number: string;
  email: string;
  company: string;
  address: string;
};

interface IProps {
  callScriptId?: string;
  contact: Contact;
}

export default function CallScript({ callScriptId, contact }: IProps): ReactElement {
  const { closeDrawer } = useCallWidgetDrawerContext();
  const { name, number, email, company, address } = contact || {};

  const callScriptTemplate = useCallScriptQuery({ callScriptId });
  const callScriptPlaceholderValues: Record<string, string> = {
    '[NAME]': name,
    '[NUMBER]': number,
    '[EMAIL]': email,
    '[COMPANY]': company,
    '[ADDRESS]': address,
  };

  let callScript = callScriptTemplate?.description;

  if (callScript) {
    Object.keys(callScriptPlaceholderValues).forEach(placeholder => {
      callScript = callScript?.replaceAll(placeholder, callScriptPlaceholderValues[placeholder]);
    });
  }

  return (
    <div className='flex flex-col h-full px-4 pb-4'>
      <div className='flex-1'>
        <div className='h-62.5 px-4 py-3.5 bg-background-200  rounded-xl border border-stroke overflow-auto'>
          <div className='self-stretch text-zinc-800 text-15 font-Heebo leading-snug '>
            {callScript}
          </div>
        </div>
      </div>
      <WidgetActionButton onClick={closeDrawer}>Discard</WidgetActionButton>
    </div>
  );
}
