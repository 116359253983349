import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

import { parsePhoneNumber } from 'components/utils/phone-lib';
import { ToastMessage } from 'components/atoms';
import { Channel, Maybe } from 'generated/graphql';
import { isAssignedNumber as assignedNumber } from 'components/organisms/common/utils';
import useCallBoxContext from 'components/pages/inbox/hooks/useCallBoxContext';

interface IProps {
  channelId?: string;
}

export const useSmsHelper = ({ channelId }: IProps) => {
  const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

  const { t } = useTranslation();
  const { configs } = useCallBoxContext();

  // CHECK IF NUMBER CONTAINS INVALID CHARACTERS OR SHORT CODE
  const isInvalidPhoneNumber = (phoneNumber: string) => {
    const { parsedPhoneNumber } = parsePhoneNumber(phoneNumber);
    if (!parsedPhoneNumber) {
      ToastMessage({
        content: `${phoneNumber} is not a valid phone number`,
        type: 'danger',
      });
      return true;
    }
    return false;
  };

  // CHECK IF NUMBER IS ASSIGNED TO ONE OF THE YOUR CHANNELS
  const isAssignedNumber = (channels: Maybe<Channel>[], phoneNumber: string) => {
    if (assignedNumber(channels, phoneNumber)) {
      ToastMessage({
        content: `${t(
          'toast.dialAssignedNumberError',
          'Number is assigned to one of your channel',
        )}`,
        type: 'danger',
      });
      return true;
    }
    return false;
  };

  // CHECK IF SMS IS SUPPORTED IN CONTACT/CALLER NUMBER
  const isContactSmsSupported = (phoneNumber: string) => {
    const { parsedPhoneNumber, alphaTwoCode = '' } = parsePhoneNumber(phoneNumber);
    const numberType = parsedPhoneNumber?.getType();
    const FIXED_LINE_SMS_COUNTRIES = ['ES', 'DE', 'GB', 'US', 'CA', 'AU', 'JP', 'NL', 'FR'];
    if (numberType === 'FIXED_LINE' && !FIXED_LINE_SMS_COUNTRIES.includes(alphaTwoCode)) {
      ToastMessage({
        content: `${t('toast.contactSmsNotSupported', "This number doesn't support sms")}`,
        type: 'danger',
      });
      return false;
    }
    return true;
  };

  // CHECK IF SMS IS SUPPORTED IN AGENT NUMBER
  const isAgentSmsSupported = (activeChannel: any) => {
    if (activeChannel?.sms === false) {
      ToastMessage({
        content: `${t('toast.agentSmsNotSupported', "Your number doesn't support sms")}`,
        type: 'danger',
      });
      return false;
    }
    return true;
  };

  // CHECK IF MMS IS SUPPORTED IN AGENT NUMBER
  const isAgentMmsSupported = (activeChannel: any) => {
    if (activeChannel?.mms === false) {
      ToastMessage({
        content: `${t('toast.agentMmsNotSupported', "Your number doesn't support mms")}`,
        type: 'danger',
      });
      return false;
    }
    return true;
  };

  // CHECK IF INTERNATIONAL CALLS AND  SMS IS ALLOWED
  const isSmsSupportedIntl = (contactPhoneNumber: string, agentPhoneNumber?: string) => {
    const sourceNumberDetail = parsePhoneNumber(agentPhoneNumber ?? '');
    const targetNumberDetail = parsePhoneNumber(contactPhoneNumber.trim() ?? '');
    if (
      !configs?.internationalCallAndMessages &&
      sourceNumberDetail.country !== targetNumberDetail.country
    ) {
      ToastMessage({
        content: `${t(
          'toast.internationalCallsDisabled',
          'International Calls and Messages Disabled. Contact your Administrator',
        )}`,
        type: 'danger',
      });
      return false;
    }
    return true;
  };

  const getLastMessagesLog = () => {
    try {
      const lsValue = ls.get('_lgmsgsdt');
      const data = lsValue && lsValue !== '' ? lsValue : [];
      const draftMessages = typeof data === 'string' ? JSON.parse(data) : [];
      return draftMessages;
    } catch (e) {
      return [];
    }
  };

  const getLastMessage = () => {
    try {
      const lsValue = ls.get('_lgmsgsdt');
      const data = lsValue && lsValue !== '' ? lsValue : [];
      const logs = typeof data === 'string' ? JSON.parse(data) : [];
      const lastMessage = logs?.find((eachMessage: any) => eachMessage.channelId === channelId);
      return lastMessage;
    } catch (e) {
      return [];
    }
  };

  // Save last sms date and time in local storage
  const logRecentSmsInfo = () => {
    const log = getLastMessagesLog();
    const lastMessage = getLastMessage();
    const sentAt = new Date();
    const expiredAt = new Date(sentAt.getTime() + 10000);
    const eachClientMsg = {
      channelId,
      sentAt,
      expiredAt,
    };
    if (!lastMessage) {
      log.push(eachClientMsg);
      // log sms date and time in local storage
      ls.set('_lgmsgsdt', JSON.stringify(log));
    }
  };

  const removeSmsLog = async () => {
    const log = getLastMessagesLog();
    const messageIndex = log.findIndex((eachMessage: any) => eachMessage.channelId === channelId);
    log.splice(messageIndex, 1);
    ls.set('_lgmsgsdt', JSON.stringify(log));
  };

  return {
    isAssignedNumber,
    isContactSmsSupported,
    isAgentSmsSupported,
    isAgentMmsSupported,
    isSmsSupportedIntl,
    isInvalidPhoneNumber,
    getLastMessage,
    logRecentSmsInfo,
    removeSmsLog,
  };
};
