import { cleanupSWAndCaches } from 'services/cleanupSWAndCaches';

const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;
const foceLogoutUser = process.env.REACT_APP_FORCE_LOGOUT === 'true';

/**
 *  Check the current version of the app and clear outdated resources if necessary.
 * @function checkAppHealth
 * @returns {void}
 * @author {Sundar Shahi Thakuri} <{shahithakurisundar@gmail.com}>
 *
 * */

export function checkAppHealth(): void {
  if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
    localStorage.setItem('APP_VERSION', APP_VERSION as string);
  }

  if (localStorage.APP_VERSION !== APP_VERSION && foceLogoutUser) {
    const deviceId = localStorage.getItem('deviceId');
    localStorage.clear();
    if (deviceId && deviceId !== 'null') localStorage.setItem('deviceId', deviceId as string);
    cleanupSWAndCaches();
  }
}
