import { useRef } from 'react';
import { Device } from '@twilio/voice-sdk';
import { useReactiveVar } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';

import { useTriggerMixpanelEvent } from 'services/mixpanel/hooks/useTriggerMixpanelEvent';

import { isNetworkAvailable, webRtcPeerConnectionEnabled } from 'services/apollo/reactiveVars';
import { ToastMessage } from 'components/atoms';
import useCallMessageEligibilityCheck, {
  EligibilityCheckPayload,
} from 'hooks/useCallMessageEligibilityCheck';

import useWebRtcTest from './useWebRtcTest';
import { ACTIONS, callEvent } from '../constants';
import { LiveListeningProps } from '../types';
import { getCallParams } from '../utils';
import { TWILIO_ERRORS } from '../error';

interface IProps {
  dispatch: React.Dispatch<any>;
  deviceInstance: Device | null;
}

export function useLiveCallListeningHandler(props: IProps) {
  const { dispatch, deviceInstance } = props;

  const webRtcPeerConnectionRef: any = useRef();
  const internetConnectionRef = useRef<boolean>();

  const { t } = useTranslation();
  const { checkPeerConnection } = useWebRtcTest();
  const { checkCallMessageEligibility } = useCallMessageEligibilityCheck();
  const { trackActivityInMixpanel } = useTriggerMixpanelEvent();

  const webRtcPeerConnection = useReactiveVar<any>(webRtcPeerConnectionEnabled);
  const internetConnection = useReactiveVar(isNetworkAvailable);

  webRtcPeerConnectionRef.current = webRtcPeerConnection;
  internetConnectionRef.current = internetConnection;

  const handleLiveListening = async (params: LiveListeningProps) => {
    if (deviceInstance) {
      if (!internetConnectionRef.current) {
        ToastMessage({
          content: `${t('toast.checkInternetConnection', 'Please check your internet connection')}`,
          type: 'danger',
        });
        return;
      }
      checkPeerConnection();
      if (!webRtcPeerConnectionRef.current) {
        ToastMessage({
          content: t(
            'error.networkConnectivityError',
            'Unable to connect. Some third party tools might be affecting your network connectivity',
          ),
          type: 'danger',
        });
        return;
      }
      if (deviceInstance?.state === 'destroyed') {
        datadogLogs.logger.error('Twilio Error : Unable to listen live calls', {
          datadogError: {
            message: 'Device is offline. Unable to listen live calls',
            description: 'DeviceInstance state is destroyed',
          },
          additionalInfo: {
            callParams: {
              parameters: params,
            },
          },
          context: 'twilio device',
        });
        ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
        return;
      }
      const eligibilityCheckPayload: EligibilityCheckPayload = {
        originationNumber: params.From || '',
        destinationNumber: params.To || '',
        eventType: 'Call',
      };
      const isEligibleForCall = await checkCallMessageEligibility(eligibilityCheckPayload);
      if (!isEligibleForCall) return;
      const call = await deviceInstance.connect({ params });

      call.on('reconnected', () => {
        console.log('reconnected');
      });

      call.on(callEvent.DISCONNECT, () => {
        console.log('disconnected');
      });

      call.on(callEvent.ERROR, (error: any) => {
        datadogLogs.logger.error('Twilio Error : live listening', {
          datadogError: error,
          additionalInfo: {
            callParams: getCallParams(call),
          },
          context: 'call',
        });
        if (TWILIO_ERRORS[error.code] !== undefined) {
          ToastMessage({
            content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
            type: 'danger',
          });
        }
      });
      dispatch({
        type: ACTIONS.SET_LIVECALL,
        data: { call, params },
      });
      return;
    }
    datadogLogs.logger.warn('Twilio Error : Device is offline', {
      datadogError: {
        message: 'Device is offline',
        description: 'DeviceInstance not created',
      },
      additionalInfo: {
        callParams: {
          parameters: params,
        },
      },
      context: 'twilio device',
    });
    ToastMessage({ content: t('error.deviceSetup', 'Device is offline.'), type: 'danger' });
  };

  return {
    handleLiveListening,
  };
}
