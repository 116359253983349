import { datadogLogs } from '@datadog/browser-logs';
import { LOGIN } from 'constants/routes';
import { useClearAllSession } from 'hooks/useClearSession';
import SecureLS from 'secure-ls';
import { encodeValueFernet } from 'services/datadog/helper';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

type LogoutEventType = {
  event?: string;
  message?: string;
  additionalInfo?: Record<string, any>;
};

export const useHandleTokenExpiry = () => {
  const { clearAllUserSession } = useClearAllSession();

  const getUserInfoLocalstorage = () => {
    const user = ls.get('user');
    const userObj = user ? JSON.parse(user) : {};
    const lsEmail = userObj?.details?.userProfile?.email;
    const lsAuthToken = userObj?.token ? encodeValueFernet(userObj?.token) : undefined;
    return { lsEmail, lsAuthToken };
  };

  const handleLogoutWithoutHook = (logoutEvent?: LogoutEventType) => {
    const { lsEmail, lsAuthToken } = getUserInfoLocalstorage();
    if (!lsAuthToken) {
      window.location.replace(`${LOGIN}`);
      return;
    }
    datadogLogs.logger.info(`Force Logout ${logoutEvent?.message}`, {
      context: 'token expired',
      lsEmail,
      lsAuthToken,
      ...logoutEvent?.additionalInfo,
    });
    clearAllUserSession();
  };
  return {
    handleLogoutWithoutHook,
  };
};
