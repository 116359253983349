import { useRef } from 'react';
import { Device } from '@twilio/voice-sdk';
import { useReactiveVar } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';

import { twilioErrorsVar } from 'services/apollo/reactiveVars';
import { ToastMessage } from 'components/atoms';

import { TWILIO_ERRORS } from '../error';
import useWebRtcTest from './useWebRtcTest';

interface IProps {
  dispatch: React.Dispatch<any>;
  deviceInstance: Device | null;
  state: any;
  setDeviceStatus: (val: string) => void;
}

export function useHandlers(props: IProps) {
  const { deviceInstance, state, setDeviceStatus } = props;

  const callStateRef: any = useRef();
  const twilioErrorsRef: any = useRef();

  const { t } = useTranslation();
  const { checkPeerConnection } = useWebRtcTest();

  const twilioErrors = useReactiveVar(twilioErrorsVar);

  callStateRef.current = state;
  twilioErrorsRef.current = twilioErrors;

  const handleDeviceReady = () => {
    console.info('The device is ready to receive incoming calls.');
    setDeviceStatus('online');
    checkPeerConnection(30000);
  };

  const handleDeviceError = (error: any) => {
    if (deviceInstance?.state !== 'registered') {
      setDeviceStatus('offline');
    }
    if (TWILIO_ERRORS[error.code] !== undefined) {
      ToastMessage({
        content: t(TWILIO_ERRORS[error.code].errorKey, TWILIO_ERRORS[error.code].message),
        type: 'danger',
      });
    }
    const now = new Date();
    // Check if error repeated within the last 15 seconds
    const duplicateError = twilioErrorsRef.current.find((item: any) => {
      return (
        item?.error?.code === error.code &&
        (now.getTime() - new Date(item.dateTime).getTime()) / 1000 <= 15
      );
    });
    if (!duplicateError) {
      const filteredData = twilioErrorsRef.current.filter(
        (item: any) => item?.error?.code !== error.code,
      );
      twilioErrorsVar([
        ...filteredData,
        {
          error,
          dateTime: now,
        },
      ]);
      // Log error in only if the same error is repeated after15 seconds
      datadogLogs.logger.error('Twilio Error : Handle device error', {
        datadogError: error,
        context: 'twilio device',
      });
    }
  };

  const handleDeviceOffline = () => {
    console.info('The device is no longer able to receive calls.');
    setDeviceStatus('offline');
    // REJECT INCOMING CALL IF TWILIO DEVICE IS DESTROYED"
    callStateRef.current?.connection?.reject?.();
  };

  const handleRegisteringDevice = () => {
    console.info('Registering Device');
    setDeviceStatus('loading');
  };

  const handleTokenWillExpire = (callback: any) => {
    callback({});
  };

  return {
    handleDeviceReady,
    handleDeviceError,
    handleDeviceOffline,
    handleRegisteringDevice,
    handleTokenWillExpire,
  };
}
