import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
  Datetime: any;
  EmailAddress: any;
  FilterParams: any;
  JSON: any;
  ShortId: any;
  Upload: any;
};

export type AddNumberPayload = {
  __typename?: 'AddNumberPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<PendingNumber>;
};

export enum AddressRequirementType {
  None = 'none',
  Any = 'any',
  Local = 'local',
  Foreign = 'foreign',
}

export type Agent = {
  __typename?: 'Agent';
  id?: Maybe<Scalars['ShortId']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AgentDraftResponse = {
  __typename?: 'AgentDraftResponse';
  msg?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ShortId']>;
};

export type AgentDraftsInputData = {
  action: DraftAction;
  id?: Maybe<Scalars['ShortId']>;
  channel?: Maybe<Scalars['ShortId']>;
  contact?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type AgentDraftsPayload = {
  __typename?: 'AgentDraftsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<AgentDraftResponse>;
};

export type AgentInfo = {
  __typename?: 'AgentInfo';
  agentId?: Maybe<Scalars['ShortId']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type AllBlockedContactPayload = {
  __typename?: 'AllBlockedContactPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Client>>>;
};

export type AllCountriesPayload = {
  __typename?: 'AllCountriesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Country>>>;
};

export type AllNewContactPayload = {
  __typename?: 'AllNewContactPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Client>>>;
};

export type AllPlansPayload = {
  __typename?: 'AllPlansPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<PlanResponseData>>>;
};

export type AllTimeZonesPayload = {
  __typename?: 'AllTimeZonesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<TimeZone>>>;
};

export type AppInfo = {
  versionNo: Scalars['String'];
  versionForceUpdate?: Maybe<Scalars['Boolean']>;
  versionNeedClearData?: Maybe<Scalars['Boolean']>;
};

export type AppInfoData = {
  __typename?: 'AppInfoData';
  versionNo: Scalars['String'];
  versionForceUpdate?: Maybe<Scalars['Boolean']>;
  versionNeedClearData?: Maybe<Scalars['Boolean']>;
};

export type AppInfoPayload = {
  __typename?: 'AppInfoPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<AppInfoData>;
};

export type AppRegisterData = {
  __typename?: 'AppRegisterData';
  versionNo: Scalars['String'];
  versionForceUpdate?: Maybe<Scalars['Boolean']>;
  versionNeedClearData?: Maybe<Scalars['Boolean']>;
};

export type AppRegisterInputData = {
  versionNo: Scalars['String'];
  versionForceUpdate?: Maybe<Scalars['Boolean']>;
  versionNeedClearData?: Maybe<Scalars['Boolean']>;
};

export type AppRegisterPayload = {
  __typename?: 'AppRegisterPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<AppRegisterData>;
};

export type ArchieveContactThreadPayload = {
  __typename?: 'ArchieveContactThreadPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ArchieveConversationResult>;
};

export type ArchieveConversationResult = {
  __typename?: 'ArchieveConversationResult';
  msg: ArchieveStatus;
  contact: Scalars['String'];
  channel: Scalars['String'];
};

export enum ArchieveStatus {
  Archieved = 'ARCHIEVED',
  Unarchieved = 'UNARCHIEVED',
}

export enum ArchiveAction {
  Archieve = 'ARCHIEVE',
  Unarchieve = 'UNARCHIEVE',
}

export type ArchiveContactThreadInputData = {
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
  action: ArchiveAction;
};

export type ArchiveWorkspacesInputData = {
  workspace: Scalars['ShortId'];
};

export type ArchiveWorkspacesPayload = {
  __typename?: 'ArchiveWorkspacesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<SuccessPayloadData>;
};

export type AssignNumbersPayload = {
  __typename?: 'AssignNumbersPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MemberNode>;
};

export type AutoReply = {
  __typename?: 'AutoReply';
  id: Scalars['ShortId'];
  channelId: Scalars['ShortId'];
  createdBy: Scalars['ShortId'];
  message?: Maybe<Scalars['String']>;
  autoreplyType?: Maybe<AutoReplyType>;
  status?: Maybe<ResourceStatus>;
};

export type AutoReplyData = {
  __typename?: 'AutoReplyData';
  channelId: Scalars['ShortId'];
  status: Scalars['String'];
  message: Scalars['String'];
  autoreplyType: AutoReplyType;
};

export type AutoReplyDataPayload = {
  __typename?: 'AutoReplyDataPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<AutoReplyData>>>;
};

export type AutoReplyInputData = {
  channelId: Scalars['ShortId'];
  message?: Maybe<Scalars['String']>;
  autoreplyType: AutoReplyType;
  status?: Maybe<ResourceStatus>;
};

export type AutoReplyPayload = {
  __typename?: 'AutoReplyPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<AutoReply>;
};

export enum AutoReplyType {
  Fc = 'FC',
  Rc = 'RC',
}

export type AvailableCountriesPayload = {
  __typename?: 'AvailableCountriesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<AvailableCountry>>>;
};

export type AvailableCountry = {
  __typename?: 'AvailableCountry';
  countryCode: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingInformation = {
  __typename?: 'BillingInformation';
  billTo: Scalars['String'];
  taxId: Scalars['String'];
  country: Scalars['ShortId'];
  address: Scalars['String'];
  recipients: Array<Maybe<Scalars['String']>>;
};

export type BillingInformationInputData = {
  billTo: Scalars['String'];
  taxId: Scalars['String'];
  country: Scalars['ShortId'];
  address: Scalars['String'];
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingInformationPayload = {
  __typename?: 'BillingInformationPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<BillingInformation>;
};

export type BillingSettings = {
  __typename?: 'BillingSettings';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
};

export type BlockContactInputData = {
  blocked: Scalars['Boolean'];
};

export type BlockContactPayload = {
  __typename?: 'BlockContactPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ContactNode>;
};

export type BlockContactsInputData = {
  blocked: Scalars['Boolean'];
  contacts: Array<Maybe<Scalars['ShortId']>>;
};

export type BlockNumberInputData = {
  blocked: Scalars['Boolean'];
};

export type BulkMessageInput = {
  channel?: Maybe<Scalars['ShortId']>;
  content?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BulkMessagePayload = {
  __typename?: 'BulkMessagePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data: BulkMessagePayloadData;
};

export type BulkMessagePayloadData = {
  __typename?: 'BulkMessagePayloadData';
  id?: Maybe<Scalars['ShortId']>;
  channel?: Maybe<Scalars['ShortId']>;
  content?: Maybe<Scalars['ShortId']>;
  contacts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum CallDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export type CallRatingInputData = {
  rating?: Maybe<Scalars['Int']>;
};

export type CallRatingSupportConnection = Connection & {
  __typename?: 'CallRatingSupportConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CallRatingSupportEdge>>>;
};

export type CallRatingSupportEdge = Edge & {
  __typename?: 'CallRatingSupportEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<CallRatingSupportNode>;
};

export type CallRatingSupportNode = {
  __typename?: 'CallRatingSupportNode';
  date?: Maybe<Scalars['Datetime']>;
  number: Scalars['String'];
  contactedNumber: Scalars['String'];
  workspaceId?: Maybe<Scalars['ShortId']>;
  workspaceName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  ratings?: Maybe<Scalars['Int']>;
};

export type CallRatingSupportPayload = {
  __typename?: 'CallRatingSupportPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<CallRatingSupportConnection>;
};

export enum CallStrategy {
  Forward = 'Forward',
  Ivr = 'IVR',
}

export type CallStrategySettingsInput = {
  incomingCallStrategy?: Maybe<CallStrategy>;
  incomingCallForward?: Maybe<IncomingCallForward>;
  externalNumber?: Maybe<Scalars['String']>;
  unanswerCallsFallback?: Maybe<UnanswerCallFallback>;
  fallbackExternalNumber?: Maybe<Scalars['String']>;
  simultaneousDialing?: Maybe<Scalars['Boolean']>;
};

export enum CalleIdVerificationStatus {
  Verified = 'Verified',
  Pending = 'Pending',
  Failed = 'Failed',
  Deleted = 'Deleted',
}

export enum CallerIdAction {
  Add = 'Add',
  Delete = 'Delete',
}

export type CallerIdData = {
  __typename?: 'CallerIdData';
  channel?: Maybe<Scalars['ShortId']>;
  callerId?: Maybe<Scalars['String']>;
  status?: Maybe<CallerIdStatus>;
  action?: Maybe<CallerIdAction>;
};

export type CallerIdDataPayload = {
  __typename?: 'CallerIdDataPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<CallerIdData>;
};

export type CallerIdNumberInput = {
  channel: Scalars['ShortId'];
  number: Scalars['String'];
  action?: Maybe<CallerIdAction>;
};

export type CallerIdPayload = {
  __typename?: 'CallerIdPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<VerifiedCallerIdData>>>;
};

export type CallerIdRequestData = {
  __typename?: 'CallerIdRequestData';
  code: Scalars['Int'];
  message: Scalars['String'];
  status: CalleIdVerificationStatus;
};

export type CallerIdRequestPayload = {
  __typename?: 'CallerIdRequestPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<CallerIdRequestData>;
};

export enum CallerIdStatus {
  Approved = 'Approved',
  Requested = 'Requested',
  Progress = 'Progress',
  Rejected = 'Rejected',
}

export type CallerIdStatusInputData = {
  ticketNumber: Scalars['String'];
  calleridStatus?: Maybe<CallerIdStatus>;
};

export type CallerIdSupportConnection = Connection & {
  __typename?: 'CallerIdSupportConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<CallerIdSupportEdge>>>;
};

export type CallerIdSupportEdge = Edge & {
  __typename?: 'CallerIdSupportEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<CallerIdSupportNode>;
};

export type CallerIdSupportNode = {
  __typename?: 'CallerIdSupportNode';
  ticketNumber: Scalars['String'];
  number: Scalars['String'];
  callerId: Scalars['String'];
  workspaceId?: Maybe<Scalars['ShortId']>;
  workspaceName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  plan: Scalars['String'];
  ticketStatus?: Maybe<TicketStatusType>;
  issueDate: Scalars['Datetime'];
  closeDate?: Maybe<Scalars['Datetime']>;
  action?: Maybe<CallerIdAction>;
  callerIdStatus?: Maybe<CallerIdStatus>;
};

export type CallerIdSupportPayload = {
  __typename?: 'CallerIdSupportPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<CallerIdSupportConnection>;
};

export type CallerIdTicketStatusInputData = {
  ticketNumber: Scalars['String'];
  ticketStatus: TicketStatusType;
};

export type CancelStatus = {
  __typename?: 'CancelStatus';
  CancelStatus?: Maybe<Scalars['Boolean']>;
};

export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<CancelSubscriptionResponseData>;
};

export type CancelSubscriptionResponseData = {
  __typename?: 'CancelSubscriptionResponseData';
  subscriptionActive?: Maybe<Scalars['String']>;
};

export type CardResponseData = {
  __typename?: 'CardResponseData';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  expiryMonth?: Maybe<Scalars['String']>;
  expiryYear?: Maybe<Scalars['String']>;
  lastDigit?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
};

export type CardsPayload = {
  __typename?: 'CardsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<CardResponseData>>>;
};

export type ChangeDefaultLanguage = {
  defaultLanguage: Scalars['String'];
};

export type ChangeDefaultTimezone = {
  defaultTimezone: Scalars['String'];
};

export type ChangeDefaultWorkspace = {
  defaultWorkspace: Scalars['String'];
};

export type ChangeEmailInputData = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
  confirmEmail: Scalars['EmailAddress'];
};

export type ChangeEmailPayload = {
  __typename?: 'ChangeEmailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChangeEmailPayloadData>;
};

export type ChangeEmailPayloadData = {
  __typename?: 'ChangeEmailPayloadData';
  success: Scalars['Boolean'];
};

export type ChangePasswordInputData = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
};

export type ChangePlanInputData = {
  id: Scalars['ShortId'];
};

export type ChangePlanPayload = {
  __typename?: 'ChangePlanPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChangePlanResponseData>;
};

export type ChangePlanResponseData = {
  __typename?: 'ChangePlanResponseData';
  id?: Maybe<Scalars['ShortId']>;
};

export type ChangeProfileNames = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type ChangeUserProfilePicture = {
  profilePicture: Scalars['Upload'];
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ShortId'];
  countryLogo: Scalars['String'];
  country: Scalars['ShortId'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
  name: Scalars['String'];
  dndEndtime?: Maybe<Scalars['Int']>;
  dndEnabled?: Maybe<Scalars['Boolean']>;
  dndRemainingTime?: Maybe<Scalars['Int']>;
  dndOn?: Maybe<Scalars['Datetime']>;
  dndDuration?: Maybe<Scalars['Int']>;
  unseenMessageCount?: Maybe<Scalars['Int']>;
  settings?: Maybe<ChannelSettings>;
  order?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  call?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ResourceStatus>;
};

export type ChannelAccessData = {
  __typename?: 'ChannelAccessData';
  id: Scalars['ShortId'];
  name: Scalars['String'];
  shared?: Maybe<Scalars['Boolean']>;
  agents?: Maybe<Array<Maybe<Agent>>>;
};

export type ChannelAccessPayload = {
  __typename?: 'ChannelAccessPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<ChannelAccessData>;
};

export type ChannelContactStateInputData = {
  contact: Scalars['String'];
  closed: Scalars['Boolean'];
};

export type ChannelDndSubscription = {
  __typename?: 'ChannelDndSubscription';
  event: Scalars['String'];
  message?: Maybe<ChannelDndSubscriptionPayload>;
};

export type ChannelDndSubscriptionPayload = {
  __typename?: 'ChannelDndSubscriptionPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChannelResponseData>;
};

export type ChannelDraftsNode = {
  __typename?: 'ChannelDraftsNode';
  id?: Maybe<Scalars['ShortId']>;
  content?: Maybe<Scalars['String']>;
  clientNumber?: Maybe<Scalars['String']>;
};

export type ChannelDraftsPayload = {
  __typename?: 'ChannelDraftsPayload';
  channel?: Maybe<Scalars['ShortId']>;
  messages?: Maybe<Array<Maybe<ChannelDraftsNode>>>;
};

export type ChannelGreetingInfo = {
  __typename?: 'ChannelGreetingInfo';
  id: Scalars['ShortId'];
  recordingUrl: Scalars['String'];
  greetingType: GreetingType;
  recordingType: RecordingType;
  source?: Maybe<Scalars['String']>;
  voice?: Maybe<Scalars['String']>;
  accent?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ChannelInfo = {
  __typename?: 'ChannelInfo';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data: ChannelInfoData;
};

export type ChannelInfoData = {
  __typename?: 'ChannelInfoData';
  id: Scalars['ShortId'];
  country: Scalars['ShortId'];
  name: Scalars['String'];
  countryLogo: Scalars['String'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
  call?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type ChannelInfoo = {
  __typename?: 'ChannelInfoo';
  channelId?: Maybe<Scalars['ShortId']>;
  number?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['ShortId']>;
  call?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type ChannelInputData = {
  country: Scalars['ShortId'];
  name: Scalars['String'];
  number: Scalars['String'];
  type: Scalars['String'];
  numberSid: Scalars['String'];
  agents: Array<Maybe<Scalars['ShortId']>>;
  call?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type ChannelInputDataSupport = {
  workspaceSid: Scalars['ShortId'];
  country: Scalars['ShortId'];
  name: Scalars['String'];
  number: Scalars['String'];
  type: Scalars['String'];
  numberSid: Scalars['String'];
  agents: Array<Maybe<Scalars['ShortId']>>;
  call?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type ChannelOrderInputData = {
  channelorder?: Maybe<Array<Channelorder>>;
};

export type ChannelPayload = {
  __typename?: 'ChannelPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChannelResponseData>;
};

export type ChannelResponseData = {
  __typename?: 'ChannelResponseData';
  id: Scalars['ShortId'];
  number: Scalars['String'];
  numberSid?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  call?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type ChannelSettings = {
  __typename?: 'ChannelSettings';
  shared?: Maybe<Scalars['Boolean']>;
  autoRecordCalls?: Maybe<Scalars['Boolean']>;
  internationalCallAndMessages?: Maybe<Scalars['Boolean']>;
  emailNotification?: Maybe<Scalars['Boolean']>;
  incomingCallStrategy?: Maybe<CallStrategy>;
  incomingCallForward?: Maybe<IncomingCallForward>;
  unanswerCallsFallback?: Maybe<UnanswerCallFallback>;
};

export type ChannelSettingsNode = {
  __typename?: 'ChannelSettingsNode';
  id: Scalars['ShortId'];
  name?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  callerId?: Maybe<Scalars['String']>;
  autoRecordCalls?: Maybe<Scalars['Boolean']>;
  externalNumber?: Maybe<Scalars['String']>;
  simultaneousDialing?: Maybe<Scalars['Boolean']>;
  externalFallbackNumber?: Maybe<Scalars['String']>;
  internationalCallAndMessages?: Maybe<Scalars['Boolean']>;
  abilities?: Maybe<NumberAbilities>;
  emailNotification?: Maybe<Scalars['Boolean']>;
  transcription?: Maybe<Scalars['Boolean']>;
  incomingCallStrategy?: Maybe<CallStrategy>;
  incomingCallForward?: Maybe<IncomingCallForward>;
  unanswerCallsFallback?: Maybe<UnanswerCallFallback>;
  agents?: Maybe<Array<Maybe<Agent>>>;
  shared?: Maybe<Scalars['Boolean']>;
};

export type ChannelSettingsPayload = {
  __typename?: 'ChannelSettingsPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<ChannelSettingsNode>;
};

export type ChannelSettingsUpdateNode = {
  __typename?: 'ChannelSettingsUpdateNode';
  autoRecordCalls?: Maybe<Scalars['Boolean']>;
  internationalCallAndMessages?: Maybe<Scalars['Boolean']>;
  emailNotification?: Maybe<Scalars['Boolean']>;
  incomingCallStrategy?: Maybe<CallStrategy>;
  incomingCallForward?: Maybe<IncomingCallForward>;
  unanswerCallsFallback?: Maybe<UnanswerCallFallback>;
  externalNumber?: Maybe<Scalars['String']>;
  externalFallbackNumber?: Maybe<Scalars['String']>;
  simultaneousDialing?: Maybe<Scalars['Boolean']>;
};

export type ChannelSettingsUpdatePayload = {
  __typename?: 'ChannelSettingsUpdatePayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<ChannelSettingsUpdateNode>;
};

export type ChannelsPayload = {
  __typename?: 'ChannelsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Channel>>>;
};

export type ChargeCreditInputData = {
  amount: Scalars['Int'];
};

export type ChargeCreditPayload = {
  __typename?: 'ChargeCreditPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
};

export type ChatHisoryConnection = {
  __typename?: 'ChatHisoryConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ChatHisoryEdge>>>;
};

export type ChatHisoryEdge = Edge & {
  __typename?: 'ChatHisoryEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<ChatMessageNode>;
};

export type ChatHistoryPayload = {
  __typename?: 'ChatHistoryPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChatHisoryConnection>;
};

export type ChatMessaagePayload = {
  __typename?: 'ChatMessaagePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChatMessageNode>;
};

export type ChatMessageInput = {
  message: Scalars['String'];
  receiver: Scalars['ShortId'];
};

export type ChatMessageNode = Node & {
  __typename?: 'ChatMessageNode';
  id: Scalars['ShortId'];
  sender?: Maybe<MemberInfo>;
  receiver?: Maybe<MemberInfo>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<ConversationType>;
  createdOn?: Maybe<Scalars['Datetime']>;
  modifiedOn?: Maybe<Scalars['Datetime']>;
  status?: Maybe<MemberChatStatus>;
};

export type CheckEmailPayload = {
  __typename?: 'CheckEmailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<SuccessPayloadData>;
};

export type CheckNamePayload = {
  __typename?: 'CheckNamePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ResourceSucceedPayloadData>;
};

export type CheckoutNumber = {
  __typename?: 'CheckoutNumber';
  name: Scalars['String'];
  number: Scalars['String'];
  country: Scalars['ShortId'];
  address?: Maybe<Scalars['String']>;
  setUpFee: Scalars['Float'];
  monthlyFee: Scalars['Float'];
  sms: Scalars['Boolean'];
  purchaseStatus: Scalars['String'];
  mms: Scalars['Boolean'];
  call: Scalars['Boolean'];
  type: NumberType;
  status?: Maybe<PendingNumbersEnum>;
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['ShortId'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['ShortId']>;
  country?: Maybe<Scalars['ShortId']>;
  blocked?: Maybe<Scalars['Boolean']>;
  profilePicture?: Maybe<Scalars['String']>;
  markAsRead?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  createdBy?: Maybe<Scalars['ShortId']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  dndEndtime?: Maybe<Scalars['Int']>;
  dndEnabled?: Maybe<Scalars['Boolean']>;
  dndDuration?: Maybe<Scalars['Int']>;
};

export type ClientDndInput = {
  contact: Scalars['String'];
  minutes?: Maybe<Scalars['Int']>;
  removeFromDND?: Maybe<Scalars['Boolean']>;
};

export type ClientDndPayload = {
  __typename?: 'ClientDNDPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ContactNode>;
};

export type ClientDetailPayload = {
  __typename?: 'ClientDetailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Client>;
};

export type ClientInfo = {
  __typename?: 'ClientInfo';
  id?: Maybe<Scalars['ShortId']>;
  number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['ShortId']>;
  createdBy?: Maybe<Scalars['ShortId']>;
  profilePicture?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  dndEnabled?: Maybe<Scalars['Boolean']>;
  dndDuration?: Maybe<Scalars['Int']>;
  dndEndtime?: Maybe<Scalars['Int']>;
};

export type ClientRecordingsConnection = {
  __typename?: 'ClientRecordingsConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ClientRecordingsEdge>>>;
};

export type ClientRecordingsEdge = Edge & {
  __typename?: 'ClientRecordingsEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<ClientRecordingsNode>;
};

export type ClientRecordingsNode = {
  __typename?: 'ClientRecordingsNode';
  id?: Maybe<Scalars['ShortId']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  direction?: Maybe<ConversationDirection>;
  content?: Maybe<ConversationContent>;
  seen?: Maybe<Scalars['Boolean']>;
};

export type ClientRecordingsPayload = {
  __typename?: 'ClientRecordingsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ClientRecordingsConnection>;
};

export type ClientTagPayload = {
  __typename?: 'ClientTagPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Client>;
};

export type Connection = {
  pageInfo: PageInfo;
};

export type ConnectionInput = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  afterWith?: Maybe<Scalars['Cursor']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  beforeWith?: Maybe<Scalars['Cursor']>;
  q?: Maybe<Scalars['String']>;
  s?: Maybe<Scalars['String']>;
  search?: Maybe<SearchInput>;
  filter?: Maybe<Scalars['FilterParams']>;
  filters?: Maybe<Array<Maybe<Scalars['FilterParams']>>>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Ordering>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  countryLogo: Scalars['String'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
  name: Scalars['Int'];
};

export type ContactAllPayload = {
  __typename?: 'ContactAllPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ContactConnection>;
};

export type ContactConnection = Connection & {
  __typename?: 'ContactConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ContactEdge>>>;
};

export type ContactConversationPayload = {
  __typename?: 'ContactConversationPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<ConversationNode>>>;
};

export type ContactCsvInputData = {
  file: Scalars['Upload'];
  visibility?: Maybe<Scalars['Boolean']>;
};

export type ContactDetailPayload = {
  __typename?: 'ContactDetailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ContactNode>;
};

export type ContactEdge = Edge & {
  __typename?: 'ContactEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<ContactNode>;
};

export type ContactHistoryListPayload = {
  __typename?: 'ContactHistoryListPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data: Array<Maybe<ContactHistoryLog>>;
};

export type ContactHistoryLog = {
  __typename?: 'ContactHistoryLog';
  id: Scalars['ShortId'];
  file_name: Scalars['String'];
  count: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type ContactHistoryRemovePayload = {
  __typename?: 'ContactHistoryRemovePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data: ContactHistoryLog;
};

export type ContactInputData = {
  country?: Maybe<Scalars['ShortId']>;
  address?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  photoUpload?: Maybe<Scalars['Upload']>;
  markAsRead?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContactListPayload = {
  __typename?: 'ContactListPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data: Array<Maybe<Client>>;
};

export type ContactNode = Node & {
  __typename?: 'ContactNode';
  id: Scalars['ShortId'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Datetime']>;
  number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  blocked?: Maybe<Scalars['Boolean']>;
  dndEnabled?: Maybe<Scalars['Boolean']>;
  dndDuration?: Maybe<Scalars['Int']>;
  dndEndtime?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['ShortId']>;
  clientId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['ShortId']>;
  flagUrl?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type ContactPayload = {
  __typename?: 'ContactPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Client>;
};

export type ContactResponseData = {
  __typename?: 'ContactResponseData';
  id: Scalars['String'];
  clientId: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
};

export type ContactsLogsPayload = {
  __typename?: 'ContactsLogsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ConversationConnection>;
};

export type ConversationAgentData = {
  seen?: Maybe<Scalars['Boolean']>;
  pinned?: Maybe<Scalars['Boolean']>;
  favourite?: Maybe<Scalars['Boolean']>;
};

export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<ConversationEdge>>>;
};

export type ConversationContent = {
  __typename?: 'ConversationContent';
  body?: Maybe<Scalars['String']>;
  transferedAudio?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  callDuration?: Maybe<Scalars['String']>;
  callTime?: Maybe<Scalars['String']>;
  transcript?: Maybe<Scalars['String']>;
};

export type ConversationContentInput = {
  body?: Maybe<Scalars['String']>;
};

export enum ConversationDirection {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export type ConversationEdge = Edge & {
  __typename?: 'ConversationEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<ConversationNode>;
};

export type ConversationHistoryPayload = {
  __typename?: 'ConversationHistoryPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ConversationConnection>;
};

export type ConversationInput = {
  conversationType: Scalars['String'];
  content: ConversationContentInput;
  contact: Scalars['String'];
  channel: Scalars['ShortId'];
  direction?: Maybe<ConversationDirection>;
};

export type ConversationListPayload = {
  __typename?: 'ConversationListPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<ConversationNode>>>;
};

export type ConversationNode = {
  __typename?: 'ConversationNode';
  id: Scalars['ShortId'];
  conversationStatus: ConversationStatus;
  transcriptionStatus: TranscriptionStatus;
  conversationType: ConversationType;
  direction: ConversationDirection;
  seen?: Maybe<Scalars['Boolean']>;
  contactClosed?: Maybe<Scalars['Boolean']>;
  content?: Maybe<ConversationContent>;
  createdAt?: Maybe<Scalars['Datetime']>;
  agentId?: Maybe<Scalars['ShortId']>;
  clientNumber?: Maybe<Scalars['String']>;
  clientCountryFlag?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  voicemailAudio?: Maybe<Scalars['String']>;
  clientCountry?: Maybe<Scalars['ShortId']>;
  status?: Maybe<Scalars['String']>;
  contactPinned?: Maybe<Scalars['Boolean']>;
  clientUnseenMsgCount?: Maybe<Scalars['Int']>;
  clientInfo?: Maybe<ClientInfo>;
  agentInfo?: Maybe<AgentInfo>;
  channelInfo: ChannelInfoo;
  personalizedInfo?: Maybe<PersonalizedInfo>;
  unreadConversationInfo?: Maybe<UnreadConversationInfo>;
  modifiedAt?: Maybe<Scalars['Datetime']>;
  errorMessage?: Maybe<Scalars['String']>;
  medias?: Maybe<Array<Scalars['String']>>;
  smsForwardedNumber?: Maybe<Scalars['String']>;
};

export type ConversationPayload = {
  __typename?: 'ConversationPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ConversationNode>;
};

export enum ConversationStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Failed = 'FAILED',
  Delivered = 'DELIVERED',
  Busy = 'BUSY',
  Completed = 'COMPLETED',
  Noanswer = 'NOANSWER',
  Canceled = 'CANCELED',
  Inprogress = 'INPROGRESS',
  Ringing = 'RINGING',
  Onhold = 'ONHOLD',
  Attempted = 'ATTEMPTED',
  Rejected = 'REJECTED',
}

export enum TranscriptionStatus {
  NotRequested = 'NOT_REQUESTED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export type ConversationSubscription = {
  __typename?: 'ConversationSubscription';
  event: Scalars['String'];
  message: ConversationNode;
};

export enum ConversationType {
  Call = 'Call',
  Message = 'Message',
  VoiceMail = 'VoiceMail',
}

export type CountPayload = {
  __typename?: 'CountPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<Scalars['Int']>;
};

export type CountSubscriptionData = {
  __typename?: 'CountSubscriptionData';
  channel: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
};

export type Country = {
  __typename?: 'Country';
  uid: Scalars['ShortId'];
  name: Scalars['String'];
  alphaTwoCode: Scalars['String'];
  dialingCode: Scalars['String'];
  flagUrl: Scalars['String'];
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CountryPayLoad = {
  __typename?: 'CountryPayLoad';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Country>;
};

export type Credit = {
  __typename?: 'Credit';
  id: Scalars['ShortId'];
  amount?: Maybe<Scalars['Float']>;
};

export type DndPayload = {
  __typename?: 'DNDPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Dnd>;
};

export type DefaultCardInputData = {
  memberId: Scalars['ShortId'];
  workspaceId: Scalars['ShortId'];
};

export type DeleteChannel = {
  __typename?: 'DeleteChannel';
  id?: Maybe<Scalars['ShortId']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteChannelPayload = {
  __typename?: 'DeleteChannelPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<DeleteChannel>;
};

export type DeleteContactsInputData = {
  contacts?: Maybe<Array<Maybe<Scalars['ShortId']>>>;
};

export type DeviceInfo = {
  platform: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  fcmToken: Scalars['String'];
};

export type DeviceInfoData = {
  __typename?: 'DeviceInfoData';
  id: Scalars['ShortId'];
  platform: Scalars['String'];
  version: Scalars['String'];
  fcmToken: Scalars['String'];
};

export type DeviceInfoPayload = {
  __typename?: 'DeviceInfoPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<DeviceInfoData>;
};

export type DeviceRegisterData = {
  __typename?: 'DeviceRegisterData';
  id?: Maybe<Scalars['ShortId']>;
  version?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  callMessages?: Maybe<Scalars['Boolean']>;
  newLeads?: Maybe<Scalars['Boolean']>;
  flashTaskbar?: Maybe<Scalars['Boolean']>;
};

export type DeviceRegisterInputData = {
  platform: Scalars['String'];
  fcmToken: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type DeviceRegisterPayload = {
  __typename?: 'DeviceRegisterPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<DeviceRegisterData>;
};

export type DevicesPayload = {
  __typename?: 'DevicesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<DeviceRegisterData>;
};

export type Dnd = {
  __typename?: 'Dnd';
  dndEnabled: Scalars['Boolean'];
  dndEndtime?: Maybe<Scalars['Int']>;
  dndRemainingTime?: Maybe<Scalars['Int']>;
};

export enum DraftAction {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export type Edge = {
  cursor: Scalars['Cursor'];
};

export type EditChannelShareInput = {
  shared?: Maybe<Scalars['Boolean']>;
  agents?: Maybe<Array<Maybe<Scalars['ShortId']>>>;
};

export type EditChatStatus = {
  id: Scalars['ID'];
  status: MemberChatStatus;
};

export type EditContact = {
  __typename?: 'EditContact';
  id: Scalars['ID'];
  countryLogo: Scalars['String'];
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type EditContactInputData = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  photoUpload?: Maybe<Scalars['Upload']>;
  tags?: Maybe<Array<Maybe<Scalars['ShortId']>>>;
  markAsRead?: Maybe<Scalars['Boolean']>;
};

export type EditContactPayload = {
  __typename?: 'EditContactPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Client>;
};

export type EditContactResponseData = {
  __typename?: 'EditContactResponseData';
  id: Scalars['String'];
  clientId: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
};

export type EditMemberPayload = {
  __typename?: 'EditMemberPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MemberNode>;
};

export type EditMemberRoleInputData = {
  role: MemberRole;
};

export type EditMemberStatusInputData = {
  status: Scalars['String'];
};

export type EditNoteInputData = {
  title: Scalars['String'];
};

export type EditNotificationInputData = {
  notification: Scalars['Boolean'];
};

export type EditOnlineStatusInputData = {
  onlineStatus: Scalars['Boolean'];
};

export type EditTagInputData = {
  title: Scalars['String'];
  background_color_code?: Maybe<Scalars['String']>;
  color_code?: Maybe<Scalars['String']>;
};

export type EditTeam = {
  __typename?: 'EditTeam';
  id: Scalars['ID'];
  name: Scalars['String'];
  total: Scalars['Int'];
  online: Scalars['Int'];
};

export type EditWorkspace = {
  __typename?: 'EditWorkspace';
  id: Scalars['ID'];
  name: Scalars['String'];
  members?: Maybe<Array<Maybe<Member>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type EditWorkspaceInputData = {
  title: Scalars['String'];
};

export type EditWorkspacePayload = {
  __typename?: 'EditWorkspacePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<EditWorkspaceResponseData>;
};

export type EditWorkspacePhotoPayload = {
  __typename?: 'EditWorkspacePhotoPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<EditWorkspacePhotoResponseData>;
};

export type EditWorkspacePhotoResponseData = {
  __typename?: 'EditWorkspacePhotoResponseData';
  id: Scalars['String'];
  title: Scalars['String'];
  photo: Scalars['String'];
};

export type EditWorkspaceResponseData = {
  __typename?: 'EditWorkspaceResponseData';
  id: Scalars['String'];
  title: Scalars['String'];
  memberId: Scalars['String'];
};

export type EmailVerificationInputData = {
  emailVerificationLink?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type EmailVerificationPayload = {
  __typename?: 'EmailVerificationPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<SuccessPayloadData>;
};

export type EndQueueData = {
  __typename?: 'EndQueueData';
  conversation_id?: Maybe<Scalars['ShortId']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EndQueueInput = {
  conversationId: Scalars['ShortId'];
  direction: ConversationDirection;
};

export type EndQueuePayload = {
  __typename?: 'EndQueuePayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<EndQueueData>;
};

export type EnterpriseSupportConnection = Connection & {
  __typename?: 'EnterpriseSupportConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<EnterpriseSupportEdge>>>;
};

export type EnterpriseSupportEdge = Edge & {
  __typename?: 'EnterpriseSupportEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<EnterpriseSupportNode>;
};

export type EnterpriseSupportInputData = {
  ticketNumber: Scalars['String'];
  ticketStatus: TicketStatusType;
};

export type EnterpriseSupportNode = {
  __typename?: 'EnterpriseSupportNode';
  ticketNumber: Scalars['String'];
  workspaceId?: Maybe<Scalars['ShortId']>;
  workspaceName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  plan: Scalars['String'];
  trial?: Maybe<Scalars['String']>;
  ticketStatus?: Maybe<TicketStatusType>;
  rate: Scalars['String'];
  issueDate: Scalars['Datetime'];
  closeDate?: Maybe<Scalars['Datetime']>;
};

export type EnterpriseSupportPayload = {
  __typename?: 'EnterpriseSupportPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<EnterpriseSupportConnection>;
};

export type ErrorData = {
  __typename?: 'ErrorData';
  code: Scalars['Int'];
  message: Scalars['String'];
  errorKey?: Maybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
  email: Scalars['EmailAddress'];
  route?: Maybe<Scalars['String']>;
};

export type GeneralChannelSettingsInput = {
  emoji?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  autoRecordCalls?: Maybe<Scalars['Boolean']>;
  internationalCallAndMessages?: Maybe<Scalars['Boolean']>;
  emailNotification?: Maybe<Scalars['Boolean']>;
  transcription?: Maybe<Scalars['Boolean']>;
};

export type GenerateInviteLinkPaylod = {
  __typename?: 'GenerateInviteLinkPaylod';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<InviteLink>;
};

export type GoogleContactIntegration = {
  __typename?: 'GoogleContactIntegration';
  id: Scalars['ShortId'];
  email?: Maybe<Scalars['String']>;
  totalContactImported?: Maybe<Scalars['Int']>;
};

export type GoogleContactIntegrationListPayload = {
  __typename?: 'GoogleContactIntegrationListPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<GoogleContactIntegration>>>;
};

export type GoogleOauthRedirect = {
  __typename?: 'GoogleOauthRedirect';
  auth_url?: Maybe<Scalars['String']>;
};

export type GoogleOauthRedirectPayload = {
  __typename?: 'GoogleOauthRedirectPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<GoogleOauthRedirect>;
};

export type GoogleOauthResponse = {
  authResponse?: Maybe<Scalars['String']>;
};

export type GreetingInputData = {
  file?: Maybe<Scalars['Upload']>;
  source?: Maybe<Scalars['String']>;
  voice?: Maybe<Scalars['String']>;
  accent?: Maybe<Scalars['String']>;
  greetingType: GreetingType;
  recordingType: RecordingType;
};

export type GreetingListPayload = {
  __typename?: 'GreetingListPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<ChannelGreetingInfo>>>;
};

export type GreetingPayload = {
  __typename?: 'GreetingPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChannelGreetingInfo>;
};

export type GreetingToggleInfo = {
  __typename?: 'GreetingToggleInfo';
  id: Scalars['ShortId'];
  greetingType: GreetingType;
  recordingType: RecordingType;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type GreetingToggleInputData = {
  source?: Maybe<Scalars['String']>;
  greetingType: GreetingType;
  recordingType: RecordingType;
};

export type GreetingTogglePayload = {
  __typename?: 'GreetingTogglePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<GreetingToggleInfo>;
};

export enum GreetingType {
  Welcome = 'Welcome',
  Voicemail = 'Voicemail',
  Hold = 'Hold',
  Queue = 'Queue',
}

export enum HoldAction {
  Hold = 'HOLD',
  Unhold = 'UNHOLD',
}

export type HoldCall = {
  __typename?: 'HoldCall';
  message?: Maybe<Scalars['String']>;
};

export type HoldCallInput = {
  action: HoldAction;
  direction: CallDirection;
  conversation_id: Scalars['ShortId'];
  channel_id: Scalars['ShortId'];
};

export type HoldCallPayload = {
  __typename?: 'HoldCallPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<HoldCall>;
};

export enum IncomingCallForward {
  WebAndMobileApps = 'WebAndMobileApps',
  TeamMember = 'TeamMember',
  ExternalNumber = 'ExternalNumber',
  VoiceMail = 'VoiceMail',
}

export type IntentResponseData = {
  __typename?: 'IntentResponseData';
  clientSecret: Scalars['String'];
};

export type InviteAcceptInput = {
  firstname: Scalars['String'];
  lastname?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  email: Scalars['String'];
  client: Scalars['String'];
  device: Scalars['String'];
  confirmPassword: Scalars['String'];
  invitationCode: Scalars['String'];
  defaultTimezone: Scalars['String'];
};

export type InviteAcceptPayload = {
  __typename?: 'InviteAcceptPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<InviteAcceptResponseData>;
};

export type InviteAcceptResponseData = {
  __typename?: 'InviteAcceptResponseData';
  workspace: Scalars['ShortId'];
  refreshToken: Scalars['String'];
  authToken: Scalars['String'];
  accessToken: Scalars['String'];
};

export type InviteCode = {
  invitationCode: Scalars['String'];
  inviteLink: Scalars['String'];
};

export type InviteConnection = Connection & {
  __typename?: 'InviteConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<InviteEdge>>>;
};

export type InviteDetailData = {
  __typename?: 'InviteDetailData';
  email: Scalars['String'];
  workspace?: Maybe<WorkspaceDetail>;
  invitationType: Scalars['String'];
};

export type InviteDetailPayload = {
  __typename?: 'InviteDetailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<InviteDetailData>;
};

export type InviteEdge = Edge & {
  __typename?: 'InviteEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<InviteNode>;
};

export type InviteLink = {
  __typename?: 'InviteLink';
  link: Scalars['String'];
};

export type InviteMemberInputData = {
  email: Scalars['String'];
  role: MemberRole;
  inviteLink: Scalars['String'];
};

export type InviteNode = Node & {
  __typename?: 'InviteNode';
  id: Scalars['ShortId'];
  createdOn?: Maybe<Scalars['Datetime']>;
  status?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type InviteResendPayload = {
  __typename?: 'InviteResendPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Message>;
};

export type InvitesPayload = {
  __typename?: 'InvitesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<InviteConnection>;
};

export type Invoice = {
  __typename?: 'Invoice';
  invoiceNumber: Scalars['String'];
  createdOn: Scalars['String'];
  billingInformation?: Maybe<BillingInformation>;
  totalAmount: Scalars['Float'];
  invoiceItems?: Maybe<Array<Maybe<InvoiceItems>>>;
  cardDetail?: Maybe<CardResponseData>;
};

export type InvoiceDetailResponseData = {
  __typename?: 'InvoiceDetailResponseData';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Invoice>;
};

export type InvoiceItems = {
  __typename?: 'InvoiceItems';
  unitPrice?: Maybe<Scalars['Float']>;
  content?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
};

export type IvrMessagePayload = {
  __typename?: 'IvrMessagePayload';
  source?: Maybe<Scalars['String']>;
  voice?: Maybe<Scalars['String']>;
  accent?: Maybe<Scalars['String']>;
  recordingUrl?: Maybe<Scalars['String']>;
  recordingType?: Maybe<Scalars['String']>;
  ivrOptions?: Maybe<Array<Maybe<IvrOptionsPayload>>>;
};

export type IvrOptionsData = {
  extensionNumber: Scalars['String'];
  forwardTo: Scalars['ShortId'];
  unansweredForwardTo: UnansweredForwardToType;
};

export type IvrOptionsPayload = {
  __typename?: 'IvrOptionsPayload';
  extensionNumber?: Maybe<Scalars['String']>;
  forwardTo?: Maybe<Scalars['ShortId']>;
  unansweredForwardTo?: Maybe<Scalars['String']>;
};

export enum IvrRecordingType {
  Tts = 'TTS',
  Custom = 'Custom',
}

export type IvrSettingsInputData = {
  file?: Maybe<Scalars['Upload']>;
  source?: Maybe<Scalars['String']>;
  voice?: Maybe<Scalars['String']>;
  accent?: Maybe<Scalars['String']>;
  recordingType?: Maybe<IvrRecordingType>;
  ivrOptions?: Maybe<Array<Maybe<IvrOptionsData>>>;
};

export type IvrSettingsPayload = {
  __typename?: 'IvrSettingsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<IvrMessagePayload>;
};

export type LastContactData = {
  __typename?: 'LastContactData';
  channel?: Maybe<Scalars['ShortId']>;
  contact?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type LastContactedDataPayload = {
  __typename?: 'LastContactedDataPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<LastContactData>;
};

export type LoginInputData = {
  details: LoginInputDetails;
  client: ClientPlatform;
  device: Scalars['String'];
};

export type LoginInputDetails = {
  kind: Scalars['String'];
  login: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<LoginResponseData>;
};

export type LoginResponseData = {
  __typename?: 'LoginResponseData';
  token: Scalars['String'];
  intercomIdentity?: Maybe<Scalars['String']>;
  details?: Maybe<LoginResponseDetails>;
};

export type LoginResponseDetails = {
  __typename?: 'LoginResponseDetails';
  id: Scalars['String'];
  workspaces?: Maybe<Array<Maybe<LoginWorkspace>>>;
  userProfile?: Maybe<UserProfile>;
};

export type LoginWorkspace = {
  __typename?: 'LoginWorkspace';
  id: Scalars['ID'];
  memberId: Scalars['String'];
  title: Scalars['String'];
  role: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  cardInfo?: Maybe<Scalars['Boolean']>;
  plan?: Maybe<PlanOverviewData>;
};

export type MacrosAllPayload = {
  __typename?: 'MacrosAllPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<MacrosData>>>;
};

export type MacrosData = {
  __typename?: 'MacrosData';
  id?: Maybe<Scalars['ShortId']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workspace_id?: Maybe<Scalars['ShortId']>;
  createdOn?: Maybe<Scalars['Datetime']>;
};

export type MacrosEdge = Edge & {
  __typename?: 'MacrosEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<MacrosNode>;
};

export type MacrosInputData = {
  title: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MacrosNode = Node & {
  __typename?: 'MacrosNode';
  id: Scalars['ShortId'];
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  workspace_id?: Maybe<Scalars['ShortId']>;
  createdOn?: Maybe<Scalars['Datetime']>;
};

export type MacrosPayload = {
  __typename?: 'MacrosPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MacrosResponseData>;
};

export type MacrosRemovePayload = {
  __typename?: 'MacrosRemovePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MacrosData>;
};

export type MacrosResponseData = {
  __typename?: 'MacrosResponseData';
  id: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type MakePrimaryCardPayload = {
  __typename?: 'MakePrimaryCardPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MakePrimaryCardResponseData>;
};

export type MakePrimaryCardResponseData = {
  __typename?: 'MakePrimaryCardResponseData';
  id: Scalars['String'];
};

export type MarkAsUnread = {
  __typename?: 'MarkAsUnread';
  id: Scalars['ShortId'];
  unreadCount: Scalars['Int'];
  cursor?: Maybe<Scalars['Cursor']>;
};

export type MarkAsUnreadConversationPayload = {
  __typename?: 'MarkAsUnreadConversationPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MarkAsUnread>;
};

export type Member = {
  __typename?: 'Member';
  id: Scalars['ShortId'];
  name: Scalars['String'];
  online: Scalars['Boolean'];
  onlineConnection?: Maybe<Scalars['Int']>;
  picture?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  unSeenMsgCount?: Maybe<Scalars['Int']>;
};

export enum MemberChatStatus {
  Sent = 'Sent',
  Delivered = 'Delivered',
  Seen = 'Seen',
  Archived = 'Archived',
}

export type MemberConnection = Connection & {
  __typename?: 'MemberConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<MemberEdge>>>;
};

export type MemberDetails = {
  __typename?: 'MemberDetails';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  lastonline?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  onlineConnection?: Maybe<Scalars['Int']>;
};

export type MemberDetailsPayload = {
  __typename?: 'MemberDetailsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MemberDetails>;
};

export type MemberEdge = Edge & {
  __typename?: 'MemberEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<MemberNode>;
};

export type MemberInfo = {
  __typename?: 'MemberInfo';
  id: Scalars['ShortId'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
};

export type MemberLoginInputData = {
  authToken: Scalars['String'];
  workspaceId: Scalars['String'];
  memberId: Scalars['String'];
};

export type MemberLoginPayload = {
  __typename?: 'MemberLoginPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<MemberLoginResponseData>;
};

export type MemberLoginResponseData = {
  __typename?: 'MemberLoginResponseData';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  cardInfo?: Maybe<Scalars['Boolean']>;
};

export type MemberNode = Node & {
  __typename?: 'MemberNode';
  id: Scalars['ShortId'];
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  profilePicture?: Maybe<Scalars['String']>;
  numbers?: Maybe<Array<Maybe<Channel>>>;
  planRate?: Maybe<PlanRate>;
  online?: Maybe<Scalars['Boolean']>;
  unSeenMsgCount?: Maybe<Scalars['Int']>;
  last_message?: Maybe<ChatMessageNode>;
  type?: Maybe<Scalars['String']>;
  onCall?: Maybe<Scalars['Boolean']>;
  onlineConnection?: Maybe<Scalars['Int']>;
};

export enum MemberRole {
  Admin = 'Admin',
  Member = 'Member',
}

export enum MemberStatus {
  Active = 'Active',
  Archived = 'Archived',
  Inactive = 'Inactive',
}

export type MemberStatusSubscription = {
  __typename?: 'MemberStatusSubscription';
  event: Scalars['String'];
  message?: Maybe<Member>;
};

export type MembersInvitesPayload = {
  __typename?: 'MembersInvitesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<MembersOrInvites>>>;
};

export type MembersOrInvites = MemberNode | InviteNode;

export type MembersPayload = {
  __typename?: 'MembersPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MemberConnection>;
};

export type Message = {
  __typename?: 'Message';
  message: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type MessageCountSubscription = {
  __typename?: 'MessageCountSubscription';
  event: Scalars['String'];
  message: CountSubscriptionData;
};

export type MessageOnlyPayload = {
  __typename?: 'MessageOnlyPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Message>;
};

export type Mutation = {
  __typename?: 'Mutation';
  setUserDND: DndPayload;
  uploadBulkContacts: UploadContactPayload;
  resyncGoogleContactIntegration: UploadContactPayload;
  deleteGoogleContactIntegration: ResourceSucceedPayload;
  configureGoogleContact: GoogleOauthRedirectPayload;
  integrateGoogleContact: UploadContactPayload;
  addPlanRestriction: PlanRestrictionDataPayload;
  autoReply: AutoReplyPayload;
  setupCallerId: CallerIdRequestPayload;
  deleteChannel: DeleteChannelPayload;
  deleteNumber: DeleteChannelPayload;
  removeContactHistoryLog: ContactHistoryRemovePayload;
  editChatStatus: ChatMessaagePayload;
  editMemberChatSeen: ResourceSucceedPayload;
  appRegister: AppRegisterPayload;
  deviceRegister: DeviceRegisterPayload;
  addPermission: PermissionDataPayload;
  callRecording: RecordCallPayload;
  callHold: HoldCallPayload;
  addContact: ContactPayload;
  editContact: EditContactPayload;
  editMemberRole: EditMemberPayload;
  editMemberStatus: EditMemberPayload;
  editNotification: EditMemberPayload;
  updateOnlineStatus: UpdateOnlineStatusPayload;
  updateTeam: UpdateTeamPayload;
  login: LoginPayload;
  register: RegisterPayload;
  signup: RegisterPayload;
  inviteMember: MessageOnlyPayload;
  logout: Scalars['Boolean'];
  changePassword: ResourceSucceedPayload;
  changeProfileNames: UpdateProfileSucceedPayload;
  toggleUserActivation: UpdateProfileSucceedPayload;
  changeUserProfilePicture: UpdateProfileSucceedPayload;
  changeWorkspacePicture: EditWorkspacePhotoPayload;
  changeDefaultLanguage: UpdateProfileSucceedPayload;
  changeDefaultTimezone: UpdateProfileSucceedPayload;
  changeDefaultWorkspace: UpdateProfileSucceedPayload;
  memberLogin: MemberLoginPayload;
  refreshToken: RefreshTokenLoginPayload;
  addWorkspace: WorkspacePayload;
  addTeam: TeamPayload;
  addChannel: ChannelPayload;
  addChannelSupport: ChannelPayload;
  editWorkspace: EditWorkspacePayload;
  changeEmail: ChangeEmailPayload;
  resendEmailVerificationLink: EmailVerificationPayload;
  acceptInvite: InviteAcceptPayload;
  acceptUserInvite: UserInviteAcceptPayload;
  verifyEmail: VerifyEmailPayload;
  removeMember: UpdateTeamPayload;
  removeTeam: UpdateTeamPayload;
  removeNote: UpdateNotePayload;
  resendInvite: InviteResendPayload;
  addNote: NotePayload;
  addNoteByContact: NoteWithClientPayload;
  editNote: NotePayload;
  addTag: TagPayload;
  addGlobalTag: TagPayload;
  addPinned: ResourceSucceedPayload;
  setChannelDnd: DndPayload;
  removeChannelDnd: ResourceSucceedPayload;
  sendMessage: ConversationPayload;
  changeConversationAgentData: ResourceSucceedPayload;
  archiveConversation: ResourceSucceedPayload;
  rejectConversation: ResourceSucceedPayload;
  changePlan: ChangePlanPayload;
  switchPlan: ChangePlanPayload;
  reActivatePlan: ChangePlanPayload;
  setupPlanRate: SetupPlanRatePayload;
  addSetupIntent: SetupIntentPayload;
  addDefaultCard: SetupIntentPayload;
  createPaymentIntent: PaymentIntentPayload;
  chargeCredit: ChargeCreditPayload;
  chargeCreditDupe: ChargeCreditPayload;
  removeCard: RemoveCardPayload;
  makePrimaryCard: MakePrimaryCardPayload;
  hideBranding: HideBrandingPayload;
  cardExist: CardExistPayload;
  cancelSubscription: CancelSubscriptionPayload;
  saveNotificationAndAutoRecharge: NotificationAndAutoRechargePayload;
  workspaceSubscription: WorkspaceSubscriptionPayload;
  toggleSubscription: ToggleSubscriptionPayload;
  renewSubscription: RenewSubscriptionPayload;
  assignNumbers: AssignNumbersPayload;
  updateGeneralSettings: ChannelSettingsPayload;
  updateCallStrategy: ChannelSettingsUpdatePayload;
  editChannelAccess: ChannelAccessPayload;
  forgotPassword: MessageOnlyPayload;
  resetPassword: MessageOnlyPayload;
  blockContact: BlockContactPayload;
  blockNumber: BlockContactPayload;
  blockContacts: ResourceSucceedPayload;
  deleteContacts: ResourceSucceedPayload;
  addNumber: AddNumberPayload;
  archiveNumber: ResourceSucceedPayload;
  processNumber: NumbersPayload;
  updateBillingInformation: ResourceSucceedPayload;
  callRating: ResourceSucceedPayload;
  removeWorkspace: WorkspaceRemovePayload;
  restoreWorkspace: WorkspaceRestorePayload;
  createAppInfo: AppInfoPayload;
  createDeviceInfo: DeviceInfoPayload;
  uploadContact: UploadContactPayload;
  conversationSeen: ResourceSucceedPayload;
  conversationUnSeen: ResourceSucceedPayload;
  channelConversationSeen: ResourceSucceedPayload;
  markAsUnreadConversation: MarkAsUnreadConversationPayload;
  createChatMessage: ChatMessaagePayload;
  cancelOutgoingCall: OutgoingCancelPayload;
  updateClientDND: ClientDndPayload;
  deleteInvitation: ResourceSucceedPayload;
  greetings: GreetingPayload;
  greetingsToggle: GreetingTogglePayload;
  uploadAudio: UploadPayload;
  workspaceOrder: ResourceSucceedPayload;
  channelOrder: ResourceSucceedPayload;
  endQueue: EndQueuePayload;
  updateNotificationSettings: DeviceRegisterPayload;
  saveIvrSettings: ResourceSucceedPayload;
  portNumber: ResourceSucceedPayload;
  unArchiveConversation: ResourceSucceedPayload;
  changePurchaseSupportTicketStatus: ResourceSucceedPayload;
  changePurchaseSupportNumberStatus: ResourceSucceedPayload;
  changePortingSupportTicketStatus: ResourceSucceedPayload;
  changePortingSupportNumberStatus: ResourceSucceedPayload;
  changeEnterpriseSupportTicketStatus: ResourceSucceedPayload;
  editTag: TagPayload;
  setChannelCallerId: ChannelSettingsPayload;
  channelContactState: ResourceSucceedPayload;
  agentDrafts: AgentDraftsPayload;
  archieveContactThread: ArchieveContactThreadPayload;
  pinnedAgentConversationData: ResourceSucceedPayload;
  sendBulkMessage: BulkMessagePayload;
  addMacros: MacrosPayload;
  removeMacros: MacrosRemovePayload;
  archiveWorkspace: ArchiveWorkspacesPayload;
  updateMacros: UpdateMacrosPayload;
  removeTag: TagPayload;
  removeContactTag: ClientTagPayload;
};

export type MutationSetUserDndArgs = {
  data?: Maybe<UserDndInput>;
};

export type MutationUploadBulkContactsArgs = {
  data: BulkContactsInput;
};

export type MutationResyncGoogleContactIntegrationArgs = {
  id: Scalars['ShortId'];
};

export type MutationDeleteGoogleContactIntegrationArgs = {
  id: Scalars['ShortId'];
};

export type MutationIntegrateGoogleContactArgs = {
  data: GoogleOauthResponse;
};

export type MutationAddPlanRestrictionArgs = {
  data: PlanRestrictionInputData;
};

export type MutationAutoReplyArgs = {
  data: AutoReplyInputData;
};

export type MutationSetupCallerIdArgs = {
  data: CallerIdNumberInput;
};

export type MutationDeleteChannelArgs = {
  id: Scalars['ShortId'];
};

export type MutationDeleteNumberArgs = {
  id: Scalars['ShortId'];
};

export type MutationRemoveContactHistoryLogArgs = {
  id: Scalars['ShortId'];
};

export type MutationEditChatStatusArgs = {
  data: EditChatStatus;
};

export type MutationEditMemberChatSeenArgs = {
  senderId: Scalars['ShortId'];
};

export type MutationAppRegisterArgs = {
  data: AppRegisterInputData;
};

export type MutationDeviceRegisterArgs = {
  data: DeviceRegisterInputData;
};

export type MutationAddPermissionArgs = {
  data: PermissionInputData;
};

export type MutationCallRecordingArgs = {
  data: RecordCallInput;
};

export type MutationCallHoldArgs = {
  data: HoldCallInput;
};

export type MutationAddContactArgs = {
  data: ContactInputData;
};

export type MutationEditContactArgs = {
  id: Scalars['ShortId'];
  data: EditContactInputData;
};

export type MutationEditMemberRoleArgs = {
  id: Scalars['ShortId'];
  data: EditMemberRoleInputData;
};

export type MutationEditMemberStatusArgs = {
  id: Scalars['ShortId'];
  data: EditMemberStatusInputData;
};

export type MutationEditNotificationArgs = {
  id: Scalars['ShortId'];
  data: EditNotificationInputData;
};

export type MutationUpdateOnlineStatusArgs = {
  data: EditOnlineStatusInputData;
};

export type MutationUpdateTeamArgs = {
  id: Scalars['ShortId'];
  data: UpdateTeamInputData;
};

export type MutationLoginArgs = {
  data: LoginInputData;
};

export type MutationRegisterArgs = {
  data: RegisterInputData;
};

export type MutationSignupArgs = {
  data: RegisterInputData;
};

export type MutationInviteMemberArgs = {
  data: InviteMemberInputData;
};

export type MutationChangePasswordArgs = {
  data: ChangePasswordInputData;
};

export type MutationChangeProfileNamesArgs = {
  data: ChangeProfileNames;
};

export type MutationToggleUserActivationArgs = {
  data: UserActivationToggle;
};

export type MutationChangeProfilePictureArgs = {
  photoUpload: Scalars['Upload'];
};

export type MutationChangeWorkspacePhotoArgs = {
  photoUpload: Scalars['Upload'];
};

export type MutationChangeDefaultLanguageArgs = {
  data: ChangeDefaultLanguage;
};

export type MutationChangeDefaultTimezoneArgs = {
  data: ChangeDefaultTimezone;
};

export type MutationChangeDefaultWorkspaceArgs = {
  data: ChangeDefaultWorkspace;
};

export type MutationMemberLoginArgs = {
  data: MemberLoginInputData;
};

export type MutationAddWorkspaceArgs = {
  data: WorkspaceInputData;
};

export type MutationAddTeamArgs = {
  data: TeamInputData;
};

export type MutationAddChannelArgs = {
  data: ChannelInputData;
};

export type MutationAddChannelSupportArgs = {
  data: ChannelInputDataSupport;
};

export type MutationEditWorkspaceArgs = {
  data: EditWorkspaceInputData;
};

export type MutationChangeEmailArgs = {
  data: ChangeEmailInputData;
};

export type MutationResendEmailVerificationLinkArgs = {
  data: EmailVerificationInputData;
};

export type MutationAcceptInviteArgs = {
  data: InviteAcceptInput;
};

export type MutationAcceptUserInviteArgs = {
  data: UserInviteAcceptInput;
};

export type MutationVerifyEmailArgs = {
  data: VerifyEmailInput;
};

export type MutationRemoveMemberArgs = {
  id: Scalars['ShortId'];
  data: RemoveTeammemberInputData;
};

export type MutationRemoveTeamArgs = {
  id: Scalars['ShortId'];
  data: RemoveTeamInputData;
};

export type MutationRemoveNoteArgs = {
  id: Scalars['ShortId'];
};

export type MutationResendInviteArgs = {
  data: InviteCode;
};

export type MutationAddNoteArgs = {
  clientId: Scalars['ShortId'];
  data: NoteInputData;
};

export type MutationAddNoteByContactArgs = {
  contact: Scalars['String'];
  data: NoteInputData;
};

export type MutationEditNoteArgs = {
  id: Scalars['ShortId'];
  data: EditNoteInputData;
};

export type MutationAddTagArgs = {
  data: TagInputData;
};

export type MutationAddGlobalTagArgs = {
  data: TagInputData;
};

export type MutationAddPinnedArgs = {
  data: PinnedInputData;
};

export type MutationSetChannelDndArgs = {
  id: Scalars['ShortId'];
  minutes?: Maybe<Scalars['Int']>;
};

export type MutationRemoveChannelDndArgs = {
  id: Scalars['ShortId'];
};

export type MutationSendMessageArgs = {
  data: ConversationInput;
};

export type MutationChangeConversationAgentDataArgs = {
  id: Scalars['ShortId'];
  data: ConversationAgentData;
};

export type MutationArchiveConversationArgs = {
  id: Scalars['ShortId'];
};

export type MutationRejectConversationArgs = {
  id: Scalars['ShortId'];
};

export type MutationChangePlanArgs = {
  data: ChangePlanInputData;
};

export type MutationReActivatePlanArgs = {
  id: Scalars['ShortId'];
};

export type MutationSetupPlanRateArgs = {
  data: SetupPlanRateInputData;
};

export type MutationAddDefaultCardArgs = {
  data: DefaultCardInputData;
};

export type MutationCreatePaymentIntentArgs = {
  data: PaymentIntentInputData;
};

export type MutationChargeCreditArgs = {
  data: ChargeCreditInputData;
};

export type MutationChargeCreditDupeArgs = {
  data: ChargeCreditInputData;
};

export type MutationRemoveCardArgs = {
  data: RemoveCardInputData;
};

export type MutationMakePrimaryCardArgs = {
  data: MakePrimaryCardInputData;
};

export type MutationHideBrandingArgs = {
  data: HideBrandingInputData;
};

export type MutationCardExistArgs = {
  data: CardExistInputData;
};

export type MutationSaveNotificationAndAutoRechargeArgs = {
  data: NotificationAndAutoRechargeInputData;
};

export type MutationWorkspaceSubscriptionArgs = {
  data: WorkspaceSubscriptionInputData;
};

export type MutationToggleSubscriptionArgs = {
  data: SubscriptionActionToggle;
};

export type MutationAssignNumbersArgs = {
  data: NumbersInputData;
};

export type MutationUpdateGeneralSettingsArgs = {
  channel: Scalars['ShortId'];
  data: GeneralChannelSettingsInput;
};

export type MutationUpdateCallStrategyArgs = {
  channel: Scalars['ShortId'];
  data: CallStrategySettingsInput;
};

export type MutationEditChannelAccessArgs = {
  channel: Scalars['ShortId'];
  data: EditChannelShareInput;
};

export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput;
};

export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};

export type MutationBlockContactArgs = {
  id: Scalars['ShortId'];
  data: BlockContactInputData;
};

export type MutationBlockNumberArgs = {
  number: Scalars['String'];
  data: BlockNumberInputData;
};

export type MutationBlockContactsArgs = {
  data: BlockContactsInputData;
};

export type MutationDeleteContactsArgs = {
  data: DeleteContactsInputData;
};

export type MutationAddNumberArgs = {
  data: Number;
};

export type MutationArchiveNumberArgs = {
  data: RemoveNumberInputData;
};

export type MutationProcessNumberArgs = {
  data: ProcessNumbersInputData;
};

export type MutationUpdateBillingInformationArgs = {
  data: BillingInformationInputData;
};

export type MutationCallRatingArgs = {
  id: Scalars['ShortId'];
  data: CallRatingInputData;
};

export type MutationRemoveWorkspaceArgs = {
  id: Scalars['ShortId'];
};

export type MutationRestoreWorkspaceArgs = {
  id: Scalars['ShortId'];
};

export type MutationCreateAppInfoArgs = {
  data: AppInfo;
};

export type MutationCreateDeviceInfoArgs = {
  data: DeviceInfo;
};

export type MutationUploadContactArgs = {
  data: ContactCsvInputData;
};

export type MutationConversationSeenArgs = {
  contact: Scalars['String'];
  channel: Scalars['ShortId'];
};

export type MutationConversationUnSeenArgs = {
  contact: Scalars['String'];
  channel: Scalars['ShortId'];
};

export type MutationChannelConversationSeenArgs = {
  channel: Scalars['ShortId'];
};

export type MutationMarkAsUnreadConversationArgs = {
  conversation: Scalars['ShortId'];
};

export type MutationCreateChatMessageArgs = {
  data: ChatMessageInput;
};

export type MutationCancelOutgoingCallArgs = {
  data: OutgoingCancelInput;
};

export type MutationUpdateClientDndArgs = {
  data: ClientDndInput;
};

export type MutationDeleteInvitationArgs = {
  link: Scalars['String'];
};

export type MutationGreetingsArgs = {
  channel: Scalars['ShortId'];
  data: GreetingInputData;
};

export type MutationGreetingsToggleArgs = {
  channel: Scalars['ShortId'];
  data: GreetingToggleInputData;
};

export type MutationUploadAudioArgs = {
  file: Scalars['Upload'];
};

export type MutationWorkspaceOrderArgs = {
  data: WorkspaceOrderInputData;
};

export type MutationChannelOrderArgs = {
  data: ChannelOrderInputData;
};

export type MutationEndQueueArgs = {
  data: EndQueueInput;
};

export type MutationUpdateNotificationSettingsArgs = {
  data: UpdateNotificationsInputData;
};

export type MutationSaveIvrSettingsArgs = {
  channel: Scalars['ShortId'];
  data?: Maybe<IvrSettingsInputData>;
};

export type MutationPortNumberArgs = {
  data: PortNumberInputData;
};

export type MutationUnArchiveConversationArgs = {
  id: Scalars['ShortId'];
};

export type MutationChangePurchaseSupportTicketStatusArgs = {
  data: PurchaseSupportTicketStatusInputData;
};

export type MutationChangePurchaseSupportNumberStatusArgs = {
  data: PurchaseSupportNumberStatusInputData;
};

export type MutationChangePortingSupportTicketStatusArgs = {
  data: PortingSupportTicketStatusInputData;
};

export type MutationChangePortingSupportNumberStatusArgs = {
  data: PortingSupportNumberStatusInputData;
};

export type MutationChangeEnterpriseSupportTicketStatusArgs = {
  data: EnterpriseSupportInputData;
};

export type MutationEditTagArgs = {
  id: Scalars['ShortId'];
  data: EditTagInputData;
};

export type MutationSetChannelCallerIdArgs = {
  data: CallerIdNumberInput;
};

export type MutationChannelContactStateArgs = {
  channel: Scalars['ShortId'];
  data: ChannelContactStateInputData;
};

export type MutationAgentDraftsArgs = {
  data: AgentDraftsInputData;
};

export type MutationArchieveContactThreadArgs = {
  data: ArchiveContactThreadInputData;
};

export type MutationPinnedAgentConversationDataArgs = {
  id: Scalars['ShortId'];
  data: PinnedConversationAgentData;
};

export type MutationSendBulkMessageArgs = {
  data: BulkMessageInput;
};

export type MutationAddMacrosArgs = {
  data: MacrosInputData;
};

export type MutationRemoveMacrosArgs = {
  id: Scalars['ShortId'];
};

export type MutationArchiveWorkspaceArgs = {
  data: ArchiveWorkspacesInputData;
};

export type MutationUpdateMacrosArgs = {
  id: Scalars['ShortId'];
  data: UpdateMacosInputData;
};

export type MutationRemoveTagArgs = {
  id: Scalars['ShortId'];
};

export type MutationRemoveContactTagArgs = {
  id: Scalars['ShortId'];
  data: RemoveContactTagInputData;
};

export type NewLeadsCountSubscription = {
  __typename?: 'NewLeadsCountSubscription';
  event: Scalars['String'];
  message: SubscriptionCountPayload;
};

export type Node = {
  id: Scalars['ShortId'];
  createdOn?: Maybe<Scalars['Datetime']>;
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  title: Scalars['String'];
  createdAt: Scalars['String'];
  modifiedAt?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type NoteInputData = {
  title: Scalars['String'];
  channelId: Scalars['ShortId'];
};

export type NotePayload = {
  __typename?: 'NotePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<NoteResponseData>;
};

export type NoteResponseData = {
  __typename?: 'NoteResponseData';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type NoteWithClientData = {
  __typename?: 'NoteWithClientData';
  id: Scalars['String'];
  title: Scalars['String'];
  client: Client;
};

export type NoteWithClientPayload = {
  __typename?: 'NoteWithClientPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<NoteWithClientData>;
};

export type NotesPayload = {
  __typename?: 'NotesPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Note>>>;
};

export type NotificationAndAutoRecharge = {
  __typename?: 'NotificationAndAutoRecharge';
  id?: Maybe<Scalars['ShortId']>;
  lowCreditNotification?: Maybe<Scalars['Boolean']>;
  lowCreditBalanceNotify?: Maybe<Scalars['Float']>;
  creditAutoRecharge?: Maybe<Scalars['Boolean']>;
  recharge?: Maybe<Scalars['Float']>;
  lowCreditBalanceRecharge?: Maybe<Scalars['Float']>;
  zeroCreditNotification?: Maybe<Scalars['Boolean']>;
};

export type NotificationAndAutoRechargeInputData = {
  lowCreditNotification?: Maybe<Scalars['Boolean']>;
  lowCreditBalanceNotify?: Maybe<Scalars['Float']>;
  creditAutoRecharge?: Maybe<Scalars['Boolean']>;
  recharge?: Maybe<Scalars['Float']>;
  lowCreditBalanceRecharge?: Maybe<Scalars['Float']>;
  zeroCreditNotification?: Maybe<Scalars['Boolean']>;
};

export type NotificationAndAutoRechargePayload = {
  __typename?: 'NotificationAndAutoRechargePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<NotificationAndAutoRecharge>;
};

export type Number = {
  name: Scalars['String'];
  number: Scalars['String'];
  country: Scalars['ShortId'];
  countryIso: Scalars['String'];
  addressRequirement: Scalars['String'];
  sms: Scalars['Boolean'];
  mms: Scalars['Boolean'];
  call: Scalars['Boolean'];
  type: NumberType;
};

export type NumberAbilities = {
  __typename?: 'NumberAbilities';
  call?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type NumberAvailablityPathParams = {
  countryIso?: Maybe<Scalars['String']>;
  type?: Maybe<NumberType>;
  types?: Maybe<Array<Maybe<NumberType>>>;
};

export type NumberAvailablitySearchParams = {
  limit?: Maybe<Scalars['Int']>;
  areaCode?: Maybe<Scalars['Int']>;
  contains?: Maybe<Scalars['String']>;
  smsEnabled?: Maybe<Scalars['Boolean']>;
  mmsEnabled?: Maybe<Scalars['Boolean']>;
  voiceEnabled?: Maybe<Scalars['Boolean']>;
  excludeAllAddressRequired?: Maybe<Scalars['Boolean']>;
  excludeLocalAddressRequired?: Maybe<Scalars['Boolean']>;
  excludeForeignAddressRequired?: Maybe<Scalars['Boolean']>;
  beta?: Maybe<Scalars['Boolean']>;
  nearNumber?: Maybe<Scalars['String']>;
  nearLatLong?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Int']>;
  inPostalCode?: Maybe<Scalars['String']>;
  inRegion?: Maybe<Scalars['String']>;
  inRateCenter?: Maybe<Scalars['String']>;
  inLata?: Maybe<Scalars['String']>;
  inLocality?: Maybe<Scalars['String']>;
  faxEnabled?: Maybe<Scalars['Int']>;
};

export type NumberCapability = {
  __typename?: 'NumberCapability';
  voice?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type NumberCheckoutPrice = {
  __typename?: 'NumberCheckoutPrice';
  setUpFee: Scalars['Float'];
  monthlyFee: Scalars['Float'];
  basePrice?: Maybe<Scalars['Float']>;
};

export type NumberPrice = {
  __typename?: 'NumberPrice';
  unit: Scalars['String'];
  prices?: Maybe<Array<Maybe<NumberTypePrices>>>;
  countryCode: Scalars['String'];
};

export type NumberPricePayload = {
  __typename?: 'NumberPricePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<NumberPrice>;
};

export type NumberPurchasePayload = {
  __typename?: 'NumberPurchasePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<CheckoutNumber>>>;
};

export type NumberSearchParms = {
  areaCode?: Maybe<Scalars['Int']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryIso?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type NumberSearchPayload = {
  __typename?: 'NumberSearchPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<PhoneNumber>>>;
};

export enum NumberType {
  Local = 'Local',
  Mobile = 'Mobile',
  TollFree = 'TollFree',
  National = 'National',
}

export type NumberTypePrices = {
  __typename?: 'NumberTypePrices';
  numberType: Scalars['String'];
  basePrice: Scalars['String'];
  currentPrice: Scalars['String'];
};

export type NumbersInputData = {
  numbers?: Maybe<Array<Scalars['ShortId']>>;
};

export type NumbersNode = {
  __typename?: 'NumbersNode';
  id: Scalars['ShortId'];
  name: Scalars['String'];
  number: Scalars['String'];
  countryLogo?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  agents?: Maybe<Array<Maybe<Agent>>>;
  callStrategy: CallStrategy;
  numberCheckoutPrice?: Maybe<NumberCheckoutPrice>;
  call?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
};

export type NumbersPayload = {
  __typename?: 'NumbersPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<NumbersNode>>>;
};

export type OffsetConnection = {
  pageInfo: OffsetPageInfo;
};

export type OffsetConnectionInput = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  search?: Maybe<SearchInput>;
  filter?: Maybe<Scalars['FilterParams']>;
  filters?: Maybe<Array<Maybe<Scalars['FilterParams']>>>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Ordering>;
};

export type OffsetContactAllPayload = {
  __typename?: 'OffsetContactAllPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<OffsetContactConnection>;
};

export type OffsetContactConnection = OffsetConnection & {
  __typename?: 'OffsetContactConnection';
  pageInfo: OffsetPageInfo;
  edges?: Maybe<Array<Maybe<OffsetContactEdge>>>;
};

export type OffsetContactEdge = Edge & {
  __typename?: 'OffsetContactEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<ContactNode>;
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type OpenContactCountSubscription = {
  __typename?: 'OpenContactCountSubscription';
  event: Scalars['String'];
  message: SubscriptionCountPayload;
};

export enum Ordering {
  Asc = 'asc',
  Desc = 'desc',
}

export type OutgoingCancelInput = {
  conversationId?: Maybe<Scalars['ShortId']>;
};

export type OutgoingCancelPayload = {
  __typename?: 'OutgoingCancelPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<CancelStatus>;
};

export type OwnerData = {
  __typename?: 'OwnerData';
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  startCursor?: Maybe<Scalars['Cursor']>;
  endCursor?: Maybe<Scalars['Cursor']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentIntentInputData = {
  amount: Scalars['Int'];
};

export type PaymentIntentPayload = {
  __typename?: 'PaymentIntentPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<IntentResponseData>;
};

export type PendingNumber = {
  __typename?: 'PendingNumber';
  name: Scalars['String'];
  number: Scalars['String'];
  country: Scalars['ShortId'];
  addressRequirement?: Maybe<Scalars['String']>;
  regulationSid?: Maybe<Scalars['String']>;
  setUpFee: Scalars['Float'];
  monthlyFee: Scalars['Float'];
  purchaseStatus: Scalars['String'];
  purchase_status: PendingNumbersEnum;
  call?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  mms?: Maybe<Scalars['Boolean']>;
  type?: Scalars['String'];
};

export enum PendingNumbersEnum {
  PurchasePending = 'PURCHASE_PENDING',
  PaymentPending = 'PAYMENT_PENDING',
  DocumentPending = 'DOCUMENT_PENDING',
  InProgress = 'IN_PROGRESS',
  Failed = 'FAILED',
  Completed = 'COMPLETED',
}

export type PendingNumbersPayload = {
  __typename?: 'PendingNumbersPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<PendingNumber>>>;
};

export type PermissionDataPayload = {
  __typename?: 'PermissionDataPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Scalars['JSON']>;
};

export type PermissionInputData = {
  data: Scalars['JSON'];
};

export type PersonalizedInfo = {
  __typename?: 'PersonalizedInfo';
  pinned?: Maybe<Scalars['Boolean']>;
  seen?: Maybe<Scalars['Boolean']>;
  favourite?: Maybe<Scalars['Boolean']>;
  dndMissed?: Maybe<Scalars['Boolean']>;
  reject?: Maybe<Scalars['Boolean']>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  type: Scalars['String'];
  dialingNumber: Scalars['String'];
  capabilities: NumberCapability;
  addressRequirements: AddressRequirementType;
  region?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  countryIso: Scalars['String'];
  price?: Maybe<SetUpAndMontlyFee>;
};

export type PinnedConversationAgentData = {
  pinned?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Scalars['ShortId']>;
  contact?: Maybe<Scalars['String']>;
};

export type PinnedInputData = {
  pinned: Scalars['Boolean'];
  contact: Scalars['String'];
  channel: Scalars['ShortId'];
};

export type PinnedPayload = {
  __typename?: 'PinnedPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ResourceSucceedPayload>;
};

export type PlanDetailData = {
  __typename?: 'PlanDetailData';
  title?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
};

export type PlanOverviewData = {
  __typename?: 'PlanOverviewData';
  remainingDays?: Maybe<Scalars['Int']>;
  subscriptionActive?: Maybe<Scalars['String']>;
  trialPeriod?: Maybe<Scalars['String']>;
  cardInfo?: Maybe<Scalars['Boolean']>;
  currentCredit?: Maybe<Scalars['Float']>;
  startedAt?: Maybe<Scalars['Datetime']>;
  endedAt?: Maybe<Scalars['Datetime']>;
  planDetail?: Maybe<PlanDetailData>;
};

export type PlanOverviewPayload = {
  __typename?: 'PlanOverviewPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<PlanOverviewSettings>;
};

export type PlanOverviewSettings = {
  __typename?: 'PlanOverviewSettings';
  customerId: Scalars['String'];
  hideKrispcallBranding?: Maybe<Scalars['Boolean']>;
  dueAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  currentPeriodEnd?: Maybe<Scalars['String']>;
  subscriptionActive?: Maybe<Scalars['String']>;
  remainingDays?: Maybe<Scalars['Int']>;
  trialPeriod?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  freeVoiceMail?: Maybe<Scalars['Float']>;
  plan?: Maybe<PlanResponseData>;
  credit?: Maybe<Credit>;
  card?: Maybe<CardResponseData>;
};

export type PlanPayload = {
  __typename?: 'PlanPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<PlanResponseData>;
};

export type PlanRate = {
  __typename?: 'PlanRate';
  planRate: Scalars['Float'];
};

export type PlanResponseData = {
  __typename?: 'PlanResponseData';
  id: Scalars['ShortId'];
  title: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
  order?: Maybe<Scalars['Int']>;
  details: Scalars['String'];
};

export type PlanRestrictionDataPayload = {
  __typename?: 'PlanRestrictionDataPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Scalars['JSON']>;
};

export type PlanRestrictionInputData = {
  data: Scalars['JSON'];
};

export type PlanSucceedPayloadData = {
  __typename?: 'PlanSucceedPayloadData';
  success: Scalars['Boolean'];
};

export enum Platform {
  Iphone = 'IPHONE',
  Android = 'ANDROID',
  Web = 'WEB',
}

export type PortNumberData = {
  __typename?: 'PortNumberData';
  id: Scalars['ShortId'];
  number?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  status?: Maybe<PortNumbersEnum>;
};

export type PortNumberDataPayload = {
  __typename?: 'PortNumberDataPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<PortNumberData>>>;
};

export type PortNumberInputData = {
  number?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
};

export enum PortNumbersEnum {
  PortingRequested = 'portingRequested',
  PortingProgress = 'portingProgress',
  PortingCompleted = 'portingCompleted',
  PortingFailed = 'portingFailed',
}

export type PortingSupportConnection = Connection & {
  __typename?: 'PortingSupportConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PortingSupportEdge>>>;
};

export type PortingSupportEdge = Edge & {
  __typename?: 'PortingSupportEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<PortingSupportNode>;
};

export type PortingSupportNode = {
  __typename?: 'PortingSupportNode';
  ticketNumber: Scalars['String'];
  number: Scalars['String'];
  workspaceId?: Maybe<Scalars['ShortId']>;
  workspaceName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  trial?: Maybe<Scalars['String']>;
  ticketStatus?: Maybe<TicketStatusType>;
  portingNumberStatus?: Maybe<SupportPortNumbersEnum>;
  issueDate: Scalars['Datetime'];
  closeDate?: Maybe<Scalars['Datetime']>;
};

export type PortingSupportNumberStatusInputData = {
  ticketNumber: Scalars['String'];
  portingNumberStatus: SupportPortNumbersEnum;
};

export type PortingSupportPayload = {
  __typename?: 'PortingSupportPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<PortingSupportConnection>;
};

export type PortingSupportTicketStatusInputData = {
  ticketNumber: Scalars['String'];
  ticketStatus: TicketStatusType;
};

export type ProcessNumbersInputData = {
  numbers: Array<Scalars['String']>;
};

export type ProfileDetailPayload = {
  __typename?: 'ProfileDetailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<UserProfile>;
};

export type PurchaseSupportConnection = Connection & {
  __typename?: 'PurchaseSupportConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<PurchaseSupportEdge>>>;
};

export type PurchaseSupportEdge = Edge & {
  __typename?: 'PurchaseSupportEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<PurchaseSupportNode>;
};

export type PurchaseSupportNode = {
  __typename?: 'PurchaseSupportNode';
  ticketNumber: Scalars['String'];
  number: Scalars['String'];
  workspaceId?: Maybe<Scalars['ShortId']>;
  workspaceName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  plan: Scalars['String'];
  trial?: Maybe<Scalars['String']>;
  ticketStatus?: Maybe<TicketStatusType>;
  purchaseNumberStatus?: Maybe<PendingNumbersEnum>;
  issueDate: Scalars['Datetime'];
  closeDate?: Maybe<Scalars['Datetime']>;
};

export type PurchaseSupportNumberStatusInputData = {
  ticketNumber: Scalars['String'];
  purchaseNumberStatus: PendingNumbersEnum;
};

export type PurchaseSupportPayload = {
  __typename?: 'PurchaseSupportPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<PurchaseSupportConnection>;
};

export type PurchaseSupportTicketStatusInputData = {
  ticketNumber: Scalars['String'];
  ticketStatus: TicketStatusType;
};

export type Query = {
  __typename?: 'Query';
  googleContactIntegrationList: GoogleContactIntegrationListPayload;
  callRatingsSupportData: CallRatingSupportPayload;
  callerIdSettingsData: CallerIdDataPayload;
  enterpriseSupportData: EnterpriseSupportPayload;
  portingSupportData: PortingSupportPayload;
  purchaseSupportData: PurchaseSupportPayload;
  planRestrictionData: PlanRestrictionDataPayload;
  autoReplyData: AutoReplyDataPayload;
  teamMembersList: TeamMembersPayload;
  memberDetail: MemberDetailsPayload;
  getContactHistoryLogs: ContactHistoryListPayload;
  permissions: PermissionDataPayload;
  profile: ProfileDetailPayload;
  workspaces: WorkspaceListPayload;
  workspace: WorkspaceDetailPayload;
  clientNotes: NotesPayload;
  tags: TagsPayload;
  contact: ContactDetailPayload;
  countryFromCode: CountryPayLoad;
  countryFromUid: CountryPayLoad;
  countryFromDialingCode: CountryPayLoad;
  allCountries: AllCountriesPayload;
  team: TeamDetailPayLoad;
  teams: TeamsDetailPayLoad;
  inviteMemberEmailExists: CheckEmailPayload;
  allWorkspaceMembers: MembersPayload;
  newContacts: AllNewContactPayload;
  blockedContacts: AllBlockedContactPayload;
  contacts: ContactAllPayload;
  inviteDetail: InviteDetailPayload;
  searchAvailableNumbers: NumberSearchPayload;
  availableNumberCountries: AvailableCountriesPayload;
  numberPrice: NumberPricePayload;
  getVoiceToken: VoiceTokenPayLoad;
  getWorkspaceByName: WorkspaceListPayload;
  getAllWorkspaces: WorkspaceListPaginated;
  workspaceMemberAndInvite: MembersInvitesPayload;
  allInvites: InvitesPayload;
  recentConversation: ConversationHistoryPayload;
  recentConversationHistory: ConversationHistoryPayload;
  conversation: ConversationHistoryPayload;
  pinnedConversation: ConversationListPayload;
  favouriteConversation: ConversationListPayload;
  unseenCount: CountPayload;
  newCount: CountPayload;
  plans: AllPlansPayload;
  planOverview: PlanOverviewPayload;
  getWorkspaceCredit: GetWorkspaceCreditPayload;
  cards: CardsPayload;
  plan: PlanPayload;
  notificationAndAutoRecharge: NotificationAndAutoRechargePayload;
  billingInformation: BillingInformationPayload;
  receipts: ReceiptsPayload;
  invoice: InvoiceDetailResponseData;
  billingSettings: BillingSettings;
  requestedPlanWorkspace: RequestedPlanWorkspacePayload;
  numbers: NumbersPayload;
  pendingNumbers: PendingNumbersPayload;
  numberSettings: ChannelSettingsPayload;
  channelNameExists: CheckNamePayload;
  coldTransfer: TransferPayload;
  deviceInfo: DeviceInfoPayload;
  stripePublicKey: StripePublicKeyPayload;
  channelInfo: ChannelInfo;
  chatHistory: ChatHistoryPayload;
  regulation: RegulationPayload;
  checkDuplicateLogin: ResourceSucceedPayload;
  channels: ChannelsPayload;
  greetings: GreetingListPayload;
  clientDetail: EditContactPayload;
  devices: DevicesPayload;
  ivrSettings?: Maybe<IvrSettingsPayload>;
  notificationSettings: DevicesPayload;
  appRegisterInfo: AppRegisterPayload;
  portNumbers: PortNumberDataPayload;
  clientRecordings?: Maybe<ClientRecordingsPayload>;
  lastContactedTime: LastContactedDataPayload;
  contactPinnedConversation: ConversationListPayload;
  Offsetcontacts: OffsetContactAllPayload;
  verifiedCallerIds: CallerIdPayload;
  getContactsLogs: ContactsLogsPayload;
  contactPinnedConversationOpenClosed: ConversationHistoryPayload;
  openContactCount: CountPayload;
  conversationHistory: ConversationHistoryPayload;
  workspaceDrafts: WorkspaceDraftsPayload;
  allPinnedConversation: ConversationListPayload;
  macros: MacrosAllPayload;
  allTags: TagsPayload;
  allTimeZones?: Maybe<AllTimeZonesPayload>;
  allUsers: UserListPaginated;
  logout: ResourceSucceedPayload;
};

export type QueryCallRatingsSupportDataArgs = {
  params?: Maybe<ConnectionInput>;
};

export type QueryCallerIdSettingsDataArgs = {
  channel: Scalars['ShortId'];
};

export type QueryEnterpriseSupportDataArgs = {
  params?: Maybe<ConnectionInput>;
};

export type QueryPortingSupportDataArgs = {
  params?: Maybe<ConnectionInput>;
};

export type QueryPurchaseSupportDataArgs = {
  params?: Maybe<ConnectionInput>;
};

export type QueryAutoReplyDataArgs = {
  id: Scalars['ShortId'];
};

export type QueryTeamMembersListArgs = {
  params?: Maybe<ConnectionInput>;
  id: Scalars['ShortId'];
};

export type QueryMemberDetailArgs = {
  id: Scalars['ShortId'];
};

export type QueryClientNotesArgs = {
  contact: Scalars['String'];
  channel: Scalars['ShortId'];
};

export type QueryContactArgs = {
  id: Scalars['ShortId'];
};

export type QueryCountryFromCodeArgs = {
  alphaTwoCode: Scalars['String'];
};

export type QueryCountryFromUidArgs = {
  id: Scalars['ShortId'];
};

export type QueryCountryFromDialingCodeArgs = {
  dialingCode: Scalars['String'];
};

export type QueryTeamArgs = {
  id: Scalars['String'];
};

export type QueryInviteMemberEmailExistsArgs = {
  email: Scalars['String'];
};

export type QueryAllWorkspaceMembersArgs = {
  pageParams: ConnectionInput;
};

export type QueryNewContactsArgs = {
  tags?: Maybe<Array<Maybe<Scalars['ShortId']>>>;
};

export type QueryContactsArgs = {
  params?: Maybe<ConnectionInput>;
  tags?: Maybe<Array<Maybe<Scalars['ShortId']>>>;
};

export type QueryInviteDetailArgs = {
  invitationCode: Scalars['String'];
};

export type QuerySearchAvailableNumbersArgs = {
  pathParams?: Maybe<NumberAvailablityPathParams>;
  searchParams?: Maybe<NumberAvailablitySearchParams>;
};

export type QueryNumberPriceArgs = {
  countryIso: Scalars['String'];
};

export type QueryGetVoiceTokenArgs = {
  platform?: Maybe<Platform>;
};

export type QueryGetWorkspaceByNameArgs = {
  workspace: Scalars['String'];
};

export type QueryGetAllWorkspacesArgs = {
  params?: Maybe<ConnectionInput>;
};

export type QueryAllInvitesArgs = {
  pageParams?: Maybe<ConnectionInput>;
};

export type QueryRecentConversationArgs = {
  channel: Scalars['ShortId'];
  params: ConnectionInput;
};

export type QueryRecentConversationHistoryArgs = {
  channel: Scalars['ShortId'];
  params: ConnectionInput;
};

export type QueryConversationArgs = {
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
  params?: Maybe<ConnectionInput>;
};

export type QueryPinnedConversationArgs = {
  channel: Scalars['ShortId'];
};

export type QueryFavouriteConversationArgs = {
  channel: Scalars['ShortId'];
  contact: Scalars['ShortId'];
};

export type QueryUnseenCountArgs = {
  channel: Scalars['ShortId'];
};

export type QueryNewCountArgs = {
  channel: Scalars['ShortId'];
};

export type QueryPlansArgs = {
  interval?: Maybe<Scalars['String']>;
};

export type QueryPlanArgs = {
  id?: Maybe<Scalars['String']>;
};

export type QueryInvoiceArgs = {
  invoiceNumber: Scalars['String'];
};

export type QueryPendingNumbersArgs = {
  purchaseStatus?: Maybe<PendingNumbersEnum>;
};

export type QueryNumberSettingsArgs = {
  channel: Scalars['ShortId'];
};

export type QueryChannelNameExistsArgs = {
  name: Scalars['String'];
};

export type QueryColdTransferArgs = {
  data?: Maybe<TransferInput>;
};

export type QueryChannelInfoArgs = {
  channel: Scalars['ShortId'];
};

export type QueryChatHistoryArgs = {
  member: Scalars['ShortId'];
  params?: Maybe<ConnectionInput>;
};

export type QueryRegulationArgs = {
  sid: Scalars['String'];
};

export type QueryCheckDuplicateLoginArgs = {
  data: LoginInputData;
};

export type QueryGreetingsArgs = {
  channel: Scalars['ShortId'];
  greetingType?: Maybe<GreetingType>;
  recordingType?: Maybe<RecordingType>;
};

export type QueryClientDetailArgs = {
  number: Scalars['String'];
};

export type QueryDevicesArgs = {
  id: Scalars['ShortId'];
};

export type QueryIvrSettingsArgs = {
  channel: Scalars['ShortId'];
};

export type QueryClientRecordingsArgs = {
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
  params?: Maybe<ConnectionInput>;
};

export type QueryLastContactedTimeArgs = {
  contact: Scalars['String'];
};

export type QueryContactPinnedConversationArgs = {
  channel: Scalars['ShortId'];
  params?: Maybe<ConnectionInput>;
};

export type QueryOffsetcontactsArgs = {
  params?: Maybe<OffsetConnectionInput>;
  tags?: Maybe<Array<Maybe<Scalars['ShortId']>>>;
};

export type QueryGetContactsLogsArgs = {
  params?: Maybe<ConnectionInput>;
};

export type QueryContactPinnedConversationOpenClosedArgs = {
  channel: Scalars['ShortId'];
  params?: Maybe<ConnectionInput>;
};

export type QueryOpenContactCountArgs = {
  channel: Scalars['ShortId'];
};

export type QueryConversationHistoryArgs = {
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
  conversationStatus: Scalars['String'];
  transcriptionStatus: Scalars['String'];
  params?: Maybe<ConnectionInput>;
};

export type QueryWorkspaceDraftsArgs = {
  workspace?: Maybe<Scalars['ShortId']>;
};

export type QueryAllPinnedConversationArgs = {
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
};

export type QueryAllUsersArgs = {
  params?: Maybe<ConnectionInput>;
};

export type ReactivatePlanPayload = {
  __typename?: 'ReactivatePlanPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ChangePlanResponseData>;
};

export type Receipt = {
  __typename?: 'Receipt';
  amount?: Maybe<Scalars['Float']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
};

export type ReceiptsPayload = {
  __typename?: 'ReceiptsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Receipt>>>;
};

export enum RecordAction {
  Start = 'START',
  Stop = 'STOP',
  Pause = 'PAUSE',
  Resume = 'RESUME',
}

export type RecordCall = {
  __typename?: 'RecordCall';
  status?: Maybe<Scalars['String']>;
};

export type RecordCallInput = {
  action?: Maybe<RecordAction>;
  call_sid?: Maybe<Scalars['String']>;
  direction?: Maybe<CallDirection>;
};

export type RecordCallPayload = {
  __typename?: 'RecordCallPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<RecordCall>;
};

export enum RecordingType {
  TextToSpeech = 'TextToSpeech',
  Custom = 'Custom',
  None = 'None',
}

export type RefreshTokenLoginPayload = {
  __typename?: 'RefreshTokenLoginPayload';
  status?: Maybe<Scalars['Int']>;
  error?: Maybe<ErrorData>;
  data?: Maybe<RefreshTokenResponseData>;
};

export type RefreshTokenResponseData = {
  __typename?: 'RefreshTokenResponseData';
  accessToken: Scalars['String'];
};

export type RegisterInputData = {
  organization: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
  passwordConfirm: Scalars['String'];
  defaultTimezone: Scalars['String'];
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<RegisterResponseData>;
};

export type RegisterResponseData = {
  __typename?: 'RegisterResponseData';
  id: Scalars['String'];
  organizationId: Scalars['String'];
};

export type RegulationPayload = {
  __typename?: 'RegulationPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Scalars['JSON']>;
};

export type RemoveCardInputData = {
  paymentMethodId: Scalars['String'];
};

export type RemoveCardPayload = {
  __typename?: 'RemoveCardPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<RemoveCardResponseData>;
};

export type RemoveCardResponseData = {
  __typename?: 'RemoveCardResponseData';
  id: Scalars['String'];
};

export type RemoveContactTagInputData = {
  tag: Scalars['ShortId'];
};

export type RemoveNumberInputData = {
  number: Scalars['String'];
};

export type RemoveTeamInputData = {
  password?: Maybe<Scalars['String']>;
};

export type RemoveTeammemberInputData = {
  member: Scalars['ShortId'];
};

export type RemoveWorkspaceInputData = {
  password: Scalars['String'];
};

export type RenewSubscriptionPayload = {
  __typename?: 'RenewSubscriptionPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Workspace>;
};

export type RequestedPlanWorkspace = {
  __typename?: 'RequestedPlanWorkspace';
  workspace_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type RequestedPlanWorkspacePayload = {
  __typename?: 'RequestedPlanWorkspacePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<RequestedPlanWorkspace>>>;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  token: Scalars['String'];
};

export enum ResourceStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Archived = 'Archived',
}

export type ResourceSucceedPayload = {
  __typename?: 'ResourceSucceedPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ResourceSucceedPayloadData>;
};

export type ResourceSucceedPayloadData = {
  __typename?: 'ResourceSucceedPayloadData';
  success: Scalars['Boolean'];
};

export type ResourceSucceedUrlPayloadData = {
  __typename?: 'ResourceSucceedURLPayloadData';
  url: Scalars['String'];
};

export type ResourceSucceedURlPayload = {
  __typename?: 'ResourceSucceedURlPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<ResourceSucceedUrlPayloadData>;
};

export type SearchInput = {
  value: Scalars['String'];
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SetUpAndMontlyFee = {
  __typename?: 'SetUpAndMontlyFee';
  setUpFee: Scalars['String'];
  monthlyFee: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  publicKey: Scalars['String'];
};

export type SetupIntentPayload = {
  __typename?: 'SetupIntentPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<IntentResponseData>;
};

export type SetupPlanRateInputData = {
  planRate: Scalars['Float'];
  workspaceId: Scalars['ShortId'];
};

export type SetupPlanRatePayload = {
  __typename?: 'SetupPlanRatePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<SetupPlanRateResponseData>;
};

export type SetupPlanRateResponseData = {
  __typename?: 'SetupPlanRateResponseData';
  success: Scalars['Boolean'];
};

export type StripePublicKeyPayload = {
  __typename?: 'StripePublicKeyPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Settings>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** for sending and updating conversation */
  updateConversation: ConversationSubscription;
  updateCounversationCount: MessageCountSubscription;
  onlineMemberStatus: MemberStatusSubscription;
  workspaceChat: WorkspaceChatSubscription;
  userProfile: UserProfileSubscription;
  newLeadsCount: NewLeadsCountSubscription;
  openContactCount: OpenContactCountSubscription;
  channelDndStatus: ChannelDndSubscriptionPayload;
};

export type SubscriptionUpdateConversationArgs = {
  channel: Scalars['ShortId'];
};

export type SubscriptionUpdateCounversationCountArgs = {
  channels: Array<Scalars['ShortId']>;
};

export type SubscriptionOnlineMemberStatusArgs = {
  workspace: Scalars['ShortId'];
};

export type SubscriptionUserProfileArgs = {
  user: Scalars['ShortId'];
};

export type SubscriptionNewLeadsCountArgs = {
  channel: Scalars['ShortId'];
};

export type SubscriptionOpenContactCountArgs = {
  channel: Scalars['ShortId'];
};

export type SubscriptionChannelDndStatusArgs = {
  channel: Scalars['ShortId'];
};

export enum SubscriptionAction {
  ReActivate = 'ReActivate',
  Cancel = 'Cancel',
}

export type SubscriptionActionToggle = {
  workspaceId: Scalars['ShortId'];
  action?: Maybe<SubscriptionAction>;
};

export type SubscriptionCountPayload = {
  __typename?: 'SubscriptionCountPayload';
  count?: Maybe<Scalars['Int']>;
  channel?: Maybe<Scalars['ShortId']>;
};

export type SuccessPayloadData = {
  __typename?: 'SuccessPayloadData';
  success: Scalars['Boolean'];
};

export enum SupportPortNumbersEnum {
  Requested = 'Requested',
  Progress = 'Progress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ShortId'];
  title: Scalars['String'];
  colorCode?: Maybe<Scalars['String']>;
  backgroundColorCode?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type TagInputData = {
  title: Scalars['String'];
  colorCode: Scalars['String'];
  backgroundColorCode: Scalars['String'];
};

export type TagPayload = {
  __typename?: 'TagPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<TagResponseData>;
};

export type TagResponseData = {
  __typename?: 'TagResponseData';
  id: Scalars['String'];
  title: Scalars['String'];
  colorCode: Scalars['String'];
  backgroundColorCode: Scalars['String'];
};

export type TagsPayload = {
  __typename?: 'TagsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Tag>>>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ShortId'];
  name: Scalars['String'];
  age?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  online: Scalars['Int'];
  teamMembers?: Maybe<Array<Maybe<TeamMembers>>>;
};

export type TeamDetailPayLoad = {
  __typename?: 'TeamDetailPayLoad';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Team>;
};

export type TeamInputData = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['Upload']>;
  avatar?: Maybe<Scalars['String']>;
  members: Array<Maybe<Scalars['ShortId']>>;
};

export type TeamMemberConnection = Connection & {
  __typename?: 'TeamMemberConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<TeamMemberEdge>>>;
};

export type TeamMemberEdge = Edge & {
  __typename?: 'TeamMemberEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<TeamMemberNode>;
};

export type TeamMemberNode = Node & {
  __typename?: 'TeamMemberNode';
  id: Scalars['ShortId'];
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Datetime']>;
  online?: Maybe<Scalars['Boolean']>;
};

export type TeamMembers = {
  __typename?: 'TeamMembers';
  id: Scalars['ShortId'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type TeamMembersPayload = {
  __typename?: 'TeamMembersPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<TeamMemberConnection>;
};

export type TeamPayload = {
  __typename?: 'TeamPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Team>;
};

export type TeamsDetailPayLoad = {
  __typename?: 'TeamsDetailPayLoad';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Team>>>;
};

export enum TicketStatusType {
  Progress = 'Progress',
  Open = 'Open',
  Closed = 'Closed',
}

export type TimeZone = {
  __typename?: 'TimeZone';
  value: Scalars['String'];
  abbr: Scalars['String'];
  offset: Scalars['Float'];
  isdst: Scalars['String'];
  text: Scalars['String'];
  utc?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ToggleSubscriptionPayload = {
  __typename?: 'ToggleSubscriptionPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Workspace>;
};

export type TransferInput = {
  direction?: Maybe<ConversationDirection>;
  conversationId?: Maybe<Scalars['ShortId']>;
  callerId?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
};

export type TransferPayload = {
  __typename?: 'TransferPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Scalars['String']>;
};

export enum UnanswerCallFallback {
  DismissCall = 'DismissCall',
  Queue = 'Queue',
  VoiceMail = 'VoiceMail',
  ExternalNumber = 'ExternalNumber',
}

export enum UnansweredForwardToType {
  VoiceMail = 'VoiceMail',
  DismissCall = 'DismissCall',
}

export type UnreadConversationInfo = {
  __typename?: 'UnreadConversationInfo';
  id?: Maybe<Scalars['ShortId']>;
  cursor: Scalars['Cursor'];
};

export type UpdateMacosInputData = {
  title: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type UpdateMacrosPayload = {
  __typename?: 'UpdateMacrosPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<MacrosData>;
};

export type UpdateNotePayload = {
  __typename?: 'UpdateNotePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<UpdateNoteResponseData>;
};

export type UpdateNoteResponseData = {
  __typename?: 'UpdateNoteResponseData';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type UpdateNotificationsInputData = {
  callMessages?: Maybe<Scalars['Boolean']>;
  newLeads?: Maybe<Scalars['Boolean']>;
  flashTaskbar?: Maybe<Scalars['Boolean']>;
};

export type UpdateOnlineStatusPayload = {
  __typename?: 'UpdateOnlineStatusPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<StatusUpdatePayload>;
};

export type UpdateProfileSucceedPayload = {
  __typename?: 'UpdateProfileSucceedPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<UserProfile>;
};

export type UpdateTeamInputData = {
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Scalars['ShortId']>>>;
  picture?: Maybe<Scalars['Upload']>;
  avatar?: Maybe<Scalars['String']>;
};

export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Team>;
};

export type UploadContactData = {
  __typename?: 'UploadContactData';
  totalRecords: Scalars['Int'];
  savedRecords: Scalars['Int'];
};

export type UploadContactPayload = {
  __typename?: 'UploadContactPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<UploadContactData>;
};

export type UploadPayload = {
  __typename?: 'UploadPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data: UploadUri;
};

export type UploadUri = {
  __typename?: 'UploadUri';
  uri: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
};

export enum UserActivationAction {
  Activate = 'Activate',
  Deactivate = 'Deactivate',
}

export type UserActivationToggle = {
  authId: Scalars['ShortId'];
  action?: Maybe<UserActivationAction>;
};

export type UserDndInput = {
  minutes?: Maybe<Scalars['Int']>;
  removeFromDND?: Maybe<Scalars['Boolean']>;
};

export type UserInviteAcceptData = {
  __typename?: 'UserInviteAcceptData';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  workspace: Scalars['ShortId'];
};

export type UserInviteAcceptInput = {
  invitationCode: Scalars['String'];
  device: Scalars['String'];
};

export type UserInviteAcceptPayload = {
  __typename?: 'UserInviteAcceptPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<UserInviteAcceptData>;
};

export type UserList = {
  __typename?: 'UserList';
  authId?: Maybe<Scalars['ShortId']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Datetime']>;
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
};

export type UserListConnection = Connection & {
  __typename?: 'UserListConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<UserListEdge>>>;
};

export type UserListEdge = Edge & {
  __typename?: 'UserListEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<UserList>;
};

export type UserListPaginated = {
  __typename?: 'UserListPaginated';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<UserListConnection>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  userId: Scalars['ShortId'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  status: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
  defaultLanguage?: Maybe<Scalars['String']>;
  defaultTimezone?: Maybe<Scalars['String']>;
  defaultWorkspace?: Maybe<Scalars['String']>;
  stayOnline?: Maybe<Scalars['Boolean']>;
  dndEnabled?: Maybe<Scalars['Boolean']>;
  dndOn?: Maybe<Scalars['Datetime']>;
  dndEndtime?: Maybe<Scalars['Int']>;
  dndDuration?: Maybe<Scalars['Int']>;
};

export type UserProfileSubscription = {
  __typename?: 'UserProfileSubscription';
  event: Scalars['String'];
  message?: Maybe<UserProfile>;
};

export enum UserStatus {
  Active = 'Active',
  Verified = 'Verified',
  Inactive = 'Inactive',
}

export type UsernameMutationPayload = {
  __typename?: 'UsernameMutationPayload';
  status: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type VerifiedCallerIdData = {
  __typename?: 'VerifiedCallerIdData';
  id?: Maybe<Scalars['ShortId']>;
  number?: Maybe<Scalars['String']>;
};

export type VerifyEmailData = {
  __typename?: 'VerifyEmailData';
  success: Scalars['Boolean'];
  status: Scalars['String'];
};

export type VerifyEmailInput = {
  link: Scalars['String'];
};

export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<VerifyEmailData>;
};

export type VoiceToken = {
  __typename?: 'VoiceToken';
  voiceToken: Scalars['String'];
};

export type VoiceTokenPayLoad = {
  __typename?: 'VoiceTokenPayLoad';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data: VoiceToken;
};
export type AlphaNumId = {
  __typename?: 'AlphaNumId';
  countryCode?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  messagingServiceSid?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
};
export type Workspace = {
  __typename?: 'Workspace';
  id?: Maybe<Scalars['ShortId']>;
  title?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  photo?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Member>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  plan?: Maybe<PlanOverviewData>;
  owner?: Maybe<OwnerData>;
  loginMemberRole?: Maybe<MemberDetails>;
  a2pCampaign?: Record<string, any>[];
  kycVerified?: Maybe<Scalars['Boolean']>;
  alphaNumIds?: Maybe<Array<Maybe<AlphaNumId>>>;
  fraudDetectionThresholdDays?: Maybe<Scalars['Int']>;
};

export type WorkspaceChatSubscription = {
  __typename?: 'WorkspaceChatSubscription';
  event: Scalars['String'];
  message: ChatMessageNode;
};

export type WorkspaceConnection = Connection & {
  __typename?: 'WorkspaceConnection';
  pageInfo: PageInfo;
  edges?: Maybe<Array<Maybe<WorkspaceEdge>>>;
};

export type WorkspaceCurrentCredit = {
  __typename?: 'WorkspaceCurrentCredit';
  currentCredit?: Maybe<Scalars['Float']>;
};

export type WorkspaceDetail = {
  __typename?: 'WorkspaceDetail';
  id: Scalars['ShortId'];
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type WorkspaceDetailPayload = {
  __typename?: 'WorkspaceDetailPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Workspace>;
};

export type WorkspaceDraftsPayload = {
  __typename?: 'WorkspaceDraftsPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<ChannelDraftsPayload>>>;
};

export type WorkspaceEdge = Edge & {
  __typename?: 'WorkspaceEdge';
  cursor: Scalars['Cursor'];
  node?: Maybe<Workspace>;
};

export type WorkspaceInputData = {
  title: Scalars['String'];
  photo?: Maybe<Scalars['Upload']>;
};

export type WorkspaceListPaginated = {
  __typename?: 'WorkspaceListPaginated';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<WorkspaceConnection>;
};

export type WorkspaceListPayload = {
  __typename?: 'WorkspaceListPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Array<Maybe<Workspace>>>;
};

export type WorkspaceNode = {
  __typename?: 'WorkspaceNode';
  id: Scalars['ShortId'];
  title?: Maybe<Scalars['String']>;
  notification?: Maybe<Scalars['Boolean']>;
  photo?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Member>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  plan?: Maybe<PlanOverviewData>;
  loginMemberRole?: Maybe<MemberDetails>;
};

export type WorkspaceOrderInputData = {
  workspaceorder?: Maybe<Array<Workspaceorder>>;
};

export type WorkspacePayload = {
  __typename?: 'WorkspacePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<WorkspaceResponseData>;
};

export type WorkspaceRemovePayload = {
  __typename?: 'WorkspaceRemovePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Workspace>;
};

export type WorkspaceResponseData = {
  __typename?: 'WorkspaceResponseData';
  id: Scalars['String'];
  title: Scalars['String'];
  memberId: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
};

export type WorkspaceRestorePayload = {
  __typename?: 'WorkspaceRestorePayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<Workspace>;
};

export type WorkspaceSubscriptionInputData = {
  workspaceTitle: Scalars['String'];
  planTitle: Scalars['String'];
  interval: Scalars['String'];
  cardId: Scalars['String'];
  photo?: Maybe<Scalars['Upload']>;
};

export type WorkspaceSubscriptionPayload = {
  __typename?: 'WorkspaceSubscriptionPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<WorkspaceSubscriptionResponseData>;
};

export type WorkspaceSubscriptionResponseData = {
  __typename?: 'WorkspaceSubscriptionResponseData';
  workspaceId: Scalars['ShortId'];
  memberId: Scalars['ShortId'];
};

export type BulkContact = {
  number: Scalars['String'];
  fullName: Scalars['String'];
};

export type BulkContactsInput = {
  contacts?: Maybe<Array<Maybe<BulkContact>>>;
};

export type CardExistInputData = {
  token: Scalars['String'];
};

export type CardExistPayload = {
  __typename?: 'cardExistPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<CardExistResponseData>;
};

export type CardExistResponseData = {
  __typename?: 'cardExistResponseData';
  cardInfo?: Maybe<Scalars['Boolean']>;
};

export type Channelorder = {
  channel_id: Scalars['ShortId'];
  order: Scalars['Int'];
};

export enum ClientPlatform {
  Web = 'web',
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export type GetWorkspaceCreditPayload = {
  __typename?: 'getWorkspaceCreditPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<WorkspaceCurrentCredit>;
};

export type HideBrandingInputData = {
  hideKrispcallBranding: Scalars['Boolean'];
};

export type HideBrandingPayload = {
  __typename?: 'hideBrandingPayload';
  status: Scalars['Int'];
  error?: Maybe<ErrorData>;
  data?: Maybe<HideBrandingResponseData>;
};

export type HideBrandingResponseData = {
  __typename?: 'hideBrandingResponseData';
  hideKrispcallBranding?: Maybe<Scalars['Boolean']>;
};

export type MakePrimaryCardInputData = {
  paymentMethodId: Scalars['String'];
};

export type Provider_Dummy = {
  __typename?: 'provider_dummy';
  dummy?: Maybe<Scalars['String']>;
};

export type StatusUpdatePayload = {
  __typename?: 'statusUpdatePayload';
  id: Scalars['ShortId'];
  onlineStatus?: Maybe<Scalars['Boolean']>;
};

export type Workspaceorder = {
  workspace_id: Scalars['ShortId'];
  order: Scalars['Int'];
};

export type AddPermissionMutationVariables = Exact<{
  data: PermissionInputData;
}>;

export type AddPermissionMutation = { __typename?: 'Mutation' } & {
  addPermission: { __typename?: 'PermissionDataPayload' } & Pick<
    PermissionDataPayload,
    'status' | 'data'
  > & { error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment> };
};

export type PermissionQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionQuery = { __typename?: 'Query' } & {
  permissions: { __typename: 'PermissionDataPayload' } & Pick<
    PermissionDataPayload,
    'status' | 'data'
  > & { error?: Maybe<{ __typename?: 'ErrorData' } & Pick<ErrorData, 'code'>> };
};

export type PlanFieldsFragment = { __typename?: 'PlanResponseData' } & Pick<
  PlanResponseData,
  'id' | 'productId' | 'title' | 'rate' | 'order' | 'details'
>;

export type CardFieldsFragment = { __typename?: 'CardResponseData' } & Pick<
  CardResponseData,
  'id' | 'name' | 'expiryMonth' | 'expiryYear' | 'lastDigit' | 'brand'
>;

export type NotificationAndAutoRechargeFieldsFragment = {
  __typename?: 'NotificationAndAutoRecharge';
} & Pick<
  NotificationAndAutoRecharge,
  | 'id'
  | 'lowCreditNotification'
  | 'lowCreditBalanceNotify'
  | 'creditAutoRecharge'
  | 'recharge'
  | 'lowCreditBalanceRecharge'
  | 'zeroCreditNotification'
>;

export type BillingInfoFieldsFragment = { __typename?: 'BillingInformation' } & Pick<
  BillingInformation,
  'billTo' | 'taxId' | 'country' | 'address' | 'recipients'
>;

export type UpdateBillingInformationMutationVariables = Exact<{
  data: BillingInformationInputData;
}>;

export type UpdateBillingInformationMutation = { __typename?: 'Mutation' } & {
  updateBillingInformation: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type HideBrandingMutationVariables = Exact<{
  data: HideBrandingInputData;
}>;

export type HideBrandingMutation = { __typename?: 'Mutation' } & {
  hideBranding: { __typename?: 'hideBrandingPayload' } & Pick<HideBrandingPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'hideBrandingResponseData' } & Pick<
          HideBrandingResponseData,
          'hideKrispcallBranding'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SaveNotificationAndAutoRechargeMutationVariables = Exact<{
  data: NotificationAndAutoRechargeInputData;
}>;

export type SaveNotificationAndAutoRechargeMutation = { __typename?: 'Mutation' } & {
  saveNotificationAndAutoRecharge: { __typename?: 'NotificationAndAutoRechargePayload' } & Pick<
    NotificationAndAutoRechargePayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'NotificationAndAutoRecharge' } & NotificationAndAutoRechargeFieldsFragment
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangePlanMutationVariables = Exact<{
  data: ChangePlanInputData;
}>;

export type ChangePlanMutation = { __typename?: 'Mutation' } & {
  changePlan: { __typename?: 'ChangePlanPayload' } & Pick<ChangePlanPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'ChangePlanResponseData' } & Pick<ChangePlanResponseData, 'id'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type CancelSubscriptionMutation = { __typename?: 'Mutation' } & {
  cancelSubscription: { __typename?: 'CancelSubscriptionPayload' } & Pick<
    CancelSubscriptionPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'CancelSubscriptionResponseData' } & Pick<
          CancelSubscriptionResponseData,
          'subscriptionActive'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RenewSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type RenewSubscriptionMutation = { __typename?: 'Mutation' } & {
  renewSubscription: { __typename?: 'RenewSubscriptionPayload' } & Pick<
    RenewSubscriptionPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'Workspace' } & Pick<Workspace, 'id'> & {
            plan?: Maybe<
              { __typename?: 'PlanOverviewData' } & Pick<
                PlanOverviewData,
                | 'remainingDays'
                | 'subscriptionActive'
                | 'trialPeriod'
                | 'cardInfo'
                | 'currentCredit'
                | 'startedAt'
                | 'endedAt'
              > & {
                  planDetail?: Maybe<
                    { __typename?: 'PlanDetailData' } & Pick<PlanDetailData, 'title' | 'interval'>
                  >;
                }
            >;
          }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SwitchPlanMutationVariables = Exact<{ [key: string]: never }>;

export type SwitchPlanMutation = { __typename?: 'Mutation' } & {
  switchPlan: { __typename?: 'ChangePlanPayload' } & Pick<ChangePlanPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'ChangePlanResponseData' } & Pick<ChangePlanResponseData, 'id'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type BillingInformationQueryVariables = Exact<{ [key: string]: never }>;

export type BillingInformationQuery = { __typename?: 'Query' } & {
  billingInformation: { __typename?: 'BillingInformationPayload' } & Pick<
    BillingInformationPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'BillingInformation' } & BillingInfoFieldsFragment>;
    };
};

export type PlansQueryVariables = Exact<{
  interval?: Maybe<Scalars['String']>;
}>;

export type PlansQuery = { __typename?: 'Query' } & {
  plans: { __typename?: 'AllPlansPayload' } & Pick<AllPlansPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<Array<Maybe<{ __typename?: 'PlanResponseData' } & PlanFieldsFragment>>>;
    };
};

export type PlanOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type PlanOverviewQuery = { __typename?: 'Query' } & {
  planOverview: { __typename?: 'PlanOverviewPayload' } & Pick<PlanOverviewPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'PlanOverviewSettings' } & Pick<
          PlanOverviewSettings,
          | 'customerId'
          | 'subscriptionActive'
          | 'currentPeriodEnd'
          | 'totalAmount'
          | 'interval'
          | 'remainingDays'
        > & {
            plan?: Maybe<{ __typename?: 'PlanResponseData' } & PlanFieldsFragment>;
            credit?: Maybe<{ __typename?: 'Credit' } & Pick<Credit, 'id' | 'amount'>>;
            card?: Maybe<{ __typename?: 'CardResponseData' } & CardFieldsFragment>;
          }
      >;
    };
};

export type NotificationAndAutoRechargeQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationAndAutoRechargeQuery = { __typename?: 'Query' } & {
  notificationAndAutoRecharge: { __typename?: 'NotificationAndAutoRechargePayload' } & Pick<
    NotificationAndAutoRechargePayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'NotificationAndAutoRecharge' } & NotificationAndAutoRechargeFieldsFragment
      >;
    };
};

export type ReceiptsQueryVariables = Exact<{ [key: string]: never }>;

export type ReceiptsQuery = { __typename?: 'Query' } & {
  receipts: { __typename?: 'ReceiptsPayload' } & Pick<ReceiptsPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Receipt' } & Pick<Receipt, 'amount' | 'invoiceNumber' | 'createdOn'>
          >
        >
      >;
    };
};

export type InvoiceQueryVariables = Exact<{
  invoiceNumber: Scalars['String'];
}>;

export type InvoiceQuery = { __typename?: 'Query' } & {
  invoice: { __typename?: 'InvoiceDetailResponseData' } & Pick<
    InvoiceDetailResponseData,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'Invoice' } & Pick<
          Invoice,
          'invoiceNumber' | 'createdOn' | 'totalAmount'
        > & {
            billingInformation?: Maybe<
              { __typename?: 'BillingInformation' } & BillingInfoFieldsFragment
            >;
            cardDetail?: Maybe<
              { __typename?: 'CardResponseData' } & Pick<
                CardResponseData,
                'id' | 'brand' | 'lastDigit'
              >
            >;
            invoiceItems?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'InvoiceItems' } & Pick<
                    InvoiceItems,
                    'unitPrice' | 'content' | 'contentType'
                  >
                >
              >
            >;
          }
      >;
    };
};

export type AddNumberMutationVariables = Exact<{
  data: Number;
}>;

export type AddNumberMutation = { __typename?: 'Mutation' } & {
  addNumber: { __typename?: 'AddNumberPayload' } & Pick<AddNumberPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'PendingNumber' } & PendingNumberResponseFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ArchiveNumberMutationVariables = Exact<{
  data: RemoveNumberInputData;
}>;

export type ArchiveNumberMutation = { __typename?: 'Mutation' } & {
  archiveNumber: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ProcessNumberMutationVariables = Exact<{
  data: ProcessNumbersInputData;
}>;

export type ProcessNumberMutation = { __typename?: 'Mutation' } & {
  processNumber: { __typename?: 'NumbersPayload' } & Pick<NumbersPayload, 'status'> & {
      data?: Maybe<Array<Maybe<{ __typename?: 'NumbersNode' } & NumberFieldsFragment>>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SearchAvailableNumbersQueryVariables = Exact<{
  pathParams?: Maybe<NumberAvailablityPathParams>;
  searchParams?: Maybe<NumberAvailablitySearchParams>;
}>;

export type SearchAvailableNumbersQuery = { __typename?: 'Query' } & {
  searchAvailableNumbers: { __typename?: 'NumberSearchPayload' } & Pick<
    NumberSearchPayload,
    'status'
  > & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'PhoneNumber' } & Pick<
              PhoneNumber,
              | 'type'
              | 'dialingNumber'
              | 'addressRequirements'
              | 'region'
              | 'locality'
              | 'countryIso'
            > & {
                capabilities: { __typename?: 'NumberCapability' } & Pick<
                  NumberCapability,
                  'voice' | 'sms' | 'mms'
                >;
                price?: Maybe<
                  { __typename?: 'SetUpAndMontlyFee' } & Pick<
                    SetUpAndMontlyFee,
                    'setUpFee' | 'monthlyFee'
                  >
                >;
              }
          >
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AvailableNumberCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableNumberCountriesQuery = { __typename?: 'Query' } & {
  availableNumberCountries: { __typename?: 'AvailableCountriesPayload' } & Pick<
    AvailableCountriesPayload,
    'status'
  > & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'AvailableCountry' } & Pick<
              AvailableCountry,
              'countryCode' | 'country' | 'types'
            >
          >
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CountryFromCodeQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;

export type CountryFromCodeQuery = { __typename?: 'Query' } & {
  countryFromCode: { __typename?: 'CountryPayLoad' } & Pick<CountryPayLoad, 'status'> & {
      data?: Maybe<{ __typename?: 'Country' } & Pick<Country, 'cities'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChannelNameExistsQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type ChannelNameExistsQuery = { __typename?: 'Query' } & {
  channelNameExists: { __typename?: 'CheckNamePayload' } & Pick<CheckNamePayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type PendingNumbersQueryVariables = Exact<{
  purchaseStatus?: Maybe<PendingNumbersEnum>;
}>;

export type PendingNumbersQuery = { __typename?: 'Query' } & {
  pendingNumbers: { __typename?: 'PendingNumbersPayload' } & Pick<
    PendingNumbersPayload,
    'status'
  > & {
      data?: Maybe<Array<Maybe<{ __typename?: 'PendingNumber' } & PendingNumberResponseFragment>>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RegulationQueryVariables = Exact<{
  sid: Scalars['String'];
}>;

export type RegulationQuery = { __typename?: 'Query' } & {
  regulation: { __typename?: 'RegulationPayload' } & Pick<RegulationPayload, 'status' | 'data'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SendMessageMutationVariables = Exact<{
  data: ConversationInput;
}>;

export type SendMessageMutation = { __typename?: 'Mutation' } & {
  sendMessage: { __typename?: 'ConversationPayload' } & Pick<ConversationPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'ConversationNode' } & ConversationFieldsFragment>;
    };
};

export type SendBulkMessageMutationVariables = Exact<{
  data: BulkMessageInput;
}>;

export type SendBulkMessageMutation = { __typename?: 'Mutation' } & {
  sendBulkMessage: { __typename?: 'BulkMessagePayload' } & Pick<BulkMessagePayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data: { __typename?: 'BulkMessagePayloadData' } & Pick<
        BulkMessagePayloadData,
        'id' | 'channel' | 'content' | 'contacts'
      >;
    };
};

export type PinnedAgentConversationDataMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: PinnedConversationAgentData;
}>;

export type PinnedAgentConversationDataMutation = { __typename?: 'Mutation' } & {
  pinnedAgentConversationData: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AddPinnedMutationVariables = Exact<{
  data: PinnedInputData;
}>;

export type AddPinnedMutation = { __typename?: 'Mutation' } & {
  addPinned: { __typename?: 'ResourceSucceedPayload' } & Pick<ResourceSucceedPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ConversationSeenMutationVariables = Exact<{
  contact: Scalars['String'];
  channel: Scalars['ShortId'];
}>;

export type ConversationSeenMutation = { __typename?: 'Mutation' } & {
  conversationSeen: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
    };
};

export type ChannelContactStateMutationVariables = Exact<{
  channel: Scalars['ShortId'];
  data: ChannelContactStateInputData;
}>;

export type ChannelContactStateMutation = { __typename?: 'Mutation' } & {
  channelContactState: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
    };
};

export type AgentDraftsMutationVariables = Exact<{
  data: AgentDraftsInputData;
}>;

export type AgentDraftsMutation = { __typename?: 'Mutation' } & {
  agentDrafts: { __typename?: 'AgentDraftsPayload' } & Pick<AgentDraftsPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'AgentDraftResponse' } & Pick<AgentDraftResponse, 'id' | 'msg'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ArchieveContactThreadMutationVariables = Exact<{
  data: ArchiveContactThreadInputData;
}>;

export type ArchieveContactThreadMutation = { __typename?: 'Mutation' } & {
  archieveContactThread: { __typename?: 'ArchieveContactThreadPayload' } & Pick<
    ArchieveContactThreadPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ArchieveConversationResult' } & Pick<
          ArchieveConversationResult,
          'contact' | 'msg' | 'channel'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type MarkAsUnreadConversationMutationVariables = Exact<{
  conversation: Scalars['ShortId'];
}>;

export type MarkAsUnreadConversationMutation = { __typename?: 'Mutation' } & {
  markAsUnreadConversation: { __typename?: 'MarkAsUnreadConversationPayload' } & Pick<
    MarkAsUnreadConversationPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'MarkAsUnread' } & Pick<MarkAsUnread, 'id' | 'unreadCount'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AddMacrosMutationVariables = Exact<{
  data: MacrosInputData;
}>;

export type AddMacrosMutation = { __typename?: 'Mutation' } & {
  addMacros: { __typename?: 'MacrosPayload' } & Pick<MacrosPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'MacrosResponseData' } & Pick<
          MacrosResponseData,
          'id' | 'title' | 'message' | 'type'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RemoveMacrosMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type RemoveMacrosMutation = { __typename?: 'Mutation' } & {
  removeMacros: { __typename?: 'MacrosRemovePayload' } & Pick<MacrosRemovePayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'MacrosData' } & Pick<MacrosData, 'id' | 'title' | 'message' | 'type'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type UpdateMacrosMutationVariables = Exact<{
  data: UpdateMacosInputData;
  id: Scalars['ShortId'];
}>;

export type UpdateMacrosMutation = { __typename?: 'Mutation' } & {
  updateMacros: { __typename?: 'UpdateMacrosPayload' } & Pick<UpdateMacrosPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'MacrosData' } & Pick<MacrosData, 'id' | 'title' | 'message' | 'type'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ConversationQueryVariables = Exact<{
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
  params?: Maybe<ConnectionInput>;
}>;

export type ConversationQuery = { __typename?: 'Query' } & {
  conversation: { __typename?: 'ConversationHistoryPayload' } & Pick<
    ConversationHistoryPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ConversationConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ConversationEdge' } & Pick<ConversationEdge, 'cursor'> & {
                    node?: Maybe<{ __typename?: 'ConversationNode' } & ConversationFieldsFragment>;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type RecentConversationQueryVariables = Exact<{
  channel: Scalars['ShortId'];
  params: ConnectionInput;
}>;

export type RecentConversationQuery = { __typename?: 'Query' } & {
  recentConversation: { __typename?: 'ConversationHistoryPayload' } & Pick<
    ConversationHistoryPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ConversationConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ConversationEdge' } & Pick<ConversationEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'ConversationNode' } & RecentConversationFieldsFragment
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type UpdateConversationCountSubscriptionVariables = Exact<{
  channels: Array<Scalars['ShortId']> | Scalars['ShortId'];
}>;

export type UpdateConversationCountSubscription = { __typename?: 'Subscription' } & {
  updateCounversationCount: { __typename?: 'MessageCountSubscription' } & Pick<
    MessageCountSubscription,
    'event'
  > & {
      message: { __typename?: 'CountSubscriptionData' } & Pick<CountSubscriptionData, 'channel'>;
    };
};

export type NewCountQueryVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type NewCountQuery = { __typename?: 'Query' } & {
  newCount: { __typename?: 'CountPayload' } & Pick<CountPayload, 'status' | 'data'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AllPinnedConversationQueryVariables = Exact<{
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
}>;

export type AllPinnedConversationQuery = { __typename?: 'Query' } & {
  allPinnedConversation: { __typename?: 'ConversationListPayload' } & Pick<
    ConversationListPayload,
    'status'
  > & {
      data?: Maybe<
        Array<Maybe<{ __typename?: 'ConversationNode' } & PinnedConversationFieldsFragment>>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ContactPinnedConversationOpenClosedQueryVariables = Exact<{
  channel: Scalars['ShortId'];
  params: ConnectionInput;
}>;

export type ContactPinnedConversationOpenClosedQuery = { __typename?: 'Query' } & {
  contactPinnedConversationOpenClosed: { __typename?: 'ConversationHistoryPayload' } & Pick<
    ConversationHistoryPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ConversationConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ConversationEdge' } & Pick<ConversationEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'ConversationNode' } & RecentConversationFieldsFragment
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type RecentConversationHistoryQueryVariables = Exact<{
  channel: Scalars['ShortId'];
  params: ConnectionInput;
}>;

export type RecentConversationHistoryQuery = { __typename?: 'Query' } & {
  recentConversationHistory: { __typename?: 'ConversationHistoryPayload' } & Pick<
    ConversationHistoryPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ConversationConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ConversationEdge' } & Pick<ConversationEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'ConversationNode' } & RecentConversationFieldsFragment
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type OpenConversationCountQueryVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type OpenConversationCountQuery = { __typename?: 'Query' } & {
  openContactCount: { __typename?: 'CountPayload' } & Pick<CountPayload, 'status' | 'data'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type WorkspaceDraftsQueryVariables = Exact<{
  workspace?: Maybe<Scalars['ShortId']>;
}>;

export type WorkspaceDraftsQuery = { __typename?: 'Query' } & {
  workspaceDrafts: { __typename?: 'WorkspaceDraftsPayload' } & Pick<
    WorkspaceDraftsPayload,
    'status'
  > & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ChannelDraftsPayload' } & Pick<ChannelDraftsPayload, 'channel'> & {
                messages?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'ChannelDraftsNode' } & Pick<
                        ChannelDraftsNode,
                        'id' | 'content' | 'clientNumber'
                      >
                    >
                  >
                >;
              }
          >
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ConversationHistoryQueryVariables = Exact<{
  channel: Scalars['ShortId'];
  contact: Scalars['String'];
  conversationStatus: Scalars['String'];
  params?: Maybe<ConnectionInput>;
}>;

export type ConversationHistoryQuery = { __typename?: 'Query' } & {
  conversationHistory: { __typename?: 'ConversationHistoryPayload' } & Pick<
    ConversationHistoryPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ConversationConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ConversationEdge' } & Pick<ConversationEdge, 'cursor'> & {
                    node?: Maybe<{ __typename?: 'ConversationNode' } & ConversationFieldsFragment>;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type MacrosQueryVariables = Exact<{ [key: string]: never }>;

export type MacrosQuery = { __typename?: 'Query' } & {
  macros: { __typename?: 'MacrosAllPayload' } & Pick<MacrosAllPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'MacrosData' } & Pick<MacrosData, 'id' | 'message' | 'title' | 'type'>
          >
        >
      >;
    };
};

export type UpdateConversationSubscriptionVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type UpdateConversationSubscription = { __typename?: 'Subscription' } & {
  updateConversation: { __typename?: 'ConversationSubscription' } & Pick<
    ConversationSubscription,
    'event'
  > & { message: { __typename?: 'ConversationNode' } & ConversationFieldsFragment };
};

export type OpenContactCountSubscriptionVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

// export type OpenContactCountSubscription = (
//   { __typename?: 'Subscription' }
//   & { openContactCount: (
//     { __typename?: 'OpenContactCountSubscription' }
//     & Pick<OpenContactCountSubscription, 'event'>
//     & { message: (
//       { __typename?: 'SubscriptionCountPayload' }
//       & Pick<SubscriptionCountPayload, 'count' | 'channel'>
//     ) }
//   ) }
// );

export type ConversationFieldsFragment = { __typename?: 'ConversationNode' } & Pick<
  ConversationNode,
  | 'id'
  | 'contactClosed'
  | 'conversationStatus'
  | 'transcriptionStatus'
  | 'conversationType'
  | 'direction'
  | 'createdAt'
  | 'clientNumber'
  | 'sms'
  | 'clientCountry'
  | 'status'
  | 'clientUnseenMsgCount'
> & {
    content?: Maybe<
      { __typename?: 'ConversationContent' } & Pick<
        ConversationContent,
        'body' | 'duration' | 'transferedAudio' | 'transcript'
      >
    >;
    clientInfo?: Maybe<
      { __typename?: 'ClientInfo' } & Pick<
        ClientInfo,
        'id' | 'number' | 'name' | 'country' | 'createdBy' | 'profilePicture'
      >
    >;
    agentInfo?: Maybe<
      { __typename?: 'AgentInfo' } & Pick<
        AgentInfo,
        'agentId' | 'firstname' | 'lastname' | 'profilePicture'
      >
    >;
    personalizedInfo?: Maybe<
      { __typename?: 'PersonalizedInfo' } & Pick<PersonalizedInfo, 'pinned' | 'seen' | 'favourite'>
    >;
  };

export type RecentConversationFieldsFragment = { __typename?: 'ConversationNode' } & Pick<
  ConversationNode,
  | 'id'
  | 'contactClosed'
  | 'contactPinned'
  | 'clientNumber'
  | 'clientCountry'
  | 'conversationType'
  | 'conversationStatus'
  | 'transcriptionStatus'
  | 'voicemailAudio'
  | 'createdAt'
  | 'direction'
  | 'sms'
  | 'status'
  | 'clientUnseenMsgCount'
> & {
    clientInfo?: Maybe<
      { __typename?: 'ClientInfo' } & Pick<
        ClientInfo,
        | 'id'
        | 'number'
        | 'name'
        | 'country'
        | 'createdBy'
        | 'profilePicture'
        | 'dndDuration'
        | 'dndEnabled'
        | 'dndEndtime'
      >
    >;
    agentInfo?: Maybe<{ __typename?: 'AgentInfo' } & Pick<AgentInfo, 'agentId' | 'firstname'>>;
    unreadConversationInfo?: Maybe<
      { __typename?: 'UnreadConversationInfo' } & Pick<UnreadConversationInfo, 'cursor' | 'id'>
    >;
  };

export type PinnedConversationFieldsFragment = { __typename?: 'ConversationNode' } & Pick<
  ConversationNode,
  | 'id'
  | 'conversationStatus'
  | 'transcriptionStatus'
  | 'conversationType'
  | 'direction'
  | 'createdAt'
  | 'clientNumber'
  | 'sms'
> & {
    content?: Maybe<
      { __typename?: 'ConversationContent' } & Pick<
        ConversationContent,
        'body' | 'duration' | 'transferedAudio' | 'transcript'
      >
    >;
    clientInfo?: Maybe<
      { __typename?: 'ClientInfo' } & Pick<
        ClientInfo,
        'id' | 'number' | 'name' | 'country' | 'createdBy' | 'profilePicture'
      >
    >;
    agentInfo?: Maybe<
      { __typename?: 'AgentInfo' } & Pick<
        AgentInfo,
        'agentId' | 'firstname' | 'lastname' | 'profilePicture'
      >
    >;
  };

export type AgentFieldsFragment = { __typename?: 'Agent' } & Pick<
  Agent,
  'id' | 'firstname' | 'email' | 'role' | 'lastname' | 'photo'
>;

export type NumberFieldsFragment = { __typename?: 'NumbersNode' } & Pick<
  NumbersNode,
  'id' | 'name' | 'number' | 'callStrategy'
> & {
    agents?: Maybe<
      Array<
        Maybe<{ __typename?: 'Agent' } & Pick<Agent, 'id' | 'firstname' | 'lastname' | 'photo'>>
      >
    >;
  };

export type PendingNumberResponseFragment = { __typename?: 'PendingNumber' } & Pick<
  PendingNumber,
  | 'name'
  | 'number'
  | 'country'
  | 'setUpFee'
  | 'monthlyFee'
  | 'purchaseStatus'
  | 'purchase_status'
  | 'addressRequirement'
  | 'regulationSid'
>;

export type ChannelFieldsFragment = { __typename?: 'Channel' } & Pick<
  Channel,
  | 'id'
  | 'countryLogo'
  | 'country'
  | 'countryCode'
  | 'number'
  | 'name'
  | 'dndEndtime'
  | 'dndEnabled'
  | 'dndRemainingTime'
  | 'dndOn'
  | 'dndDuration'
  | 'unseenMessageCount'
  | 'sms'
  | 'call'
  | 'mms'
>;

export type NumberAbilitiesFieldsFragment = { __typename?: 'NumberAbilities' } & Pick<
  NumberAbilities,
  'call' | 'sms' | 'mms'
>;

export type AddChannelMutationVariables = Exact<{
  data: ChannelInputData;
}>;

export type AddChannelMutation = { __typename?: 'Mutation' } & {
  addChannel: { __typename?: 'ChannelPayload' } & Pick<ChannelPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'ChannelResponseData' } & Pick<ChannelResponseData, 'id'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChannelOrderMutationVariables = Exact<{
  data: ChannelOrderInputData;
}>;

export type ChannelOrderMutation = { __typename?: 'Mutation' } & {
  channelOrder: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SetChannelDndMutationVariables = Exact<{
  id: Scalars['ShortId'];
  minutes?: Maybe<Scalars['Int']>;
}>;

export type SetChannelDndMutation = { __typename?: 'Mutation' } & {
  setChannelDnd: { __typename?: 'DNDPayload' } & Pick<DndPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'Dnd' } & Pick<Dnd, 'dndEnabled' | 'dndEndtime' | 'dndRemainingTime'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RemoveChannelDndMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type RemoveChannelDndMutation = { __typename?: 'Mutation' } & {
  removeChannelDnd: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChannelConversationSeenMutationVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type ChannelConversationSeenMutation = { __typename?: 'Mutation' } & {
  channelConversationSeen: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RejectConversationMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type RejectConversationMutation = { __typename?: 'Mutation' } & {
  rejectConversation: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CallRecordingMutationVariables = Exact<{
  data: RecordCallInput;
}>;

export type CallRecordingMutation = { __typename?: 'Mutation' } & {
  callRecording: { __typename?: 'RecordCallPayload' } & Pick<RecordCallPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'RecordCall' } & Pick<RecordCall, 'status'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CancelOutgoingCallMutationVariables = Exact<{
  data: OutgoingCancelInput;
}>;

export type CancelOutgoingCallMutation = { __typename?: 'Mutation' } & {
  cancelOutgoingCall: { __typename?: 'OutgoingCancelPayload' } & Pick<
    OutgoingCancelPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'CancelStatus' } & Pick<CancelStatus, 'CancelStatus'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CallHoldMutationVariables = Exact<{
  data: HoldCallInput;
}>;

export type CallHoldMutation = { __typename?: 'Mutation' } & {
  callHold: { __typename?: 'HoldCallPayload' } & Pick<HoldCallPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'HoldCall' } & Pick<HoldCall, 'message'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CallRatingMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: CallRatingInputData;
}>;

export type CallRatingMutation = { __typename?: 'Mutation' } & {
  callRating: { __typename?: 'ResourceSucceedPayload' } & Pick<ResourceSucceedPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type GetVoiceTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetVoiceTokenQuery = { __typename?: 'Query' } & {
  getVoiceToken: { __typename?: 'VoiceTokenPayLoad' } & Pick<VoiceTokenPayLoad, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data: { __typename?: 'VoiceToken' } & Pick<VoiceToken, 'voiceToken'>;
    };
};

export type ChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type ChannelsQuery = { __typename?: 'Query' } & {
  channels: { __typename?: 'ChannelsPayload' } & Pick<ChannelsPayload, 'status'> & {
      data?: Maybe<Array<Maybe<{ __typename?: 'Channel' } & ChannelFieldsFragment>>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChannelInfoQueryVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type ChannelInfoQuery = { __typename?: 'Query' } & {
  channelInfo: { __typename?: 'ChannelInfo' } & Pick<ChannelInfo, 'status'> & {
      data: { __typename?: 'ChannelInfoData' } & Pick<
        ChannelInfoData,
        | 'id'
        | 'country'
        | 'name'
        | 'countryLogo'
        | 'countryCode'
        | 'number'
        | 'call'
        | 'sms'
        | 'mms'
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SaveIvrSettingsMutationVariables = Exact<{
  channel: Scalars['ShortId'];
  data: IvrSettingsInputData;
}>;

export type SaveIvrSettingsMutation = { __typename?: 'Mutation' } & {
  saveIvrSettings: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type UpdateCallStrategyMutationVariables = Exact<{
  channel: Scalars['ShortId'];
  data: CallStrategySettingsInput;
}>;

export type UpdateCallStrategyMutation = { __typename?: 'Mutation' } & {
  updateCallStrategy: { __typename?: 'ChannelSettingsUpdatePayload' } & Pick<
    ChannelSettingsUpdatePayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ChannelSettingsUpdateNode' } & Pick<
          ChannelSettingsUpdateNode,
          'incomingCallStrategy'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type PortNumberMutationVariables = Exact<{
  data: PortNumberInputData;
}>;

export type PortNumberMutation = { __typename?: 'Mutation' } & {
  portNumber: { __typename?: 'ResourceSucceedPayload' } & Pick<ResourceSucceedPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type UpdateGeneralSettingsMutationVariables = Exact<{
  channel: Scalars['ShortId'];
  data: GeneralChannelSettingsInput;
}>;

export type UpdateGeneralSettingsMutation = { __typename?: 'Mutation' } & {
  updateGeneralSettings: { __typename?: 'ChannelSettingsPayload' } & Pick<
    ChannelSettingsPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ChannelSettingsNode' } & Pick<
          ChannelSettingsNode,
          | 'name'
          | 'autoRecordCalls'
          | 'internationalCallAndMessages'
          | 'emailNotification'
          | 'transcription'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type EditChannelAccessMutationVariables = Exact<{
  channel: Scalars['ShortId'];
  data: EditChannelShareInput;
}>;

export type EditChannelAccessMutation = { __typename?: 'Mutation' } & {
  editChannelAccess: { __typename?: 'ChannelAccessPayload' } & Pick<
    ChannelAccessPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ChannelAccessData' } & Pick<ChannelAccessData, 'id' | 'shared'> & {
            agents?: Maybe<Array<Maybe<{ __typename?: 'Agent' } & AgentFieldsFragment>>>;
          }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type DeleteChannelMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type DeleteChannelMutation = { __typename?: 'Mutation' } & {
  deleteChannel: { __typename?: 'DeleteChannelPayload' } & Pick<DeleteChannelPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'DeleteChannel' } & Pick<DeleteChannel, 'id' | 'success'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SetupCallerIdMutationVariables = Exact<{
  data: CallerIdNumberInput;
}>;

export type SetupCallerIdMutation = { __typename?: 'Mutation' } & {
  setupCallerId: { __typename?: 'CallerIdRequestPayload' } & Pick<
    CallerIdRequestPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'CallerIdRequestData' } & Pick<
          CallerIdRequestData,
          'code' | 'message' | 'status'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SetChannelCallerIdMutationVariables = Exact<{
  data: CallerIdNumberInput;
}>;

export type SetChannelCallerIdMutation = { __typename?: 'Mutation' } & {
  setChannelCallerId: { __typename?: 'ChannelSettingsPayload' } & Pick<
    ChannelSettingsPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'ChannelSettingsNode' } & Pick<ChannelSettingsNode, 'callerId'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type NumbersQueryVariables = Exact<{ [key: string]: never }>;

export type NumbersQuery = { __typename?: 'Query' } & {
  numbers: { __typename?: 'NumbersPayload' } & Pick<NumbersPayload, 'status'> & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'NumbersNode' } & Pick<
              NumbersNode,
              'id' | 'name' | 'number' | 'callStrategy'
            > & {
                agents?: Maybe<Array<Maybe<{ __typename?: 'Agent' } & AgentFieldsFragment>>>;
                numberCheckoutPrice?: Maybe<
                  { __typename?: 'NumberCheckoutPrice' } & Pick<
                    NumberCheckoutPrice,
                    'setUpFee' | 'monthlyFee' | 'basePrice'
                  >
                >;
              }
          >
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type NumberSettingsQueryVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type NumberSettingsQuery = { __typename?: 'Query' } & {
  numberSettings: { __typename?: 'ChannelSettingsPayload' } & Pick<
    ChannelSettingsPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ChannelSettingsNode' } & Pick<
          ChannelSettingsNode,
          | 'id'
          | 'name'
          | 'autoRecordCalls'
          | 'internationalCallAndMessages'
          | 'emailNotification'
          | 'incomingCallStrategy'
          | 'incomingCallForward'
          | 'unanswerCallsFallback'
          | 'externalNumber'
          | 'simultaneousDialing'
          | 'transcription'
          | 'shared'
        > & {
            agents?: Maybe<Array<Maybe<{ __typename?: 'Agent' } & AgentFieldsFragment>>>;
            abilities?: Maybe<{ __typename?: 'NumberAbilities' } & NumberAbilitiesFieldsFragment>;
          }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type IvrSettingsQueryVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type IvrSettingsQuery = { __typename?: 'Query' } & {
  ivrSettings?: Maybe<
    { __typename?: 'IvrSettingsPayload' } & Pick<IvrSettingsPayload, 'status'> & {
        data?: Maybe<
          { __typename?: 'IvrMessagePayload' } & Pick<
            IvrMessagePayload,
            'source' | 'voice' | 'accent' | 'recordingType' | 'recordingUrl'
          > & {
              ivrOptions?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'IvrOptionsPayload' } & Pick<
                      IvrOptionsPayload,
                      'extensionNumber' | 'forwardTo' | 'unansweredForwardTo'
                    >
                  >
                >
              >;
            }
        >;
        error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      }
  >;
};

export type CallerIdSettingsDataQueryVariables = Exact<{
  channel: Scalars['ShortId'];
}>;

export type CallerIdSettingsDataQuery = { __typename?: 'Query' } & {
  callerIdSettingsData: { __typename?: 'CallerIdDataPayload' } & Pick<
    CallerIdDataPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'CallerIdData' } & Pick<CallerIdData, 'status' | 'action' | 'callerId'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type VerifiedCallerIdsQueryVariables = Exact<{ [key: string]: never }>;

export type VerifiedCallerIdsQuery = { __typename?: 'Query' } & {
  verifiedCallerIds: { __typename?: 'CallerIdPayload' } & Pick<CallerIdPayload, 'status'> & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'VerifiedCallerIdData' } & Pick<VerifiedCallerIdData, 'id' | 'number'>
          >
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type UpdateCounversationCountSubscriptionVariables = Exact<{
  channels: Array<Scalars['ShortId']> | Scalars['ShortId'];
}>;

export type UpdateCounversationCountSubscription = { __typename?: 'Subscription' } & {
  updateCounversationCount: { __typename?: 'MessageCountSubscription' } & Pick<
    MessageCountSubscription,
    'event'
  > & {
      message: { __typename?: 'CountSubscriptionData' } & Pick<
        CountSubscriptionData,
        'channel' | 'count'
      >;
    };
};

export type MemberInfoFieldsFragment = { __typename?: 'MemberInfo' } & Pick<
  MemberInfo,
  'id' | 'firstname' | 'lastname' | 'picture'
>;

export type ChatFieldsFragment = { __typename?: 'ChatMessageNode' } & Pick<
  ChatMessageNode,
  'id' | 'message' | 'type' | 'createdOn' | 'modifiedOn' | 'status'
> & {
    sender?: Maybe<{ __typename?: 'MemberInfo' } & MemberInfoFieldsFragment>;
    receiver?: Maybe<{ __typename?: 'MemberInfo' } & MemberInfoFieldsFragment>;
  };

export type CreateChatMessageMutationVariables = Exact<{
  data: ChatMessageInput;
}>;

export type CreateChatMessageMutation = { __typename?: 'Mutation' } & {
  createChatMessage: { __typename?: 'ChatMessaagePayload' } & Pick<
    ChatMessaagePayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'ChatMessageNode' } & ChatFieldsFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type EditMemberChatSeenMutationVariables = Exact<{
  senderId: Scalars['ShortId'];
}>;

export type EditMemberChatSeenMutation = { __typename?: 'Mutation' } & {
  editMemberChatSeen: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
    };
};

export type ChatHistoryQueryVariables = Exact<{
  member: Scalars['ShortId'];
  params: ConnectionInput;
}>;

export type ChatHistoryQuery = { __typename?: 'Query' } & {
  chatHistory: { __typename?: 'ChatHistoryPayload' } & Pick<ChatHistoryPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ChatHisoryConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ChatHisoryEdge' } & Pick<ChatHisoryEdge, 'cursor'> & {
                    node?: Maybe<{ __typename?: 'ChatMessageNode' } & ChatFieldsFragment>;
                  }
              >
            >
          >;
        }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type WorkspaceChatSubscriptionVariables = Exact<{ [key: string]: never }>;

// export type WorkspaceChatSubscription = (
//   { __typename?: 'Subscription' }
//   & { workspaceChat: (
//     { __typename?: 'WorkspaceChatSubscription' }
//     & Pick<WorkspaceChatSubscription, 'event'>
//     & { message: (
//       { __typename?: 'ChatMessageNode' }
//       & ChatFieldsFragment
//     ) }
//   ) }
// );

export type ClientInfoFieldsFragment = { __typename?: 'ClientInfo' } & Pick<
  ClientInfo,
  'id' | 'number' | 'name' | 'country' | 'createdBy' | 'profilePicture'
>;

export type TagFieldsFragment = { __typename?: 'Tag' } & Pick<
  Tag,
  'id' | 'title' | 'colorCode' | 'backgroundColorCode' | 'count'
>;

export type ClientFieldsFragment = { __typename?: 'Client' } & Pick<
  Client,
  | 'id'
  | 'email'
  | 'name'
  | 'number'
  | 'address'
  | 'company'
  | 'visibility'
  | 'clientId'
  | 'country'
  | 'blocked'
  | 'profilePicture'
  | 'createdBy'
  | 'createdAt'
> & { tags?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>> };

export type TagResponseFieldsFragment = { __typename?: 'TagResponseData' } & Pick<
  TagResponseData,
  'id' | 'title' | 'colorCode' | 'backgroundColorCode'
>;

export type ContactFieldsFragment = { __typename?: 'ContactNode' } & Pick<
  ContactNode,
  | 'id'
  | 'email'
  | 'name'
  | 'createdOn'
  | 'number'
  | 'address'
  | 'company'
  | 'visibility'
  | 'clientId'
  | 'country'
  | 'profilePicture'
  | 'blocked'
  | 'createdBy'
  | 'dndEnabled'
  | 'dndDuration'
  | 'dndEndtime'
> & { tags?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>> };

export type AddContactMutationVariables = Exact<{
  data: ContactInputData;
}>;

export type AddContactMutation = { __typename?: 'Mutation' } & {
  addContact: { __typename?: 'ContactPayload' } & Pick<ContactPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'Client' } & Pick<
          Client,
          'id' | 'country' | 'company' | 'number' | 'address' | 'name' | 'visibility' | 'email'
        > & {
            tags?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>>;
            notes?: Maybe<Array<Maybe<{ __typename?: 'Note' } & Pick<Note, 'id' | 'title'>>>>;
          }
      >;
    };
};

export type EditContactMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: EditContactInputData;
}>;

export type EditContactMutation = { __typename?: 'Mutation' } & {
  editContact: { __typename?: 'EditContactPayload' } & Pick<EditContactPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'Client' } & Pick<
          Client,
          'id' | 'country' | 'name' | 'address' | 'company' | 'number' | 'visibility' | 'email'
        > & {
            tags?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>>;
            notes?: Maybe<Array<Maybe<{ __typename?: 'Note' } & Pick<Note, 'id' | 'title'>>>>;
          }
      >;
    };
};

export type AddNoteByContactMutationVariables = Exact<{
  contact: Scalars['String'];
  data: NoteInputData;
}>;

export type AddNoteByContactMutation = { __typename?: 'Mutation' } & {
  addNoteByContact: { __typename?: 'NoteWithClientPayload' } & Pick<
    NoteWithClientPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'NoteWithClientData' } & Pick<NoteWithClientData, 'id' | 'title'> & {
            client: { __typename?: 'Client' } & ClientFieldsFragment;
          }
      >;
    };
};

export type EditNoteMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: EditNoteInputData;
}>;

export type EditNoteMutation = { __typename?: 'Mutation' } & {
  editNote: { __typename?: 'NotePayload' } & Pick<NotePayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'NoteResponseData' } & Pick<NoteResponseData, 'id' | 'title'>>;
    };
};

export type RemoveNoteMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type RemoveNoteMutation = { __typename?: 'Mutation' } & {
  removeNote: { __typename?: 'UpdateNotePayload' } & Pick<UpdateNotePayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'UpdateNoteResponseData' } & Pick<UpdateNoteResponseData, 'id' | 'title'>
      >;
    };
};

export type AddTagMutationVariables = Exact<{
  data: TagInputData;
}>;

export type AddTagMutation = { __typename?: 'Mutation' } & {
  addTag: { __typename?: 'TagPayload' } & Pick<TagPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'TagResponseData' } & TagResponseFieldsFragment>;
    };
};

export type RemoveTagMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type RemoveTagMutation = { __typename?: 'Mutation' } & {
  removeTag: { __typename?: 'TagPayload' } & Pick<TagPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'TagResponseData' } & Pick<TagResponseData, 'id'>>;
    };
};

export type RemoveContactTagMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: RemoveContactTagInputData;
}>;

export type RemoveContactTagMutation = { __typename?: 'Mutation' } & {
  removeContactTag: { __typename?: 'ClientTagPayload' } & Pick<ClientTagPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Client' } & Pick<Client, 'id'>>;
    };
};

export type EditTagMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: EditTagInputData;
}>;

export type EditTagMutation = { __typename?: 'Mutation' } & {
  editTag: { __typename?: 'TagPayload' } & Pick<TagPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'TagResponseData' } & Pick<TagResponseData, 'id'>>;
    };
};

export type BlockNumberMutationVariables = Exact<{
  number: Scalars['String'];
  data: BlockNumberInputData;
}>;

export type BlockNumberMutation = { __typename?: 'Mutation' } & {
  blockNumber: { __typename?: 'BlockContactPayload' } & Pick<BlockContactPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'ContactNode' } & Pick<ContactNode, 'id' | 'blocked'>>;
    };
};

export type BlockContactMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: BlockContactInputData;
}>;

export type BlockContactMutation = { __typename?: 'Mutation' } & {
  blockContact: { __typename?: 'BlockContactPayload' } & Pick<BlockContactPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'ContactNode' } & Pick<ContactNode, 'id' | 'blocked'>>;
    };
};

export type BlockContactsMutationVariables = Exact<{
  data: BlockContactsInputData;
}>;

export type BlockContactsMutation = { __typename?: 'Mutation' } & {
  blockContacts: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
    };
};

export type DeleteContactsMutationVariables = Exact<{
  data: DeleteContactsInputData;
}>;

export type DeleteContactsMutation = { __typename?: 'Mutation' } & {
  deleteContacts: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
    };
};

export type UploadContactMutationVariables = Exact<{
  data: ContactCsvInputData;
}>;

export type UploadContactMutation = { __typename?: 'Mutation' } & {
  uploadContact: { __typename?: 'UploadContactPayload' } & Pick<UploadContactPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'UploadContactData' } & Pick<
          UploadContactData,
          'totalRecords' | 'savedRecords'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type UpdateClientDndMutationVariables = Exact<{
  data: ClientDndInput;
}>;

export type UpdateClientDndMutation = { __typename?: 'Mutation' } & {
  updateClientDND: { __typename?: 'ClientDNDPayload' } & Pick<ClientDndPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'ContactNode' } & ContactFieldsFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type GreetingsMutationVariables = Exact<{
  channel: Scalars['ShortId'];
  data: GreetingInputData;
}>;

export type GreetingsMutation = { __typename?: 'Mutation' } & {
  greetings: { __typename?: 'GreetingPayload' } & Pick<GreetingPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ChannelGreetingInfo' } & Pick<
          ChannelGreetingInfo,
          'id' | 'recordingUrl' | 'greetingType' | 'recordingType' | 'source' | 'status'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type GreetingsToggleMutationVariables = Exact<{
  channel: Scalars['ShortId'];
  data: GreetingToggleInputData;
}>;

export type GreetingsToggleMutation = { __typename?: 'Mutation' } & {
  greetingsToggle: { __typename?: 'GreetingTogglePayload' } & Pick<
    GreetingTogglePayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'GreetingToggleInfo' } & Pick<
          GreetingToggleInfo,
          'greetingType' | 'recordingType'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AutoReplyMutationVariables = Exact<{
  data: AutoReplyInputData;
}>;

export type AutoReplyMutation = { __typename?: 'Mutation' } & {
  autoReply: { __typename?: 'AutoReplyPayload' } & Pick<AutoReplyPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'AutoReply' } & Pick<
          AutoReply,
          'id' | 'channelId' | 'createdBy' | 'message' | 'status' | 'autoreplyType'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type NewContactsQueryVariables = Exact<{
  tags?: Maybe<Array<Maybe<Scalars['ShortId']>> | Maybe<Scalars['ShortId']>>;
}>;

export type NewContactsQuery = { __typename?: 'Query' } & {
  newContacts: { __typename?: 'AllNewContactPayload' } & Pick<AllNewContactPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Client' } & Pick<
              Client,
              'id' | 'email' | 'name' | 'profilePicture' | 'createdBy'
            > & { tags?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>> }
          >
        >
      >;
    };
};

export type BlockedContactsQueryVariables = Exact<{ [key: string]: never }>;

export type BlockedContactsQuery = { __typename?: 'Query' } & {
  blockedContacts: { __typename?: 'AllBlockedContactPayload' } & Pick<
    AllBlockedContactPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Client' } & Pick<
              Client,
              'id' | 'email' | 'name' | 'number' | 'profilePicture' | 'createdBy'
            >
          >
        >
      >;
    };
};

export type ContactsQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
  tags?: Maybe<Array<Maybe<Scalars['ShortId']>> | Maybe<Scalars['ShortId']>>;
}>;

export type ContactsQuery = { __typename?: 'Query' } & {
  contacts: { __typename?: 'ContactAllPayload' } & Pick<ContactAllPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ContactConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ContactEdge' } & Pick<ContactEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'ContactNode' } & Pick<
                        ContactNode,
                        'id' | 'email' | 'name' | 'profilePicture' | 'number'
                      > & { tags?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>> }
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type ContactQueryVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type ContactQuery = { __typename?: 'Query' } & {
  contact: { __typename?: 'ContactDetailPayload' } & Pick<ContactDetailPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'ContactNode' } & ContactFieldsFragment>;
    };
};

export type ClientDetailQueryVariables = Exact<{
  number: Scalars['String'];
}>;

export type ClientDetailQuery = { __typename?: 'Query' } & {
  clientDetail: { __typename?: 'EditContactPayload' } & Pick<EditContactPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Client' } & ClientFieldsFragment>;
    };
};

export type ClientNotesQueryVariables = Exact<{
  contact: Scalars['String'];
  channel: Scalars['ShortId'];
}>;

export type ClientNotesQuery = { __typename?: 'Query' } & {
  clientNotes: { __typename?: 'NotesPayload' } & Pick<NotesPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Note' } & Pick<
              Note,
              'id' | 'title' | 'createdAt' | 'userId' | 'firstName' | 'lastName' | 'profilePicture'
            >
          >
        >
      >;
    };
};

export type TagsQueryVariables = Exact<{ [key: string]: never }>;

export type TagsQuery = { __typename?: 'Query' } & {
  tags: { __typename?: 'TagsPayload' } & Pick<TagsPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>>;
    };
};

export type AllTagsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTagsQuery = { __typename?: 'Query' } & {
  allTags: { __typename?: 'TagsPayload' } & Pick<TagsPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<Array<Maybe<{ __typename?: 'Tag' } & TagFieldsFragment>>>;
    };
};

export type AutoReplyDataQueryVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type AutoReplyDataQuery = { __typename?: 'Query' } & {
  autoReplyData: { __typename?: 'AutoReplyDataPayload' } & Pick<AutoReplyDataPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'AutoReplyData' } & Pick<
              AutoReplyData,
              'status' | 'message' | 'channelId' | 'autoreplyType'
            >
          >
        >
      >;
    };
};

export type GreetingsListQueryVariables = Exact<{
  channel: Scalars['ShortId'];
  greetingType?: Maybe<GreetingType>;
}>;

export type GreetingsListQuery = { __typename?: 'Query' } & {
  greetings: { __typename?: 'GreetingListPayload' } & Pick<GreetingListPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ChannelGreetingInfo' } & Pick<
              ChannelGreetingInfo,
              | 'id'
              | 'recordingUrl'
              | 'greetingType'
              | 'recordingType'
              | 'source'
              | 'voice'
              | 'accent'
              | 'status'
            >
          >
        >
      >;
    };
};

export type LastContactedTimeQueryVariables = Exact<{
  contact: Scalars['String'];
}>;

export type LastContactedTimeQuery = { __typename?: 'Query' } & {
  lastContactedTime: { __typename?: 'LastContactedDataPayload' } & Pick<
    LastContactedDataPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'LastContactData' } & Pick<LastContactData, 'createdAt'>>;
    };
};

export type GetContactsLogsQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
}>;

export type GetContactsLogsQuery = { __typename?: 'Query' } & {
  getContactsLogs: { __typename?: 'ContactsLogsPayload' } & Pick<ContactsLogsPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ConversationConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ConversationEdge' } & Pick<ConversationEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'ConversationNode' } & Pick<
                        ConversationNode,
                        'clientNumber'
                      > & {
                          clientInfo?: Maybe<
                            { __typename?: 'ClientInfo' } & ClientInfoFieldsFragment
                          >;
                        }
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type WorkspaceFieldsFragment = { __typename?: 'Workspace' } & Pick<
  Workspace,
  'id' | 'title' | 'photo' | 'notification' | 'status'
> & {
    channels?: Maybe<Array<Maybe<{ __typename?: 'Channel' } & ChannelFieldsFragment>>>;
    plan?: Maybe<
      { __typename?: 'PlanOverviewData' } & Pick<
        PlanOverviewData,
        'remainingDays' | 'subscriptionActive' | 'currentCredit'
      >
    >;
  };

export type MemberNodeFieldsFragment = { __typename?: 'MemberNode' } & Pick<
  MemberNode,
  | 'id'
  | 'firstname'
  | 'lastname'
  | 'role'
  | 'gender'
  | 'email'
  | 'createdOn'
  | 'profilePicture'
  | 'unSeenMsgCount'
  | 'online'
  | 'onCall'
> & {
    numbers?: Maybe<
      Array<
        Maybe<
          { __typename?: 'Channel' } & Pick<
            Channel,
            'name' | 'number' | 'country' | 'countryLogo' | 'countryCode'
          > & {
              settings?: Maybe<
                { __typename?: 'ChannelSettings' } & Pick<ChannelSettings, 'shared'>
              >;
            }
        >
      >
    >;
    planRate?: Maybe<{ __typename?: 'PlanRate' } & Pick<PlanRate, 'planRate'>>;
    last_message?: Maybe<
      { __typename?: 'ChatMessageNode' } & Pick<ChatMessageNode, 'createdOn' | 'message'>
    >;
  };

export type InviteNodeFieldsFragment = { __typename?: 'InviteNode' } & Pick<
  InviteNode,
  'id' | 'role' | 'code' | 'email' | 'createdOn' | 'type' | 'status'
>;

export type AddWorkspaceMutationVariables = Exact<{
  data: WorkspaceInputData;
}>;

export type AddWorkspaceMutation = { __typename?: 'Mutation' } & {
  addWorkspace: { __typename?: 'WorkspacePayload' } & Pick<WorkspacePayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'WorkspaceResponseData' } & Pick<WorkspaceResponseData, 'id' | 'memberId'>
      >;
    };
};

export type WorkspaceSubscriptionMutationVariables = Exact<{
  data: WorkspaceSubscriptionInputData;
}>;

export type WorkspaceSubscriptionMutation = { __typename?: 'Mutation' } & {
  workspaceSubscription: { __typename?: 'WorkspaceSubscriptionPayload' } & Pick<
    WorkspaceSubscriptionPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'WorkspaceSubscriptionResponseData' } & Pick<
          WorkspaceSubscriptionResponseData,
          'workspaceId' | 'memberId'
        >
      >;
    };
};

export type EditWorkspaceMutationVariables = Exact<{
  data: EditWorkspaceInputData;
}>;

export type EditWorkspaceMutation = { __typename?: 'Mutation' } & {
  editWorkspace: { __typename?: 'EditWorkspacePayload' } & Pick<EditWorkspacePayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'EditWorkspaceResponseData' } & Pick<
          EditWorkspaceResponseData,
          'id' | 'title'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type WorkspaceOrderMutationVariables = Exact<{
  data: WorkspaceOrderInputData;
}>;

export type WorkspaceOrderMutation = { __typename?: 'Mutation' } & {
  workspaceOrder: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type EditNotificationMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: EditNotificationInputData;
}>;

export type EditNotificationMutation = { __typename?: 'Mutation' } & {
  editNotification: { __typename?: 'EditMemberPayload' } & Pick<EditMemberPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'MemberNode' } & Pick<MemberNode, 'id' | 'notification'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RemoveWorkspaceMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type RemoveWorkspaceMutation = { __typename?: 'Mutation' } & {
  removeWorkspace: { __typename?: 'WorkspaceRemovePayload' } & Pick<
    WorkspaceRemovePayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Workspace' } & Pick<Workspace, 'id'>>;
    };
};

export type InviteMemberMutationVariables = Exact<{
  data: InviteMemberInputData;
}>;

export type InviteMemberMutation = { __typename?: 'Mutation' } & {
  inviteMember: { __typename?: 'MessageOnlyPayload' } & Pick<MessageOnlyPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Message' } & Pick<Message, 'message'>>;
    };
};

export type ResendEmailVerificationLinkMutationVariables = Exact<{
  data: EmailVerificationInputData;
}>;

export type ResendEmailVerificationLinkMutation = { __typename?: 'Mutation' } & {
  resendEmailVerificationLink: { __typename?: 'EmailVerificationPayload' } & Pick<
    EmailVerificationPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'SuccessPayloadData' } & Pick<SuccessPayloadData, 'success'>>;
    };
};

export type AcceptInviteMutationVariables = Exact<{
  data: InviteAcceptInput;
}>;

export type AcceptInviteMutation = { __typename?: 'Mutation' } & {
  acceptInvite: { __typename?: 'InviteAcceptPayload' } & {
    data?: Maybe<
      { __typename?: 'InviteAcceptResponseData' } & Pick<
        InviteAcceptResponseData,
        'authToken' | 'accessToken' | 'refreshToken' | 'workspace'
      >
    >;
    error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
  };
};

export type AcceptUserInviteMutationVariables = Exact<{
  data: UserInviteAcceptInput;
}>;

export type AcceptUserInviteMutation = { __typename?: 'Mutation' } & {
  acceptUserInvite: { __typename?: 'UserInviteAcceptPayload' } & {
    data?: Maybe<
      { __typename?: 'UserInviteAcceptData' } & Pick<
        UserInviteAcceptData,
        'accessToken' | 'refreshToken' | 'workspace'
      >
    >;
    error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
  };
};

export type ChangeDefaultLanguageMutationVariables = Exact<{
  data: ChangeDefaultLanguage;
}>;

export type ChangeDefaultLanguageMutation = { __typename?: 'Mutation' } & {
  changeDefaultLanguage: { __typename?: 'UpdateProfileSucceedPayload' } & Pick<
    UpdateProfileSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'UserProfile' } & Pick<
          UserProfile,
          'defaultLanguage' | 'defaultWorkspace' | 'email'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangeDefaultTimezoneMutationVariables = Exact<{
  data: ChangeDefaultTimezone;
}>;

export type ChangeDefaultTimezoneMutation = { __typename?: 'Mutation' } & {
  changeDefaultTimezone: { __typename?: 'UpdateProfileSucceedPayload' } & Pick<
    UpdateProfileSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'UserProfile' } & Pick<
          UserProfile,
          'defaultLanguage' | 'defaultTimezone' | 'defaultWorkspace' | 'email'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type EditMemberStatusMutationVariables = Exact<{
  uid: Scalars['ShortId'];
  data: EditMemberStatusInputData;
}>;

export type EditMemberStatusMutation = { __typename?: 'Mutation' } & {
  editMemberStatus: { __typename?: 'EditMemberPayload' } & Pick<EditMemberPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'MemberNode' } & Pick<MemberNode, 'id'>>;
    };
};

export type EditMemberRoleMutationVariables = Exact<{
  uid: Scalars['ShortId'];
  data: EditMemberRoleInputData;
}>;

export type EditMemberRoleMutation = { __typename?: 'Mutation' } & {
  editMemberRole: { __typename?: 'EditMemberPayload' } & Pick<EditMemberPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'MemberNode' } & Pick<MemberNode, 'id' | 'role'>>;
    };
};

export type ResendInviteMutationVariables = Exact<{
  data: InviteCode;
}>;

export type ResendInviteMutation = { __typename?: 'Mutation' } & {
  resendInvite: { __typename?: 'InviteResendPayload' } & {
    data?: Maybe<{ __typename?: 'Message' } & Pick<Message, 'message'>>;
    error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
  };
};

export type DeleteInvitationMutationVariables = Exact<{
  link: Scalars['String'];
}>;

export type DeleteInvitationMutation = { __typename?: 'Mutation' } & {
  deleteInvitation: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
    };
};

export type ChangeWorkspacePhotoMutationVariables = Exact<{
  photoUpload: Scalars['Upload'];
}>;

export type ChangeWorkspacePhotoMutation = { __typename?: 'Mutation' } & {
  changeWorkspacePicture: { __typename?: 'EditWorkspacePhotoPayload' } & Pick<
    EditWorkspacePhotoPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'EditWorkspacePhotoResponseData' } & Pick<
          EditWorkspacePhotoResponseData,
          'id' | 'title' | 'photo'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RestoreWorkspaceMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type RestoreWorkspaceMutation = { __typename?: 'Mutation' } & {
  restoreWorkspace: { __typename?: 'WorkspaceRestorePayload' } & Pick<
    WorkspaceRestorePayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Workspace' } & Pick<Workspace, 'id' | 'title'>>;
    };
};

export type WorkspaceQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspaceQuery = { __typename?: 'Query' } & {
  workspace: { __typename?: 'WorkspaceDetailPayload' } & Pick<WorkspaceDetailPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Workspace' } & WorkspaceFieldsFragment>;
    };
};

export type WorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspacesQuery = { __typename?: 'Query' } & {
  workspaces: { __typename?: 'WorkspaceListPayload' } & Pick<WorkspaceListPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Workspace' } & Pick<
              Workspace,
              'id' | 'title' | 'memberId' | 'photo' | 'status' | 'order'
            > & {
                plan?: Maybe<
                  { __typename?: 'PlanOverviewData' } & Pick<
                    PlanOverviewData,
                    'remainingDays' | 'subscriptionActive' | 'currentCredit'
                  >
                >;
              }
          >
        >
      >;
    };
};

export type InviteMemberEmailExistsQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type InviteMemberEmailExistsQuery = { __typename?: 'Query' } & {
  inviteMemberEmailExists: { __typename?: 'CheckEmailPayload' } & Pick<
    CheckEmailPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'SuccessPayloadData' } & Pick<SuccessPayloadData, 'success'>>;
    };
};

export type InviteDetailQueryVariables = Exact<{
  invitationCode: Scalars['String'];
}>;

export type InviteDetailQuery = { __typename?: 'Query' } & {
  inviteDetail: { __typename?: 'InviteDetailPayload' } & Pick<InviteDetailPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'InviteDetailData' } & Pick<InviteDetailData, 'email' | 'invitationType'> & {
            workspace?: Maybe<
              { __typename?: 'WorkspaceDetail' } & Pick<
                WorkspaceDetail,
                'id' | 'name' | 'image' | 'role'
              >
            >;
          }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AllWorkspaceMembersQueryVariables = Exact<{
  pageParams: ConnectionInput;
}>;

export type AllWorkspaceMembersQuery = { __typename?: 'Query' } & {
  allWorkspaceMembers: { __typename?: 'MembersPayload' } & Pick<MembersPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'MemberConnection' } & {
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'MemberEdge' } & Pick<MemberEdge, 'cursor'> & {
                    node?: Maybe<{ __typename?: 'MemberNode' } & MemberNodeFieldsFragment>;
                  }
              >
            >
          >;
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
        }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AllInvitesQueryVariables = Exact<{
  pageParams?: Maybe<ConnectionInput>;
}>;

export type AllInvitesQuery = { __typename?: 'Query' } & {
  allInvites: { __typename?: 'InvitesPayload' } & Pick<InvitesPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'InviteConnection' } & {
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'InviteEdge' } & Pick<InviteEdge, 'cursor'> & {
                    node?: Maybe<{ __typename?: 'InviteNode' } & InviteNodeFieldsFragment>;
                  }
              >
            >
          >;
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
        }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AllTimeZonesQueryVariables = Exact<{ [key: string]: never }>;

export type AllTimeZonesQuery = { __typename?: 'Query' } & {
  allTimeZones?: Maybe<
    { __typename?: 'AllTimeZonesPayload' } & Pick<AllTimeZonesPayload, 'status'> & {
        error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
        data?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TimeZone' } & Pick<
                TimeZone,
                'value' | 'abbr' | 'offset' | 'isdst' | 'text' | 'utc'
              >
            >
          >
        >;
      }
  >;
};

export type ErrorFragment = { __typename?: 'ErrorData' } & Pick<
  ErrorData,
  'code' | 'message' | 'errorKey'
>;

export type PageInfoFragment = { __typename?: 'PageInfo' } & Pick<
  PageInfo,
  'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
>;

export type ConfigureGoogleContactMutationVariables = Exact<{ [key: string]: never }>;

export type ConfigureGoogleContactMutation = { __typename?: 'Mutation' } & {
  configureGoogleContact: { __typename?: 'GoogleOauthRedirectPayload' } & Pick<
    GoogleOauthRedirectPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'GoogleOauthRedirect' } & Pick<GoogleOauthRedirect, 'auth_url'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type IntegrateGoogleContactMutationVariables = Exact<{
  data: GoogleOauthResponse;
}>;

export type IntegrateGoogleContactMutation = { __typename?: 'Mutation' } & {
  integrateGoogleContact: { __typename?: 'UploadContactPayload' } & Pick<
    UploadContactPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'UploadContactData' } & Pick<
          UploadContactData,
          'totalRecords' | 'savedRecords'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type DeleteGoogleContactIntegrationMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type DeleteGoogleContactIntegrationMutation = { __typename?: 'Mutation' } & {
  deleteGoogleContactIntegration: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ResyncGoogleContactIntegrationMutationVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type ResyncGoogleContactIntegrationMutation = { __typename?: 'Mutation' } & {
  resyncGoogleContactIntegration: { __typename?: 'UploadContactPayload' } & Pick<
    UploadContactPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'UploadContactData' } & Pick<
          UploadContactData,
          'totalRecords' | 'savedRecords'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type GoogleContactIntegrationListQueryVariables = Exact<{ [key: string]: never }>;

export type GoogleContactIntegrationListQuery = { __typename?: 'Query' } & {
  googleContactIntegrationList: { __typename?: 'GoogleContactIntegrationListPayload' } & Pick<
    GoogleContactIntegrationListPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'GoogleContactIntegration' } & Pick<
              GoogleContactIntegration,
              'id' | 'email' | 'totalContactImported'
            >
          >
        >
      >;
    };
};

export type AddplanRestrictionDataMutationVariables = Exact<{
  data: PlanRestrictionInputData;
}>;

export type AddplanRestrictionDataMutation = { __typename?: 'Mutation' } & {
  addPlanRestriction: { __typename?: 'PlanRestrictionDataPayload' } & Pick<
    PlanRestrictionDataPayload,
    'status' | 'data'
  > & { error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment> };
};

export type PlanRestrictionDataQueryVariables = Exact<{ [key: string]: never }>;

export type PlanRestrictionDataQuery = { __typename?: 'Query' } & {
  planRestrictionData: { __typename?: 'PlanRestrictionDataPayload' } & Pick<
    PlanRestrictionDataPayload,
    'status' | 'data'
  > & { error?: Maybe<{ __typename?: 'ErrorData' } & Pick<ErrorData, 'code' | 'message'>> };
};

export type NotificationFieldsFragment = { __typename?: 'DeviceRegisterData' } & Pick<
  DeviceRegisterData,
  'id' | 'callMessages' | 'newLeads' | 'flashTaskbar'
>;

export type UpdateNotificationSettingsMutationVariables = Exact<{
  data: UpdateNotificationsInputData;
}>;

export type UpdateNotificationSettingsMutation = { __typename?: 'Mutation' } & {
  updateNotificationSettings: { __typename?: 'DeviceRegisterPayload' } & Pick<
    DeviceRegisterPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'DeviceRegisterData' } & NotificationFieldsFragment>;
    };
};

export type NotificationSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationSettingsQuery = { __typename?: 'Query' } & {
  notificationSettings: { __typename?: 'DevicesPayload' } & Pick<DevicesPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'DeviceRegisterData' } & NotificationFieldsFragment>;
    };
};

export type TeamMembersNodeFragment = { __typename?: 'TeamMembers' } & Pick<
  TeamMembers,
  'id' | 'firstname' | 'lastname' | 'online' | 'profilePicture' | 'role' | 'email'
>;

export type TeamFieldsFragment = { __typename?: 'Team' } & Pick<
  Team,
  'id' | 'title' | 'description' | 'picture' | 'total' | 'online' | 'avatar'
> & { teamMembers?: Maybe<Array<Maybe<{ __typename?: 'TeamMembers' } & TeamMembersNodeFragment>>> };

export type MemberFieldsFragment = { __typename?: 'MemberDetails' } & Pick<
  MemberDetails,
  'id' | 'email' | 'firstname' | 'lastname' | 'photo' | 'role' | 'lastonline' | 'online'
>;

export type TeamMemberFieldsFragment = { __typename?: 'TeamMemberNode' } & Pick<
  TeamMemberNode,
  'id' | 'email' | 'firstname' | 'lastname' | 'photo' | 'role' | 'createdOn' | 'online'
>;

export type MemberDetailQueryVariables = Exact<{
  id: Scalars['ShortId'];
}>;

export type MemberDetailQuery = { __typename?: 'Query' } & {
  memberDetail: { __typename?: 'MemberDetailsPayload' } & Pick<MemberDetailsPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'MemberDetails' } & MemberFieldsFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type TeamMembersListQueryVariables = Exact<{
  id: Scalars['ShortId'];
  params?: Maybe<ConnectionInput>;
}>;

export type TeamMembersListQuery = { __typename?: 'Query' } & {
  teamMembersList: { __typename?: 'TeamMembersPayload' } & Pick<TeamMembersPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'TeamMemberConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'TeamMemberEdge' } & Pick<TeamMemberEdge, 'cursor'> & {
                    node?: Maybe<{ __typename?: 'TeamMemberNode' } & TeamMemberFieldsFragment>;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type OnlineMemberStatusSubscriptionVariables = Exact<{
  workspace: Scalars['ShortId'];
}>;

export type OnlineMemberStatusSubscription = { __typename?: 'Subscription' } & {
  onlineMemberStatus: { __typename?: 'MemberStatusSubscription' } & Pick<
    MemberStatusSubscription,
    'event'
  > & { message?: Maybe<{ __typename?: 'Member' } & Pick<Member, 'id' | 'online'>> };
};

export type AddTeamMutationVariables = Exact<{
  data: TeamInputData;
}>;

export type AddTeamMutation = { __typename?: 'Mutation' } & {
  addTeam: { __typename?: 'TeamPayload' } & Pick<TeamPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'Team' } & TeamFieldsFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type UpdateTeamMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: UpdateTeamInputData;
}>;

export type UpdateTeamMutation = { __typename?: 'Mutation' } & {
  updateTeam: { __typename?: 'UpdateTeamPayload' } & Pick<UpdateTeamPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'Team' } & TeamFieldsFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RemoveTeamMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: RemoveTeamInputData;
}>;

export type RemoveTeamMutation = { __typename?: 'Mutation' } & {
  removeTeam: { __typename?: 'UpdateTeamPayload' } & Pick<UpdateTeamPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Team' } & Pick<Team, 'id'>>;
    };
};

export type RemoveMemberMutationVariables = Exact<{
  id: Scalars['ShortId'];
  data: RemoveTeammemberInputData;
}>;

export type RemoveMemberMutation = { __typename?: 'Mutation' } & {
  removeMember: { __typename?: 'UpdateTeamPayload' } & Pick<UpdateTeamPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'Team' } & Pick<Team, 'id'> & {
            teamMembers?: Maybe<
              Array<Maybe<{ __typename?: 'TeamMembers' } & TeamMembersNodeFragment>>
            >;
          }
      >;
    };
};

export type TeamsQueryVariables = Exact<{ [key: string]: never }>;

export type TeamsQuery = { __typename?: 'Query' } & {
  teams: { __typename?: 'TeamsDetailPayLoad' } & Pick<TeamsDetailPayLoad, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<Array<Maybe<{ __typename?: 'Team' } & TeamFieldsFragment>>>;
    };
};

export type TeamQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type TeamQuery = { __typename?: 'Query' } & {
  team: { __typename?: 'TeamDetailPayLoad' } & Pick<TeamDetailPayLoad, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Team' } & TeamFieldsFragment>;
    };
};

export type AddSetupIntentMutationVariables = Exact<{ [key: string]: never }>;

export type AddSetupIntentMutation = { __typename?: 'Mutation' } & {
  addSetupIntent: { __typename?: 'SetupIntentPayload' } & Pick<SetupIntentPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'IntentResponseData' } & Pick<IntentResponseData, 'clientSecret'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CreatePaymentIntentMutationVariables = Exact<{
  data: PaymentIntentInputData;
}>;

export type CreatePaymentIntentMutation = { __typename?: 'Mutation' } & {
  createPaymentIntent: { __typename?: 'PaymentIntentPayload' } & Pick<
    PaymentIntentPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'IntentResponseData' } & Pick<IntentResponseData, 'clientSecret'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RemoveCardMutationVariables = Exact<{
  data: RemoveCardInputData;
}>;

export type RemoveCardMutation = { __typename?: 'Mutation' } & {
  removeCard: { __typename?: 'RemoveCardPayload' } & Pick<RemoveCardPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'RemoveCardResponseData' } & Pick<RemoveCardResponseData, 'id'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type MakePrimaryCardMutationVariables = Exact<{
  data: MakePrimaryCardInputData;
}>;

export type MakePrimaryCardMutation = { __typename?: 'Mutation' } & {
  makePrimaryCard: { __typename?: 'MakePrimaryCardPayload' } & Pick<
    MakePrimaryCardPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'MakePrimaryCardResponseData' } & Pick<MakePrimaryCardResponseData, 'id'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AddDefaultCardMutationVariables = Exact<{
  data: DefaultCardInputData;
}>;

export type AddDefaultCardMutation = { __typename?: 'Mutation' } & {
  addDefaultCard: { __typename?: 'SetupIntentPayload' } & Pick<SetupIntentPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'IntentResponseData' } & Pick<IntentResponseData, 'clientSecret'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CardExistMutationVariables = Exact<{
  data: CardExistInputData;
}>;

export type CardExistMutation = { __typename?: 'Mutation' } & {
  cardExist: { __typename?: 'cardExistPayload' } & Pick<CardExistPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'cardExistResponseData' } & Pick<CardExistResponseData, 'cardInfo'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type StripePublicKeyQueryVariables = Exact<{ [key: string]: never }>;

export type StripePublicKeyQuery = { __typename?: 'Query' } & {
  stripePublicKey: { __typename?: 'StripePublicKeyPayload' } & Pick<
    StripePublicKeyPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Settings' } & Pick<Settings, 'publicKey'>>;
    };
};

export type CardsQueryVariables = Exact<{ [key: string]: never }>;

export type CardsQuery = { __typename?: 'Query' } & {
  cards: { __typename?: 'CardsPayload' } & Pick<CardsPayload, 'status'> & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CardResponseData' } & Pick<
              CardResponseData,
              'id' | 'name' | 'expiryMonth' | 'expiryYear' | 'lastDigit' | 'brand'
            >
          >
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type CallRatingsSupportDataQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
}>;

export type CallRatingsSupportDataQuery = { __typename?: 'Query' } & {
  callRatingsSupportData: { __typename?: 'CallRatingSupportPayload' } & Pick<
    CallRatingSupportPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'CallRatingSupportConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'CallRatingSupportEdge' } & Pick<CallRatingSupportEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'CallRatingSupportNode' } & Pick<
                        CallRatingSupportNode,
                        | 'date'
                        | 'number'
                        | 'contactedNumber'
                        | 'workspaceId'
                        | 'workspaceName'
                        | 'firstName'
                        | 'lastName'
                        | 'ratings'
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type ChangeEnterpriseSupportTicketStatusMutationVariables = Exact<{
  data: EnterpriseSupportInputData;
}>;

export type ChangeEnterpriseSupportTicketStatusMutation = { __typename?: 'Mutation' } & {
  changeEnterpriseSupportTicketStatus: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SetupPlanRateMutationVariables = Exact<{
  data: SetupPlanRateInputData;
}>;

export type SetupPlanRateMutation = { __typename?: 'Mutation' } & {
  setupPlanRate: { __typename?: 'SetupPlanRatePayload' } & Pick<SetupPlanRatePayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'SetupPlanRateResponseData' } & Pick<SetupPlanRateResponseData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type EnterpriseSupportDataQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
}>;

export type EnterpriseSupportDataQuery = { __typename?: 'Query' } & {
  enterpriseSupportData: { __typename?: 'EnterpriseSupportPayload' } & Pick<
    EnterpriseSupportPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'EnterpriseSupportConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'EnterpriseSupportEdge' } & Pick<EnterpriseSupportEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'EnterpriseSupportNode' } & Pick<
                        EnterpriseSupportNode,
                        | 'ticketNumber'
                        | 'workspaceId'
                        | 'workspaceName'
                        | 'firstName'
                        | 'lastName'
                        | 'plan'
                        | 'ticketStatus'
                        | 'rate'
                        | 'issueDate'
                        | 'closeDate'
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type ChangePortingSupportTicketStatusMutationVariables = Exact<{
  data: PortingSupportTicketStatusInputData;
}>;

export type ChangePortingSupportTicketStatusMutation = { __typename?: 'Mutation' } & {
  changePortingSupportTicketStatus: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangePortingSupportNumberStatusMutationVariables = Exact<{
  data: PortingSupportNumberStatusInputData;
}>;

export type ChangePortingSupportNumberStatusMutation = { __typename?: 'Mutation' } & {
  changePortingSupportNumberStatus: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type PortingSupportDataQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
}>;

export type PortingSupportDataQuery = { __typename?: 'Query' } & {
  portingSupportData: { __typename?: 'PortingSupportPayload' } & Pick<
    PortingSupportPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'PortingSupportConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'PortingSupportEdge' } & Pick<PortingSupportEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'PortingSupportNode' } & Pick<
                        PortingSupportNode,
                        | 'ticketNumber'
                        | 'number'
                        | 'workspaceId'
                        | 'workspaceName'
                        | 'firstName'
                        | 'lastName'
                        | 'plan'
                        | 'ticketStatus'
                        | 'portingNumberStatus'
                        | 'issueDate'
                        | 'closeDate'
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type ChangePurchaseSupportTicketStatusMutationVariables = Exact<{
  data: PurchaseSupportTicketStatusInputData;
}>;

export type ChangePurchaseSupportTicketStatusMutation = { __typename?: 'Mutation' } & {
  changePurchaseSupportTicketStatus: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangePurchaseSupportNumberStatusMutationVariables = Exact<{
  data: PurchaseSupportNumberStatusInputData;
}>;

export type ChangePurchaseSupportNumberStatusMutation = { __typename?: 'Mutation' } & {
  changePurchaseSupportNumberStatus: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type PurchaseSupportDataQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
}>;

export type PurchaseSupportDataQuery = { __typename?: 'Query' } & {
  purchaseSupportData: { __typename?: 'PurchaseSupportPayload' } & Pick<
    PurchaseSupportPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'PurchaseSupportConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'PurchaseSupportEdge' } & Pick<PurchaseSupportEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'PurchaseSupportNode' } & Pick<
                        PurchaseSupportNode,
                        | 'ticketNumber'
                        | 'number'
                        | 'workspaceId'
                        | 'workspaceName'
                        | 'firstName'
                        | 'lastName'
                        | 'plan'
                        | 'ticketStatus'
                        | 'purchaseNumberStatus'
                        | 'issueDate'
                        | 'closeDate'
                      >
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type ToggleUserActivationMutationVariables = Exact<{
  data: UserActivationToggle;
}>;

export type ToggleUserActivationMutation = { __typename?: 'Mutation' } & {
  toggleUserActivation: { __typename?: 'UpdateProfileSucceedPayload' } & Pick<
    UpdateProfileSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'UserProfile' } & Pick<
          UserProfile,
          'userId' | 'firstname' | 'lastname' | 'email' | 'status'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AllUsersQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
}>;

export type AllUsersQuery = { __typename?: 'Query' } & {
  allUsers: { __typename?: 'UserListPaginated' } & Pick<UserListPaginated, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'UserListConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'UserListEdge' } & Pick<UserListEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'UserList' } & Pick<
                        UserList,
                        'authId' | 'firstname' | 'lastname' | 'email' | 'profilePicture' | 'status'
                      > & {
                          workspaces?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'Workspace' } & Pick<
                                  Workspace,
                                  'id' | 'title' | 'photo' | 'status'
                                >
                              >
                            >
                          >;
                        }
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type ArchiveWorkspaceMutationVariables = Exact<{
  data: ArchiveWorkspacesInputData;
}>;

export type ArchiveWorkspaceMutation = { __typename?: 'Mutation' } & {
  archiveWorkspace: { __typename?: 'ArchiveWorkspacesPayload' } & Pick<
    ArchiveWorkspacesPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'SuccessPayloadData' } & Pick<SuccessPayloadData, 'success'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type AddChannelSupportMutationVariables = Exact<{
  data: ChannelInputDataSupport;
}>;

export type AddChannelSupportMutation = { __typename?: 'Mutation' } & {
  addChannelSupport: { __typename?: 'ChannelPayload' } & Pick<ChannelPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ChannelResponseData' } & Pick<
          ChannelResponseData,
          'id' | 'number' | 'numberSid' | 'sms' | 'call' | 'mms'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ToggleSubscriptionMutationVariables = Exact<{
  data: SubscriptionActionToggle;
}>;

export type ToggleSubscriptionMutation = { __typename?: 'Mutation' } & {
  toggleSubscription: { __typename?: 'ToggleSubscriptionPayload' } & Pick<
    ToggleSubscriptionPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'Workspace' } & Pick<Workspace, 'id'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type GetAllWorkspacesQueryVariables = Exact<{
  params?: Maybe<ConnectionInput>;
}>;

export type GetAllWorkspacesQuery = { __typename?: 'Query' } & {
  getAllWorkspaces: { __typename?: 'WorkspaceListPaginated' } & Pick<
    WorkspaceListPaginated,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'WorkspaceConnection' } & {
          pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          edges?: Maybe<
            Array<
              Maybe<
                { __typename?: 'WorkspaceEdge' } & Pick<WorkspaceEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename?: 'Workspace' } & Pick<Workspace, 'id' | 'title' | 'status'> & {
                          members?: Maybe<
                            Array<Maybe<{ __typename?: 'Member' } & Pick<Member, 'id' | 'name'>>>
                          >;
                          plan?: Maybe<
                            { __typename?: 'PlanOverviewData' } & Pick<
                              PlanOverviewData,
                              | 'remainingDays'
                              | 'subscriptionActive'
                              | 'currentCredit'
                              | 'startedAt'
                              | 'endedAt'
                            > & {
                                planDetail?: Maybe<
                                  { __typename?: 'PlanDetailData' } & Pick<
                                    PlanDetailData,
                                    'title' | 'interval'
                                  >
                                >;
                              }
                          >;
                          owner?: Maybe<
                            { __typename?: 'OwnerData' } & Pick<
                              OwnerData,
                              'firstname' | 'lastname' | 'email'
                            >
                          >;
                          channels?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'Channel' } & Pick<
                                  Channel,
                                  'id' | 'number' | 'name' | 'status'
                                >
                              >
                            >
                          >;
                        }
                    >;
                  }
              >
            >
          >;
        }
      >;
    };
};

export type UserProfileFragment = { __typename?: 'UserProfile' } & Pick<
  UserProfile,
  | 'userId'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'status'
  | 'profilePicture'
  | 'defaultLanguage'
  | 'defaultTimezone'
  | 'defaultWorkspace'
  | 'stayOnline'
  | 'dndEnabled'
  | 'dndOn'
  | 'dndEndtime'
  | 'dndDuration'
>;

export type LoginMutationVariables = Exact<{
  data: LoginInputData;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'LoginPayload' } & Pick<LoginPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'LoginResponseData' } & Pick<
          LoginResponseData,
          'token' | 'intercomIdentity'
        > & {
            details?: Maybe<
              { __typename?: 'LoginResponseDetails' } & Pick<LoginResponseDetails, 'id'> & {
                  workspaces?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'LoginWorkspace' } & Pick<
                          LoginWorkspace,
                          'id' | 'memberId' | 'title' | 'role' | 'status'
                        > & {
                            plan?: Maybe<
                              { __typename?: 'PlanOverviewData' } & Pick<
                                PlanOverviewData,
                                'remainingDays' | 'subscriptionActive'
                              >
                            >;
                          }
                      >
                    >
                  >;
                  userProfile?: Maybe<{ __typename?: 'UserProfile' } & UserProfileFragment>;
                }
            >;
          }
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SignupMutationVariables = Exact<{
  data: RegisterInputData;
}>;

export type SignupMutation = { __typename?: 'Mutation' } & {
  signup: { __typename?: 'RegisterPayload' } & Pick<RegisterPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'RegisterResponseData' } & Pick<RegisterResponseData, 'id'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInputData;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
  changePassword: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ForgotPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & {
  forgotPassword: { __typename?: 'MessageOnlyPayload' } & Pick<MessageOnlyPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'Message' } & Pick<Message, 'message'>>;
    };
};

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword: { __typename?: 'MessageOnlyPayload' } & Pick<MessageOnlyPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'Message' } & Pick<Message, 'message'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangeEmailMutationVariables = Exact<{
  data: ChangeEmailInputData;
}>;

export type ChangeEmailMutation = { __typename?: 'Mutation' } & {
  changeEmail: { __typename?: 'ChangeEmailPayload' } & Pick<ChangeEmailPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'ChangeEmailPayloadData' } & Pick<ChangeEmailPayloadData, 'success'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenMutation = { __typename?: 'Mutation' } & {
  refreshToken: { __typename?: 'RefreshTokenLoginPayload' } & Pick<
    RefreshTokenLoginPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'RefreshTokenResponseData' } & Pick<RefreshTokenResponseData, 'accessToken'>
      >;
    };
};

export type MemberLoginMutationVariables = Exact<{
  data: MemberLoginInputData;
}>;

export type MemberLoginMutation = { __typename?: 'Mutation' } & {
  memberLogin: { __typename?: 'MemberLoginPayload' } & Pick<MemberLoginPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'MemberLoginResponseData' } & Pick<
          MemberLoginResponseData,
          'accessToken' | 'refreshToken' | 'cardInfo'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type VerifyEmailMutationVariables = Exact<{
  data: VerifyEmailInput;
}>;

export type VerifyEmailMutation = { __typename?: 'Mutation' } & {
  verifyEmail: { __typename?: 'VerifyEmailPayload' } & Pick<VerifyEmailPayload, 'status'> & {
      data?: Maybe<{ __typename?: 'VerifyEmailData' } & Pick<VerifyEmailData, 'success'>>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangeProfileNamesMutationVariables = Exact<{
  data: ChangeProfileNames;
}>;

export type ChangeProfileNamesMutation = { __typename?: 'Mutation' } & {
  changeProfileNames: { __typename?: 'UpdateProfileSucceedPayload' } & Pick<
    UpdateProfileSucceedPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'UserProfile' } & UserProfileFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ChangeProfilePictureMutationVariables = Exact<{
  photoUpload: Scalars['Upload'];
}>;

export type ChangeProfilePictureMutation = { __typename?: 'Mutation' } & {
  changeUserProfilePicture: { __typename?: 'UpdateProfileSucceedPayload' } & Pick<
    UpdateProfileSucceedPayload,
    'status'
  > & {
      data?: Maybe<{ __typename?: 'UserProfile' } & UserProfileFragment>;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type DeviceRegisterMutationVariables = Exact<{
  data: DeviceRegisterInputData;
}>;

export type DeviceRegisterMutation = { __typename?: 'Mutation' } & {
  deviceRegister: { __typename?: 'DeviceRegisterPayload' } & Pick<
    DeviceRegisterPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'DeviceRegisterData' } & Pick<
          DeviceRegisterData,
          'id' | 'version' | 'fcmToken' | 'platform'
        >
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type UpdateOnlineStatusMutationVariables = Exact<{
  data: EditOnlineStatusInputData;
}>;

export type UpdateOnlineStatusMutation = { __typename?: 'Mutation' } & {
  updateOnlineStatus: { __typename?: 'UpdateOnlineStatusPayload' } & Pick<
    UpdateOnlineStatusPayload,
    'status'
  > & {
      data?: Maybe<
        { __typename?: 'statusUpdatePayload' } & Pick<StatusUpdatePayload, 'id' | 'onlineStatus'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type SetUserDndMutationVariables = Exact<{
  data: UserDndInput;
}>;

export type SetUserDndMutation = { __typename?: 'Mutation' } & {
  setUserDND: { __typename?: 'DNDPayload' } & Pick<DndPayload, 'status'> & {
      data?: Maybe<
        { __typename?: 'Dnd' } & Pick<Dnd, 'dndEnabled' | 'dndEndtime' | 'dndRemainingTime'>
      >;
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
    };
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = { __typename?: 'Query' } & {
  profile: { __typename?: 'ProfileDetailPayload' } & Pick<ProfileDetailPayload, 'status'> & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<{ __typename?: 'UserProfile' } & UserProfileFragment>;
    };
};

export type CheckDuplicateLoginQueryVariables = Exact<{
  data: LoginInputData;
}>;

export type CheckDuplicateLoginQuery = { __typename?: 'Query' } & {
  checkDuplicateLogin: { __typename?: 'ResourceSucceedPayload' } & Pick<
    ResourceSucceedPayload,
    'status'
  > & {
      error?: Maybe<{ __typename?: 'ErrorData' } & ErrorFragment>;
      data?: Maybe<
        { __typename?: 'ResourceSucceedPayloadData' } & Pick<ResourceSucceedPayloadData, 'success'>
      >;
    };
};

export type UserProfileSubscriptionVariables = Exact<{
  user: Scalars['ShortId'];
}>;

// export type UserProfileSubscription = (
//   { __typename?: 'Subscription' }
//   & { userProfile: (
//     { __typename?: 'UserProfileSubscription' }
//     & Pick<UserProfileSubscription, 'event'>
//     & { message?: Maybe<(
//       { __typename?: 'UserProfile' }
//       & UserProfileFragment
//     )> }
//   ) }
// );

export const PlanFieldsFragmentDoc = gql`
  fragment PlanFields on PlanResponseData {
    id
    productId
    title
    rate
    order
    details
  }
`;
export const CardFieldsFragmentDoc = gql`
  fragment CardFields on CardResponseData {
    id
    name
    expiryMonth
    expiryYear
    lastDigit
    brand
  }
`;
export const NotificationAndAutoRechargeFieldsFragmentDoc = gql`
  fragment NotificationAndAutoRechargeFields on NotificationAndAutoRecharge {
    id
    lowCreditNotification
    lowCreditBalanceNotify
    creditAutoRecharge
    recharge
    lowCreditBalanceRecharge
    zeroCreditNotification
  }
`;
export const BillingInfoFieldsFragmentDoc = gql`
  fragment BillingInfoFields on BillingInformation {
    billTo
    taxId
    country
    address
    recipients
  }
`;
export const ConversationFieldsFragmentDoc = gql`
  fragment ConversationFields on ConversationNode {
    id
    contactClosed
    conversationStatus
    transcriptionStatus
    conversationType
    direction
    content {
      body
      duration
      transferedAudio
      transcript
    }
    createdAt
    clientNumber
    sms
    clientCountry
    status
    clientUnseenMsgCount
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
    }
    agentInfo {
      agentId
      firstname
      lastname
      profilePicture
    }
    personalizedInfo {
      pinned
      seen
      favourite
    }
  }
`;
export const RecentConversationFieldsFragmentDoc = gql`
  fragment RecentConversationFields on ConversationNode {
    id
    contactClosed
    contactPinned
    clientNumber
    clientCountry
    conversationType
    conversationStatus
    transcriptionStatus
    voicemailAudio
    createdAt
    direction
    sms
    status
    clientUnseenMsgCount
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
      dndDuration
      dndEnabled
      dndEndtime
    }
    agentInfo {
      agentId
      firstname
    }
    unreadConversationInfo {
      cursor
      id
    }
  }
`;
export const PinnedConversationFieldsFragmentDoc = gql`
  fragment PinnedConversationFields on ConversationNode {
    id
    conversationStatus
    transcriptionStatus
    conversationType
    direction
    content {
      body
      duration
      transferedAudio
      transcript
    }
    createdAt
    clientNumber
    sms
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
    }
    agentInfo {
      agentId
      firstname
      lastname
      profilePicture
    }
  }
`;
export const AgentFieldsFragmentDoc = gql`
  fragment AgentFields on Agent {
    id
    firstname
    email
    role
    lastname
    photo
  }
`;
export const NumberFieldsFragmentDoc = gql`
  fragment NumberFields on NumbersNode {
    id
    name
    number
    agents {
      id
      firstname
      lastname
      photo
    }
    callStrategy
  }
`;
export const PendingNumberResponseFragmentDoc = gql`
  fragment PendingNumberResponse on PendingNumber {
    name
    number
    country
    setUpFee
    monthlyFee
    purchaseStatus
    purchase_status
    addressRequirement
    regulationSid
  }
`;
export const NumberAbilitiesFieldsFragmentDoc = gql`
  fragment NumberAbilitiesFields on NumberAbilities {
    call
    sms
    mms
  }
`;
export const MemberInfoFieldsFragmentDoc = gql`
  fragment MemberInfoFields on MemberInfo {
    id
    firstname
    lastname
    picture
  }
`;
export const ChatFieldsFragmentDoc = gql`
  fragment ChatFields on ChatMessageNode {
    id
    sender {
      ...MemberInfoFields
    }
    receiver {
      ...MemberInfoFields
    }
    message
    type
    createdOn
    modifiedOn
    status
  }
  ${MemberInfoFieldsFragmentDoc}
`;
export const ClientInfoFieldsFragmentDoc = gql`
  fragment ClientInfoFields on ClientInfo {
    id
    number
    name
    country
    createdBy
    profilePicture
  }
`;
export const TagFieldsFragmentDoc = gql`
  fragment TagFields on Tag {
    id
    title
    colorCode
    backgroundColorCode
    count
  }
`;
export const ClientFieldsFragmentDoc = gql`
  fragment ClientFields on Client {
    id
    email
    name
    number
    address
    company
    visibility
    clientId
    country
    blocked
    profilePicture
    tags {
      ...TagFields
    }
    createdBy
    createdAt
  }
  ${TagFieldsFragmentDoc}
`;
export const TagResponseFieldsFragmentDoc = gql`
  fragment TagResponseFields on TagResponseData {
    id
    title
    colorCode
    backgroundColorCode
  }
`;
export const ContactFieldsFragmentDoc = gql`
  fragment ContactFields on ContactNode {
    id
    email
    name
    createdOn
    number
    address
    company
    visibility
    clientId
    country
    profilePicture
    blocked
    tags {
      ...TagFields
    }
    createdBy
    dndEnabled
    dndDuration
    dndEndtime
  }
  ${TagFieldsFragmentDoc}
`;
export const ChannelFieldsFragmentDoc = gql`
  fragment ChannelFields on Channel {
    id
    countryLogo
    country
    countryCode
    number
    name
    dndEndtime
    dndEnabled
    dndRemainingTime
    dndOn
    dndDuration
    unseenMessageCount
    sms
    call
    mms
  }
`;
export const WorkspaceFieldsFragmentDoc = gql`
  fragment WorkspaceFields on Workspace {
    id
    title
    photo
    channels {
      ...ChannelFields
    }
    plan {
      remainingDays
      subscriptionActive
      currentCredit
    }
    notification
    status
  }
  ${ChannelFieldsFragmentDoc}
`;
export const MemberNodeFieldsFragmentDoc = gql`
  fragment MemberNodeFields on MemberNode {
    id
    firstname
    lastname
    role
    gender
    email
    createdOn
    profilePicture
    numbers {
      name
      number
      country
      countryLogo
      countryCode
      settings {
        shared
      }
    }
    planRate {
      planRate
    }
    unSeenMsgCount
    online
    onCall
    last_message {
      createdOn
      message
    }
  }
`;
export const InviteNodeFieldsFragmentDoc = gql`
  fragment InviteNodeFields on InviteNode {
    id
    role
    code
    email
    createdOn
    type
    status
  }
`;
export const ErrorFragmentDoc = gql`
  fragment Error on ErrorData {
    code
    message
    errorKey
  }
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;
export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on DeviceRegisterData {
    id
    callMessages
    newLeads
    flashTaskbar
  }
`;
export const TeamMembersNodeFragmentDoc = gql`
  fragment TeamMembersNode on TeamMembers {
    id
    firstname
    lastname
    online
    profilePicture
    role
    email
  }
`;
export const TeamFieldsFragmentDoc = gql`
  fragment TeamFields on Team {
    id
    title
    description
    picture
    total
    online
    teamMembers {
      ...TeamMembersNode
    }
    avatar
  }
  ${TeamMembersNodeFragmentDoc}
`;
export const MemberFieldsFragmentDoc = gql`
  fragment MemberFields on MemberDetails {
    id
    email
    firstname
    lastname
    photo
    role
    lastonline
    online
  }
`;
export const TeamMemberFieldsFragmentDoc = gql`
  fragment TeamMemberFields on TeamMemberNode {
    id
    email
    firstname
    lastname
    photo
    role
    createdOn
    online
  }
`;
export const UserProfileFragmentDoc = gql`
  fragment UserProfile on UserProfile {
    userId
    firstname
    lastname
    email
    status
    profilePicture
    defaultLanguage
    defaultTimezone
    defaultWorkspace
    stayOnline
    dndEnabled
    dndOn
    dndEndtime
    dndDuration
  }
`;
export const AddPermissionDocument = gql`
  mutation addPermission($data: PermissionInputData!) {
    addPermission(data: $data) {
      status
      data
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddPermissionMutationFn = Apollo.MutationFunction<
  AddPermissionMutation,
  AddPermissionMutationVariables
>;
export type AddPermissionMutationResult = Apollo.MutationResult<AddPermissionMutation>;
export type AddPermissionMutationOptions = Apollo.BaseMutationOptions<
  AddPermissionMutation,
  AddPermissionMutationVariables
>;
export const PermissionDocument = gql`
  query permission {
    permissions {
      __typename
      status
      error {
        code
      }
      data
    }
  }
`;
export type PermissionQueryResult = Apollo.QueryResult<PermissionQuery, PermissionQueryVariables>;
export const UpdateBillingInformationDocument = gql`
  mutation updateBillingInformation($data: BillingInformationInputData!) {
    updateBillingInformation(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdateBillingInformationMutationFn = Apollo.MutationFunction<
  UpdateBillingInformationMutation,
  UpdateBillingInformationMutationVariables
>;
export type UpdateBillingInformationMutationResult = Apollo.MutationResult<UpdateBillingInformationMutation>;
export type UpdateBillingInformationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillingInformationMutation,
  UpdateBillingInformationMutationVariables
>;
export const HideBrandingDocument = gql`
  mutation hideBranding($data: hideBrandingInputData!) {
    hideBranding(data: $data) {
      status
      data {
        hideKrispcallBranding
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type HideBrandingMutationFn = Apollo.MutationFunction<
  HideBrandingMutation,
  HideBrandingMutationVariables
>;
export type HideBrandingMutationResult = Apollo.MutationResult<HideBrandingMutation>;
export type HideBrandingMutationOptions = Apollo.BaseMutationOptions<
  HideBrandingMutation,
  HideBrandingMutationVariables
>;
export const SaveNotificationAndAutoRechargeDocument = gql`
  mutation saveNotificationAndAutoRecharge($data: NotificationAndAutoRechargeInputData!) {
    saveNotificationAndAutoRecharge(data: $data) {
      status
      data {
        ...NotificationAndAutoRechargeFields
      }
      error {
        ...Error
      }
    }
  }
  ${NotificationAndAutoRechargeFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SaveNotificationAndAutoRechargeMutationFn = Apollo.MutationFunction<
  SaveNotificationAndAutoRechargeMutation,
  SaveNotificationAndAutoRechargeMutationVariables
>;
export type SaveNotificationAndAutoRechargeMutationResult = Apollo.MutationResult<SaveNotificationAndAutoRechargeMutation>;
export type SaveNotificationAndAutoRechargeMutationOptions = Apollo.BaseMutationOptions<
  SaveNotificationAndAutoRechargeMutation,
  SaveNotificationAndAutoRechargeMutationVariables
>;
export const ChangePlanDocument = gql`
  mutation changePlan($data: ChangePlanInputData!) {
    changePlan(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangePlanMutationFn = Apollo.MutationFunction<
  ChangePlanMutation,
  ChangePlanMutationVariables
>;
export type ChangePlanMutationResult = Apollo.MutationResult<ChangePlanMutation>;
export type ChangePlanMutationOptions = Apollo.BaseMutationOptions<
  ChangePlanMutation,
  ChangePlanMutationVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription {
    cancelSubscription {
      status
      data {
        subscriptionActive
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const RenewSubscriptionDocument = gql`
  mutation renewSubscription {
    renewSubscription {
      status
      data {
        id
        plan {
          remainingDays
          subscriptionActive
          trialPeriod
          cardInfo
          currentCredit
          startedAt
          endedAt
          planDetail {
            title
            interval
          }
        }
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RenewSubscriptionMutationFn = Apollo.MutationFunction<
  RenewSubscriptionMutation,
  RenewSubscriptionMutationVariables
>;
export type RenewSubscriptionMutationResult = Apollo.MutationResult<RenewSubscriptionMutation>;
export type RenewSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  RenewSubscriptionMutation,
  RenewSubscriptionMutationVariables
>;
export const SwitchPlanDocument = gql`
  mutation switchPlan {
    switchPlan {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SwitchPlanMutationFn = Apollo.MutationFunction<
  SwitchPlanMutation,
  SwitchPlanMutationVariables
>;
export type SwitchPlanMutationResult = Apollo.MutationResult<SwitchPlanMutation>;
export type SwitchPlanMutationOptions = Apollo.BaseMutationOptions<
  SwitchPlanMutation,
  SwitchPlanMutationVariables
>;
export const BillingInformationDocument = gql`
  query billingInformation {
    billingInformation {
      status
      error {
        ...Error
      }
      data {
        ...BillingInfoFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${BillingInfoFieldsFragmentDoc}
`;
export type BillingInformationQueryResult = Apollo.QueryResult<
  BillingInformationQuery,
  BillingInformationQueryVariables
>;
export const PlansDocument = gql`
  query plans($interval: String) {
    plans(interval: $interval) {
      status
      error {
        ...Error
      }
      data {
        ...PlanFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PlanFieldsFragmentDoc}
`;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const PlanOverviewDocument = gql`
  query planOverview {
    planOverview {
      status
      error {
        ...Error
      }
      data {
        customerId
        subscriptionActive
        currentPeriodEnd
        totalAmount
        interval
        remainingDays
        plan {
          ...PlanFields
        }
        credit {
          id
          amount
        }
        card {
          ...CardFields
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PlanFieldsFragmentDoc}
  ${CardFieldsFragmentDoc}
`;
export type PlanOverviewQueryResult = Apollo.QueryResult<
  PlanOverviewQuery,
  PlanOverviewQueryVariables
>;
export const NotificationAndAutoRechargeDocument = gql`
  query notificationAndAutoRecharge {
    notificationAndAutoRecharge {
      status
      error {
        ...Error
      }
      data {
        ...NotificationAndAutoRechargeFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${NotificationAndAutoRechargeFieldsFragmentDoc}
`;
export type NotificationAndAutoRechargeQueryResult = Apollo.QueryResult<
  NotificationAndAutoRechargeQuery,
  NotificationAndAutoRechargeQueryVariables
>;
export const ReceiptsDocument = gql`
  query receipts {
    receipts {
      status
      error {
        ...Error
      }
      data {
        amount
        invoiceNumber
        createdOn
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ReceiptsQueryResult = Apollo.QueryResult<ReceiptsQuery, ReceiptsQueryVariables>;
export const InvoiceDocument = gql`
  query invoice($invoiceNumber: String!) {
    invoice(invoiceNumber: $invoiceNumber) {
      status
      error {
        ...Error
      }
      data {
        invoiceNumber
        createdOn
        billingInformation {
          ...BillingInfoFields
        }
        cardDetail {
          id
          brand
          lastDigit
        }
        totalAmount
        invoiceItems {
          unitPrice
          content
          contentType
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${BillingInfoFieldsFragmentDoc}
`;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const AddNumberDocument = gql`
  mutation addNumber($data: Number!) {
    addNumber(data: $data) {
      status
      data {
        ...PendingNumberResponse
      }
      error {
        ...Error
      }
    }
  }
  ${PendingNumberResponseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AddNumberMutationFn = Apollo.MutationFunction<
  AddNumberMutation,
  AddNumberMutationVariables
>;
export type AddNumberMutationResult = Apollo.MutationResult<AddNumberMutation>;
export type AddNumberMutationOptions = Apollo.BaseMutationOptions<
  AddNumberMutation,
  AddNumberMutationVariables
>;
export const ArchiveNumberDocument = gql`
  mutation archiveNumber($data: RemoveNumberInputData!) {
    archiveNumber(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ArchiveNumberMutationFn = Apollo.MutationFunction<
  ArchiveNumberMutation,
  ArchiveNumberMutationVariables
>;
export type ArchiveNumberMutationResult = Apollo.MutationResult<ArchiveNumberMutation>;
export type ArchiveNumberMutationOptions = Apollo.BaseMutationOptions<
  ArchiveNumberMutation,
  ArchiveNumberMutationVariables
>;
export const ProcessNumberDocument = gql`
  mutation processNumber($data: ProcessNumbersInputData!) {
    processNumber(data: $data) {
      status
      data {
        ...NumberFields
      }
      error {
        ...Error
      }
    }
  }
  ${NumberFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ProcessNumberMutationFn = Apollo.MutationFunction<
  ProcessNumberMutation,
  ProcessNumberMutationVariables
>;
export type ProcessNumberMutationResult = Apollo.MutationResult<ProcessNumberMutation>;
export type ProcessNumberMutationOptions = Apollo.BaseMutationOptions<
  ProcessNumberMutation,
  ProcessNumberMutationVariables
>;
export const SearchAvailableNumbersDocument = gql`
  query searchAvailableNumbers(
    $pathParams: NumberAvailablityPathParams
    $searchParams: NumberAvailablitySearchParams
  ) {
    searchAvailableNumbers(pathParams: $pathParams, searchParams: $searchParams) {
      status
      data {
        type
        dialingNumber
        capabilities {
          voice
          sms
          mms
        }
        price {
          setUpFee
          monthlyFee
        }
        addressRequirements
        region
        locality
        countryIso
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SearchAvailableNumbersQueryResult = Apollo.QueryResult<
  SearchAvailableNumbersQuery,
  SearchAvailableNumbersQueryVariables
>;
export const AvailableNumberCountriesDocument = gql`
  query availableNumberCountries {
    availableNumberCountries {
      status
      data {
        countryCode
        country
        types
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AvailableNumberCountriesQueryResult = Apollo.QueryResult<
  AvailableNumberCountriesQuery,
  AvailableNumberCountriesQueryVariables
>;
export const CountryFromCodeDocument = gql`
  query countryFromCode($countryCode: String!) {
    countryFromCode(alphaTwoCode: $countryCode) {
      status
      data {
        cities
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CountryFromCodeQueryResult = Apollo.QueryResult<
  CountryFromCodeQuery,
  CountryFromCodeQueryVariables
>;
export const ChannelNameExistsDocument = gql`
  query channelNameExists($name: String!) {
    channelNameExists(name: $name) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChannelNameExistsQueryResult = Apollo.QueryResult<
  ChannelNameExistsQuery,
  ChannelNameExistsQueryVariables
>;
export const PendingNumbersDocument = gql`
  query pendingNumbers($purchaseStatus: PendingNumbersEnum) {
    pendingNumbers(purchaseStatus: $purchaseStatus) {
      status
      data {
        ...PendingNumberResponse
      }
      error {
        ...Error
      }
    }
  }
  ${PendingNumberResponseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PendingNumbersQueryResult = Apollo.QueryResult<
  PendingNumbersQuery,
  PendingNumbersQueryVariables
>;
export const RegulationDocument = gql`
  query regulation($sid: String!) {
    regulation(sid: $sid) {
      status
      data
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RegulationQueryResult = Apollo.QueryResult<RegulationQuery, RegulationQueryVariables>;
export const SendMessageDocument = gql`
  mutation sendMessage($data: ConversationInput!) {
    sendMessage(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...ConversationFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${ConversationFieldsFragmentDoc}
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const SendBulkMessageDocument = gql`
  mutation sendBulkMessage($data: BulkMessageInput!) {
    sendBulkMessage(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        channel
        content
        contacts
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SendBulkMessageMutationFn = Apollo.MutationFunction<
  SendBulkMessageMutation,
  SendBulkMessageMutationVariables
>;
export type SendBulkMessageMutationResult = Apollo.MutationResult<SendBulkMessageMutation>;
export type SendBulkMessageMutationOptions = Apollo.BaseMutationOptions<
  SendBulkMessageMutation,
  SendBulkMessageMutationVariables
>;
export const PinnedAgentConversationDataDocument = gql`
  mutation pinnedAgentConversationData($id: ShortId!, $data: PinnedConversationAgentData!) {
    pinnedAgentConversationData(id: $id, data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type PinnedAgentConversationDataMutationFn = Apollo.MutationFunction<
  PinnedAgentConversationDataMutation,
  PinnedAgentConversationDataMutationVariables
>;
export type PinnedAgentConversationDataMutationResult = Apollo.MutationResult<PinnedAgentConversationDataMutation>;
export type PinnedAgentConversationDataMutationOptions = Apollo.BaseMutationOptions<
  PinnedAgentConversationDataMutation,
  PinnedAgentConversationDataMutationVariables
>;
export const AddPinnedDocument = gql`
  mutation addPinned($data: PinnedInputData!) {
    addPinned(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddPinnedMutationFn = Apollo.MutationFunction<
  AddPinnedMutation,
  AddPinnedMutationVariables
>;
export type AddPinnedMutationResult = Apollo.MutationResult<AddPinnedMutation>;
export type AddPinnedMutationOptions = Apollo.BaseMutationOptions<
  AddPinnedMutation,
  AddPinnedMutationVariables
>;
export const ConversationSeenDocument = gql`
  mutation conversationSeen($contact: String!, $channel: ShortId!) {
    conversationSeen(contact: $contact, channel: $channel) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ConversationSeenMutationFn = Apollo.MutationFunction<
  ConversationSeenMutation,
  ConversationSeenMutationVariables
>;
export type ConversationSeenMutationResult = Apollo.MutationResult<ConversationSeenMutation>;
export type ConversationSeenMutationOptions = Apollo.BaseMutationOptions<
  ConversationSeenMutation,
  ConversationSeenMutationVariables
>;
export const ChannelContactStateDocument = gql`
  mutation channelContactState($channel: ShortId!, $data: ChannelContactStateInputData!) {
    channelContactState(channel: $channel, data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChannelContactStateMutationFn = Apollo.MutationFunction<
  ChannelContactStateMutation,
  ChannelContactStateMutationVariables
>;
export type ChannelContactStateMutationResult = Apollo.MutationResult<ChannelContactStateMutation>;
export type ChannelContactStateMutationOptions = Apollo.BaseMutationOptions<
  ChannelContactStateMutation,
  ChannelContactStateMutationVariables
>;
export const AgentDraftsDocument = gql`
  mutation agentDrafts($data: AgentDraftsInputData!) {
    agentDrafts(data: $data) {
      status
      data {
        id
        msg
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AgentDraftsMutationFn = Apollo.MutationFunction<
  AgentDraftsMutation,
  AgentDraftsMutationVariables
>;
export type AgentDraftsMutationResult = Apollo.MutationResult<AgentDraftsMutation>;
export type AgentDraftsMutationOptions = Apollo.BaseMutationOptions<
  AgentDraftsMutation,
  AgentDraftsMutationVariables
>;
export const ArchieveContactThreadDocument = gql`
  mutation archieveContactThread($data: ArchiveContactThreadInputData!) {
    archieveContactThread(data: $data) {
      status
      data {
        contact
        msg
        channel
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ArchieveContactThreadMutationFn = Apollo.MutationFunction<
  ArchieveContactThreadMutation,
  ArchieveContactThreadMutationVariables
>;
export type ArchieveContactThreadMutationResult = Apollo.MutationResult<ArchieveContactThreadMutation>;
export type ArchieveContactThreadMutationOptions = Apollo.BaseMutationOptions<
  ArchieveContactThreadMutation,
  ArchieveContactThreadMutationVariables
>;
export const MarkAsUnreadConversationDocument = gql`
  mutation markAsUnreadConversation($conversation: ShortId!) {
    markAsUnreadConversation(conversation: $conversation) {
      status
      data {
        id
        unreadCount
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MarkAsUnreadConversationMutationFn = Apollo.MutationFunction<
  MarkAsUnreadConversationMutation,
  MarkAsUnreadConversationMutationVariables
>;
export type MarkAsUnreadConversationMutationResult = Apollo.MutationResult<MarkAsUnreadConversationMutation>;
export type MarkAsUnreadConversationMutationOptions = Apollo.BaseMutationOptions<
  MarkAsUnreadConversationMutation,
  MarkAsUnreadConversationMutationVariables
>;
export const AddMacrosDocument = gql`
  mutation addMacros($data: MacrosInputData!) {
    addMacros(data: $data) {
      status
      data {
        id
        title
        message
        type
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddMacrosMutationFn = Apollo.MutationFunction<
  AddMacrosMutation,
  AddMacrosMutationVariables
>;
export type AddMacrosMutationResult = Apollo.MutationResult<AddMacrosMutation>;
export type AddMacrosMutationOptions = Apollo.BaseMutationOptions<
  AddMacrosMutation,
  AddMacrosMutationVariables
>;
export const RemoveMacrosDocument = gql`
  mutation removeMacros($id: ShortId!) {
    removeMacros(id: $id) {
      status
      data {
        id
        title
        message
        type
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveMacrosMutationFn = Apollo.MutationFunction<
  RemoveMacrosMutation,
  RemoveMacrosMutationVariables
>;
export type RemoveMacrosMutationResult = Apollo.MutationResult<RemoveMacrosMutation>;
export type RemoveMacrosMutationOptions = Apollo.BaseMutationOptions<
  RemoveMacrosMutation,
  RemoveMacrosMutationVariables
>;
export const UpdateMacrosDocument = gql`
  mutation updateMacros($data: UpdateMacosInputData!, $id: ShortId!) {
    updateMacros(id: $id, data: $data) {
      status
      data {
        id
        title
        message
        type
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdateMacrosMutationFn = Apollo.MutationFunction<
  UpdateMacrosMutation,
  UpdateMacrosMutationVariables
>;
export type UpdateMacrosMutationResult = Apollo.MutationResult<UpdateMacrosMutation>;
export type UpdateMacrosMutationOptions = Apollo.BaseMutationOptions<
  UpdateMacrosMutation,
  UpdateMacrosMutationVariables
>;
export const ConversationDocument = gql`
  query conversation($channel: ShortId!, $contact: String!, $params: ConnectionInput) {
    conversation(channel: $channel, contact: $contact, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...ConversationFields
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${ConversationFieldsFragmentDoc}
`;
export type ConversationQueryResult = Apollo.QueryResult<
  ConversationQuery,
  ConversationQueryVariables
>;
export const RecentConversationDocument = gql`
  query recentConversation($channel: ShortId!, $params: ConnectionInput!) {
    recentConversation(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...RecentConversationFields
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${RecentConversationFieldsFragmentDoc}
`;
export type RecentConversationQueryResult = Apollo.QueryResult<
  RecentConversationQuery,
  RecentConversationQueryVariables
>;
export const UpdateConversationCountDocument = gql`
  subscription updateConversationCount($channels: [ShortId!]!) {
    updateCounversationCount(channels: $channels) {
      event
      message {
        channel
      }
    }
  }
`;
export type UpdateConversationCountSubscriptionResult = Apollo.SubscriptionResult<UpdateConversationCountSubscription>;
export const NewCountDocument = gql`
  query newCount($channel: ShortId!) {
    newCount(channel: $channel) {
      status
      error {
        ...Error
      }
      data
    }
  }
  ${ErrorFragmentDoc}
`;
export type NewCountQueryResult = Apollo.QueryResult<NewCountQuery, NewCountQueryVariables>;
export const AllPinnedConversationDocument = gql`
  query allPinnedConversation($channel: ShortId!, $contact: String!) {
    allPinnedConversation(channel: $channel, contact: $contact) {
      status
      data {
        ...PinnedConversationFields
      }
      error {
        ...Error
      }
    }
  }
  ${PinnedConversationFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AllPinnedConversationQueryResult = Apollo.QueryResult<
  AllPinnedConversationQuery,
  AllPinnedConversationQueryVariables
>;
export const ContactPinnedConversationOpenClosedDocument = gql`
  query contactPinnedConversationOpenClosed($channel: ShortId!, $params: ConnectionInput!) {
    contactPinnedConversationOpenClosed(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...RecentConversationFields
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${RecentConversationFieldsFragmentDoc}
`;
export type ContactPinnedConversationOpenClosedQueryResult = Apollo.QueryResult<
  ContactPinnedConversationOpenClosedQuery,
  ContactPinnedConversationOpenClosedQueryVariables
>;
export const RecentConversationHistoryDocument = gql`
  query recentConversationHistory($channel: ShortId!, $params: ConnectionInput!) {
    recentConversationHistory(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...RecentConversationFields
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${RecentConversationFieldsFragmentDoc}
`;
export type RecentConversationHistoryQueryResult = Apollo.QueryResult<
  RecentConversationHistoryQuery,
  RecentConversationHistoryQueryVariables
>;
export const OpenConversationCountDocument = gql`
  query openConversationCount($channel: ShortId!) {
    openContactCount(channel: $channel) {
      status
      error {
        ...Error
      }
      data
    }
  }
  ${ErrorFragmentDoc}
`;
export type OpenConversationCountQueryResult = Apollo.QueryResult<
  OpenConversationCountQuery,
  OpenConversationCountQueryVariables
>;
export const WorkspaceDraftsDocument = gql`
  query workspaceDrafts($workspace: ShortId) {
    workspaceDrafts(workspace: $workspace) {
      status
      data {
        channel
        messages {
          id
          content
          clientNumber
        }
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type WorkspaceDraftsQueryResult = Apollo.QueryResult<
  WorkspaceDraftsQuery,
  WorkspaceDraftsQueryVariables
>;
export const ConversationHistoryDocument = gql`
  query conversationHistory(
    $channel: ShortId!
    $contact: String!
    $conversationStatus: String!
    $transcriptionStatus: String!
    $params: ConnectionInput
  ) {
    conversationHistory(
      channel: $channel
      contact: $contact
      conversationStatus: $conversationStatus
      transcriptionStatus: $transcriptionStatus
      params: $params
    ) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...ConversationFields
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${ConversationFieldsFragmentDoc}
`;
export type ConversationHistoryQueryResult = Apollo.QueryResult<
  ConversationHistoryQuery,
  ConversationHistoryQueryVariables
>;
export const MacrosDocument = gql`
  query macros {
    macros {
      status
      error {
        ...Error
      }
      data {
        id
        message
        title
        type
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MacrosQueryResult = Apollo.QueryResult<MacrosQuery, MacrosQueryVariables>;
export const UpdateConversationDocument = gql`
  subscription updateConversation($channel: ShortId!) {
    updateConversation(channel: $channel) {
      event
      message {
        ...ConversationFields
      }
    }
  }
  ${ConversationFieldsFragmentDoc}
`;
export type UpdateConversationSubscriptionResult = Apollo.SubscriptionResult<UpdateConversationSubscription>;
export const OpenContactCountDocument = gql`
  subscription openContactCount($channel: ShortId!) {
    openContactCount(channel: $channel) {
      event
      message {
        count
        channel
      }
    }
  }
`;
export type OpenContactCountSubscriptionResult = Apollo.SubscriptionResult<OpenContactCountSubscription>;
export const AddChannelDocument = gql`
  mutation addChannel($data: ChannelInputData!) {
    addChannel(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddChannelMutationFn = Apollo.MutationFunction<
  AddChannelMutation,
  AddChannelMutationVariables
>;
export type AddChannelMutationResult = Apollo.MutationResult<AddChannelMutation>;
export type AddChannelMutationOptions = Apollo.BaseMutationOptions<
  AddChannelMutation,
  AddChannelMutationVariables
>;
export const ChannelOrderDocument = gql`
  mutation channelOrder($data: ChannelOrderInputData!) {
    channelOrder(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChannelOrderMutationFn = Apollo.MutationFunction<
  ChannelOrderMutation,
  ChannelOrderMutationVariables
>;
export type ChannelOrderMutationResult = Apollo.MutationResult<ChannelOrderMutation>;
export type ChannelOrderMutationOptions = Apollo.BaseMutationOptions<
  ChannelOrderMutation,
  ChannelOrderMutationVariables
>;
export const SetChannelDndDocument = gql`
  mutation setChannelDnd($id: ShortId!, $minutes: Int) {
    setChannelDnd(id: $id, minutes: $minutes) {
      status
      data {
        dndEnabled
        dndEndtime
        dndRemainingTime
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SetChannelDndMutationFn = Apollo.MutationFunction<
  SetChannelDndMutation,
  SetChannelDndMutationVariables
>;
export type SetChannelDndMutationResult = Apollo.MutationResult<SetChannelDndMutation>;
export type SetChannelDndMutationOptions = Apollo.BaseMutationOptions<
  SetChannelDndMutation,
  SetChannelDndMutationVariables
>;
export const RemoveChannelDndDocument = gql`
  mutation removeChannelDnd($id: ShortId!) {
    removeChannelDnd(id: $id) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveChannelDndMutationFn = Apollo.MutationFunction<
  RemoveChannelDndMutation,
  RemoveChannelDndMutationVariables
>;
export type RemoveChannelDndMutationResult = Apollo.MutationResult<RemoveChannelDndMutation>;
export type RemoveChannelDndMutationOptions = Apollo.BaseMutationOptions<
  RemoveChannelDndMutation,
  RemoveChannelDndMutationVariables
>;
export const ChannelConversationSeenDocument = gql`
  mutation channelConversationSeen($channel: ShortId!) {
    channelConversationSeen(channel: $channel) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChannelConversationSeenMutationFn = Apollo.MutationFunction<
  ChannelConversationSeenMutation,
  ChannelConversationSeenMutationVariables
>;
export type ChannelConversationSeenMutationResult = Apollo.MutationResult<ChannelConversationSeenMutation>;
export type ChannelConversationSeenMutationOptions = Apollo.BaseMutationOptions<
  ChannelConversationSeenMutation,
  ChannelConversationSeenMutationVariables
>;
export const RejectConversationDocument = gql`
  mutation rejectConversation($id: ShortId!) {
    rejectConversation(id: $id) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RejectConversationMutationFn = Apollo.MutationFunction<
  RejectConversationMutation,
  RejectConversationMutationVariables
>;
export type RejectConversationMutationResult = Apollo.MutationResult<RejectConversationMutation>;
export type RejectConversationMutationOptions = Apollo.BaseMutationOptions<
  RejectConversationMutation,
  RejectConversationMutationVariables
>;
export const CallRecordingDocument = gql`
  mutation callRecording($data: RecordCallInput!) {
    callRecording(data: $data) {
      status
      data {
        status
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CallRecordingMutationFn = Apollo.MutationFunction<
  CallRecordingMutation,
  CallRecordingMutationVariables
>;
export type CallRecordingMutationResult = Apollo.MutationResult<CallRecordingMutation>;
export type CallRecordingMutationOptions = Apollo.BaseMutationOptions<
  CallRecordingMutation,
  CallRecordingMutationVariables
>;
export const CancelOutgoingCallDocument = gql`
  mutation cancelOutgoingCall($data: OutgoingCancelInput!) {
    cancelOutgoingCall(data: $data) {
      status
      data {
        CancelStatus
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CancelOutgoingCallMutationFn = Apollo.MutationFunction<
  CancelOutgoingCallMutation,
  CancelOutgoingCallMutationVariables
>;
export type CancelOutgoingCallMutationResult = Apollo.MutationResult<CancelOutgoingCallMutation>;
export type CancelOutgoingCallMutationOptions = Apollo.BaseMutationOptions<
  CancelOutgoingCallMutation,
  CancelOutgoingCallMutationVariables
>;
export const CallHoldDocument = gql`
  mutation callHold($data: HoldCallInput!) {
    callHold(data: $data) {
      status
      data {
        message
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CallHoldMutationFn = Apollo.MutationFunction<
  CallHoldMutation,
  CallHoldMutationVariables
>;
export type CallHoldMutationResult = Apollo.MutationResult<CallHoldMutation>;
export type CallHoldMutationOptions = Apollo.BaseMutationOptions<
  CallHoldMutation,
  CallHoldMutationVariables
>;
export const CallRatingDocument = gql`
  mutation callRating($id: ShortId!, $data: CallRatingInputData!) {
    callRating(id: $id, data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CallRatingMutationFn = Apollo.MutationFunction<
  CallRatingMutation,
  CallRatingMutationVariables
>;
export type CallRatingMutationResult = Apollo.MutationResult<CallRatingMutation>;
export type CallRatingMutationOptions = Apollo.BaseMutationOptions<
  CallRatingMutation,
  CallRatingMutationVariables
>;
export const GetVoiceTokenDocument = gql`
  query getVoiceToken {
    getVoiceToken {
      status
      error {
        ...Error
      }
      data {
        voiceToken
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GetVoiceTokenQueryResult = Apollo.QueryResult<
  GetVoiceTokenQuery,
  GetVoiceTokenQueryVariables
>;
export const ChannelsDocument = gql`
  query channels {
    channels {
      status
      data {
        ...ChannelFields
      }
      error {
        ...Error
      }
    }
  }
  ${ChannelFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ChannelsQueryResult = Apollo.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export const ChannelInfoDocument = gql`
  query channelInfo($channel: ShortId!) {
    channelInfo(channel: $channel) {
      status
      data {
        id
        country
        name
        countryLogo
        countryCode
        number
        call
        sms
        mms
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChannelInfoQueryResult = Apollo.QueryResult<
  ChannelInfoQuery,
  ChannelInfoQueryVariables
>;
export const SaveIvrSettingsDocument = gql`
  mutation saveIvrSettings($channel: ShortId!, $data: IvrSettingsInputData!) {
    saveIvrSettings(channel: $channel, data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SaveIvrSettingsMutationFn = Apollo.MutationFunction<
  SaveIvrSettingsMutation,
  SaveIvrSettingsMutationVariables
>;
export type SaveIvrSettingsMutationResult = Apollo.MutationResult<SaveIvrSettingsMutation>;
export type SaveIvrSettingsMutationOptions = Apollo.BaseMutationOptions<
  SaveIvrSettingsMutation,
  SaveIvrSettingsMutationVariables
>;
export const UpdateCallStrategyDocument = gql`
  mutation updateCallStrategy($channel: ShortId!, $data: CallStrategySettingsInput!) {
    updateCallStrategy(channel: $channel, data: $data) {
      status
      data {
        incomingCallStrategy
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdateCallStrategyMutationFn = Apollo.MutationFunction<
  UpdateCallStrategyMutation,
  UpdateCallStrategyMutationVariables
>;
export type UpdateCallStrategyMutationResult = Apollo.MutationResult<UpdateCallStrategyMutation>;
export type UpdateCallStrategyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallStrategyMutation,
  UpdateCallStrategyMutationVariables
>;
export const PortNumberDocument = gql`
  mutation portNumber($data: PortNumberInputData!) {
    portNumber(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type PortNumberMutationFn = Apollo.MutationFunction<
  PortNumberMutation,
  PortNumberMutationVariables
>;
export type PortNumberMutationResult = Apollo.MutationResult<PortNumberMutation>;
export type PortNumberMutationOptions = Apollo.BaseMutationOptions<
  PortNumberMutation,
  PortNumberMutationVariables
>;
export const UpdateGeneralSettingsDocument = gql`
  mutation updateGeneralSettings($channel: ShortId!, $data: GeneralChannelSettingsInput!) {
    updateGeneralSettings(channel: $channel, data: $data) {
      status
      data {
        name
        autoRecordCalls
        internationalCallAndMessages
        emailNotification
        transcription
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdateGeneralSettingsMutationFn = Apollo.MutationFunction<
  UpdateGeneralSettingsMutation,
  UpdateGeneralSettingsMutationVariables
>;
export type UpdateGeneralSettingsMutationResult = Apollo.MutationResult<UpdateGeneralSettingsMutation>;
export type UpdateGeneralSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateGeneralSettingsMutation,
  UpdateGeneralSettingsMutationVariables
>;
export const EditChannelAccessDocument = gql`
  mutation editChannelAccess($channel: ShortId!, $data: EditChannelShareInput!) {
    editChannelAccess(channel: $channel, data: $data) {
      status
      data {
        id
        shared
        agents {
          ...AgentFields
        }
      }
      error {
        ...Error
      }
    }
  }
  ${AgentFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EditChannelAccessMutationFn = Apollo.MutationFunction<
  EditChannelAccessMutation,
  EditChannelAccessMutationVariables
>;
export type EditChannelAccessMutationResult = Apollo.MutationResult<EditChannelAccessMutation>;
export type EditChannelAccessMutationOptions = Apollo.BaseMutationOptions<
  EditChannelAccessMutation,
  EditChannelAccessMutationVariables
>;
export const DeleteChannelDocument = gql`
  mutation deleteChannel($id: ShortId!) {
    deleteChannel(id: $id) {
      status
      data {
        id
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type DeleteChannelMutationFn = Apollo.MutationFunction<
  DeleteChannelMutation,
  DeleteChannelMutationVariables
>;
export type DeleteChannelMutationResult = Apollo.MutationResult<DeleteChannelMutation>;
export type DeleteChannelMutationOptions = Apollo.BaseMutationOptions<
  DeleteChannelMutation,
  DeleteChannelMutationVariables
>;
export const SetupCallerIdDocument = gql`
  mutation setupCallerId($data: CallerIdNumberInput!) {
    setupCallerId(data: $data) {
      status
      data {
        code
        message
        status
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SetupCallerIdMutationFn = Apollo.MutationFunction<
  SetupCallerIdMutation,
  SetupCallerIdMutationVariables
>;
export type SetupCallerIdMutationResult = Apollo.MutationResult<SetupCallerIdMutation>;
export type SetupCallerIdMutationOptions = Apollo.BaseMutationOptions<
  SetupCallerIdMutation,
  SetupCallerIdMutationVariables
>;
export const SetChannelCallerIdDocument = gql`
  mutation setChannelCallerId($data: CallerIdNumberInput!) {
    setChannelCallerId(data: $data) {
      status
      data {
        callerId
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SetChannelCallerIdMutationFn = Apollo.MutationFunction<
  SetChannelCallerIdMutation,
  SetChannelCallerIdMutationVariables
>;
export type SetChannelCallerIdMutationResult = Apollo.MutationResult<SetChannelCallerIdMutation>;
export type SetChannelCallerIdMutationOptions = Apollo.BaseMutationOptions<
  SetChannelCallerIdMutation,
  SetChannelCallerIdMutationVariables
>;
export const NumbersDocument = gql`
  query numbers {
    numbers {
      status
      data {
        id
        name
        number
        agents {
          ...AgentFields
        }
        callStrategy
        numberCheckoutPrice {
          setUpFee
          monthlyFee
          basePrice
        }
      }
      error {
        ...Error
      }
    }
  }
  ${AgentFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type NumbersQueryResult = Apollo.QueryResult<NumbersQuery, NumbersQueryVariables>;
export const NumberSettingsDocument = gql`
  query numberSettings($channel: ShortId!) {
    numberSettings(channel: $channel) {
      status
      data {
        id
        name
        autoRecordCalls
        internationalCallAndMessages
        emailNotification
        incomingCallStrategy
        incomingCallForward
        unanswerCallsFallback
        externalNumber
        simultaneousDialing
        transcription
        agents {
          ...AgentFields
        }
        abilities {
          ...NumberAbilitiesFields
        }
        shared
      }
      error {
        ...Error
      }
    }
  }
  ${AgentFieldsFragmentDoc}
  ${NumberAbilitiesFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type NumberSettingsQueryResult = Apollo.QueryResult<
  NumberSettingsQuery,
  NumberSettingsQueryVariables
>;
export const IvrSettingsDocument = gql`
  query ivrSettings($channel: ShortId!) {
    ivrSettings(channel: $channel) {
      status
      data {
        source
        voice
        accent
        recordingType
        recordingUrl
        ivrOptions {
          extensionNumber
          forwardTo
          unansweredForwardTo
        }
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type IvrSettingsQueryResult = Apollo.QueryResult<
  IvrSettingsQuery,
  IvrSettingsQueryVariables
>;
export const CallerIdSettingsDataDocument = gql`
  query callerIdSettingsData($channel: ShortId!) {
    callerIdSettingsData(channel: $channel) {
      status
      data {
        status
        action
        callerId
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CallerIdSettingsDataQueryResult = Apollo.QueryResult<
  CallerIdSettingsDataQuery,
  CallerIdSettingsDataQueryVariables
>;
export const VerifiedCallerIdsDocument = gql`
  query verifiedCallerIds {
    verifiedCallerIds {
      status
      data {
        id
        number
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type VerifiedCallerIdsQueryResult = Apollo.QueryResult<
  VerifiedCallerIdsQuery,
  VerifiedCallerIdsQueryVariables
>;
export const UpdateCounversationCountDocument = gql`
  subscription updateCounversationCount($channels: [ShortId!]!) {
    updateCounversationCount(channels: $channels) {
      event
      message {
        channel
        count
      }
    }
  }
`;
export type UpdateCounversationCountSubscriptionResult = Apollo.SubscriptionResult<UpdateCounversationCountSubscription>;
export const CreateChatMessageDocument = gql`
  mutation createChatMessage($data: ChatMessageInput!) {
    createChatMessage(data: $data) {
      status
      data {
        ...ChatFields
      }
      error {
        ...Error
      }
    }
  }
  ${ChatFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CreateChatMessageMutationFn = Apollo.MutationFunction<
  CreateChatMessageMutation,
  CreateChatMessageMutationVariables
>;
export type CreateChatMessageMutationResult = Apollo.MutationResult<CreateChatMessageMutation>;
export type CreateChatMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMessageMutation,
  CreateChatMessageMutationVariables
>;
export const EditMemberChatSeenDocument = gql`
  mutation editMemberChatSeen($senderId: ShortId!) {
    editMemberChatSeen(senderId: $senderId) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EditMemberChatSeenMutationFn = Apollo.MutationFunction<
  EditMemberChatSeenMutation,
  EditMemberChatSeenMutationVariables
>;
export type EditMemberChatSeenMutationResult = Apollo.MutationResult<EditMemberChatSeenMutation>;
export type EditMemberChatSeenMutationOptions = Apollo.BaseMutationOptions<
  EditMemberChatSeenMutation,
  EditMemberChatSeenMutationVariables
>;
export const ChatHistoryDocument = gql`
  query chatHistory($member: ShortId!, $params: ConnectionInput!) {
    chatHistory(member: $member, params: $params) {
      status
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...ChatFields
          }
        }
      }
      error {
        ...Error
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${ChatFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ChatHistoryQueryResult = Apollo.QueryResult<
  ChatHistoryQuery,
  ChatHistoryQueryVariables
>;
export const WorkspaceChatDocument = gql`
  subscription workspaceChat {
    workspaceChat {
      event
      message {
        ...ChatFields
      }
    }
  }
  ${ChatFieldsFragmentDoc}
`;
export type WorkspaceChatSubscriptionResult = Apollo.SubscriptionResult<WorkspaceChatSubscription>;
export const AddContactDocument = gql`
  mutation addContact($data: ContactInputData!) {
    addContact(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        country
        company
        number
        address
        name
        visibility
        tags {
          ...TagFields
        }
        notes {
          id
          title
        }
        email
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export type AddContactMutationFn = Apollo.MutationFunction<
  AddContactMutation,
  AddContactMutationVariables
>;
export type AddContactMutationResult = Apollo.MutationResult<AddContactMutation>;
export type AddContactMutationOptions = Apollo.BaseMutationOptions<
  AddContactMutation,
  AddContactMutationVariables
>;
export const EditContactDocument = gql`
  mutation editContact($id: ShortId!, $data: EditContactInputData!) {
    editContact(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        country
        name
        address
        company
        number
        visibility
        email
        tags {
          ...TagFields
        }
        notes {
          id
          title
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export type EditContactMutationFn = Apollo.MutationFunction<
  EditContactMutation,
  EditContactMutationVariables
>;
export type EditContactMutationResult = Apollo.MutationResult<EditContactMutation>;
export type EditContactMutationOptions = Apollo.BaseMutationOptions<
  EditContactMutation,
  EditContactMutationVariables
>;
export const AddNoteByContactDocument = gql`
  mutation addNoteByContact($contact: String!, $data: NoteInputData!) {
    addNoteByContact(contact: $contact, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        title
        client {
          ...ClientFields
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${ClientFieldsFragmentDoc}
`;
export type AddNoteByContactMutationFn = Apollo.MutationFunction<
  AddNoteByContactMutation,
  AddNoteByContactMutationVariables
>;
export type AddNoteByContactMutationResult = Apollo.MutationResult<AddNoteByContactMutation>;
export type AddNoteByContactMutationOptions = Apollo.BaseMutationOptions<
  AddNoteByContactMutation,
  AddNoteByContactMutationVariables
>;
export const EditNoteDocument = gql`
  mutation editNote($id: ShortId!, $data: EditNoteInputData!) {
    editNote(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        title
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EditNoteMutationFn = Apollo.MutationFunction<
  EditNoteMutation,
  EditNoteMutationVariables
>;
export type EditNoteMutationResult = Apollo.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = Apollo.BaseMutationOptions<
  EditNoteMutation,
  EditNoteMutationVariables
>;
export const RemoveNoteDocument = gql`
  mutation removeNote($id: ShortId!) {
    removeNote(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
        title
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveNoteMutationFn = Apollo.MutationFunction<
  RemoveNoteMutation,
  RemoveNoteMutationVariables
>;
export type RemoveNoteMutationResult = Apollo.MutationResult<RemoveNoteMutation>;
export type RemoveNoteMutationOptions = Apollo.BaseMutationOptions<
  RemoveNoteMutation,
  RemoveNoteMutationVariables
>;
export const AddTagDocument = gql`
  mutation addTag($data: TagInputData!) {
    addTag(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...TagResponseFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TagResponseFieldsFragmentDoc}
`;
export type AddTagMutationFn = Apollo.MutationFunction<AddTagMutation, AddTagMutationVariables>;
export type AddTagMutationResult = Apollo.MutationResult<AddTagMutation>;
export type AddTagMutationOptions = Apollo.BaseMutationOptions<
  AddTagMutation,
  AddTagMutationVariables
>;
export const RemoveTagDocument = gql`
  mutation removeTag($id: ShortId!) {
    removeTag(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveTagMutationFn = Apollo.MutationFunction<
  RemoveTagMutation,
  RemoveTagMutationVariables
>;
export type RemoveTagMutationResult = Apollo.MutationResult<RemoveTagMutation>;
export type RemoveTagMutationOptions = Apollo.BaseMutationOptions<
  RemoveTagMutation,
  RemoveTagMutationVariables
>;
export const RemoveContactTagDocument = gql`
  mutation removeContactTag($id: ShortId!, $data: RemoveContactTagInputData!) {
    removeContactTag(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveContactTagMutationFn = Apollo.MutationFunction<
  RemoveContactTagMutation,
  RemoveContactTagMutationVariables
>;
export type RemoveContactTagMutationResult = Apollo.MutationResult<RemoveContactTagMutation>;
export type RemoveContactTagMutationOptions = Apollo.BaseMutationOptions<
  RemoveContactTagMutation,
  RemoveContactTagMutationVariables
>;
export const EditTagDocument = gql`
  mutation editTag($id: ShortId!, $data: EditTagInputData!) {
    editTag(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EditTagMutationFn = Apollo.MutationFunction<EditTagMutation, EditTagMutationVariables>;
export type EditTagMutationResult = Apollo.MutationResult<EditTagMutation>;
export type EditTagMutationOptions = Apollo.BaseMutationOptions<
  EditTagMutation,
  EditTagMutationVariables
>;
export const BlockNumberDocument = gql`
  mutation blockNumber($number: String!, $data: BlockNumberInputData!) {
    blockNumber(number: $number, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        blocked
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BlockNumberMutationFn = Apollo.MutationFunction<
  BlockNumberMutation,
  BlockNumberMutationVariables
>;
export type BlockNumberMutationResult = Apollo.MutationResult<BlockNumberMutation>;
export type BlockNumberMutationOptions = Apollo.BaseMutationOptions<
  BlockNumberMutation,
  BlockNumberMutationVariables
>;
export const BlockContactDocument = gql`
  mutation blockContact($id: ShortId!, $data: BlockContactInputData!) {
    blockContact(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        blocked
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BlockContactMutationFn = Apollo.MutationFunction<
  BlockContactMutation,
  BlockContactMutationVariables
>;
export type BlockContactMutationResult = Apollo.MutationResult<BlockContactMutation>;
export type BlockContactMutationOptions = Apollo.BaseMutationOptions<
  BlockContactMutation,
  BlockContactMutationVariables
>;
export const BlockContactsDocument = gql`
  mutation blockContacts($data: BlockContactsInputData!) {
    blockContacts(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BlockContactsMutationFn = Apollo.MutationFunction<
  BlockContactsMutation,
  BlockContactsMutationVariables
>;
export type BlockContactsMutationResult = Apollo.MutationResult<BlockContactsMutation>;
export type BlockContactsMutationOptions = Apollo.BaseMutationOptions<
  BlockContactsMutation,
  BlockContactsMutationVariables
>;
export const DeleteContactsDocument = gql`
  mutation deleteContacts($data: DeleteContactsInputData!) {
    deleteContacts(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type DeleteContactsMutationFn = Apollo.MutationFunction<
  DeleteContactsMutation,
  DeleteContactsMutationVariables
>;
export type DeleteContactsMutationResult = Apollo.MutationResult<DeleteContactsMutation>;
export type DeleteContactsMutationOptions = Apollo.BaseMutationOptions<
  DeleteContactsMutation,
  DeleteContactsMutationVariables
>;
export const UploadContactDocument = gql`
  mutation uploadContact($data: ContactCsvInputData!) {
    uploadContact(data: $data) {
      status
      data {
        totalRecords
        savedRecords
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UploadContactMutationFn = Apollo.MutationFunction<
  UploadContactMutation,
  UploadContactMutationVariables
>;
export type UploadContactMutationResult = Apollo.MutationResult<UploadContactMutation>;
export type UploadContactMutationOptions = Apollo.BaseMutationOptions<
  UploadContactMutation,
  UploadContactMutationVariables
>;
export const UpdateClientDndDocument = gql`
  mutation updateClientDND($data: ClientDNDInput!) {
    updateClientDND(data: $data) {
      status
      data {
        ...ContactFields
      }
      error {
        ...Error
      }
    }
  }
  ${ContactFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateClientDndMutationFn = Apollo.MutationFunction<
  UpdateClientDndMutation,
  UpdateClientDndMutationVariables
>;
export type UpdateClientDndMutationResult = Apollo.MutationResult<UpdateClientDndMutation>;
export type UpdateClientDndMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientDndMutation,
  UpdateClientDndMutationVariables
>;
export const GreetingsDocument = gql`
  mutation greetings($channel: ShortId!, $data: GreetingInputData!) {
    greetings(channel: $channel, data: $data) {
      status
      data {
        id
        recordingUrl
        greetingType
        recordingType
        source
        status
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GreetingsMutationFn = Apollo.MutationFunction<
  GreetingsMutation,
  GreetingsMutationVariables
>;
export type GreetingsMutationResult = Apollo.MutationResult<GreetingsMutation>;
export type GreetingsMutationOptions = Apollo.BaseMutationOptions<
  GreetingsMutation,
  GreetingsMutationVariables
>;
export const GreetingsToggleDocument = gql`
  mutation greetingsToggle($channel: ShortId!, $data: GreetingToggleInputData!) {
    greetingsToggle(channel: $channel, data: $data) {
      status
      data {
        greetingType
        recordingType
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GreetingsToggleMutationFn = Apollo.MutationFunction<
  GreetingsToggleMutation,
  GreetingsToggleMutationVariables
>;
export type GreetingsToggleMutationResult = Apollo.MutationResult<GreetingsToggleMutation>;
export type GreetingsToggleMutationOptions = Apollo.BaseMutationOptions<
  GreetingsToggleMutation,
  GreetingsToggleMutationVariables
>;
export const AutoReplyDocument = gql`
  mutation autoReply($data: AutoReplyInputData!) {
    autoReply(data: $data) {
      status
      data {
        id
        channelId
        createdBy
        message
        status
        autoreplyType
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AutoReplyMutationFn = Apollo.MutationFunction<
  AutoReplyMutation,
  AutoReplyMutationVariables
>;
export type AutoReplyMutationResult = Apollo.MutationResult<AutoReplyMutation>;
export type AutoReplyMutationOptions = Apollo.BaseMutationOptions<
  AutoReplyMutation,
  AutoReplyMutationVariables
>;
export const NewContactsDocument = gql`
  query newContacts($tags: [ShortId]) {
    newContacts(tags: $tags) {
      status
      error {
        ...Error
      }
      data {
        id
        email
        name
        profilePicture
        createdBy
        tags {
          ...TagFields
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export type NewContactsQueryResult = Apollo.QueryResult<
  NewContactsQuery,
  NewContactsQueryVariables
>;
export const BlockedContactsDocument = gql`
  query blockedContacts {
    blockedContacts {
      status
      error {
        ...Error
      }
      data {
        id
        email
        name
        number
        profilePicture
        createdBy
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BlockedContactsQueryResult = Apollo.QueryResult<
  BlockedContactsQuery,
  BlockedContactsQueryVariables
>;
export const ContactsDocument = gql`
  query contacts($params: ConnectionInput, $tags: [ShortId]) {
    contacts(params: $params, tags: $tags) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            id
            email
            name
            profilePicture
            number
            tags {
              ...TagFields
            }
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export const ContactDocument = gql`
  query contact($id: ShortId!) {
    contact(id: $id) {
      status
      error {
        ...Error
      }
      data {
        ...ContactFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${ContactFieldsFragmentDoc}
`;
export type ContactQueryResult = Apollo.QueryResult<ContactQuery, ContactQueryVariables>;
export const ClientDetailDocument = gql`
  query clientDetail($number: String!) {
    clientDetail(number: $number) {
      status
      error {
        ...Error
      }
      data {
        ...ClientFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${ClientFieldsFragmentDoc}
`;
export type ClientDetailQueryResult = Apollo.QueryResult<
  ClientDetailQuery,
  ClientDetailQueryVariables
>;
export const ClientNotesDocument = gql`
  query clientNotes($contact: String!, $channel: ShortId!) {
    clientNotes(contact: $contact, channel: $channel) {
      status
      error {
        ...Error
      }
      data {
        id
        title
        createdAt
        userId
        firstName
        lastName
        profilePicture
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ClientNotesQueryResult = Apollo.QueryResult<
  ClientNotesQuery,
  ClientNotesQueryVariables
>;
export const TagsDocument = gql`
  query tags {
    tags {
      status
      error {
        ...Error
      }
      data {
        ...TagFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const AllTagsDocument = gql`
  query allTags {
    allTags {
      status
      error {
        ...Error
      }
      data {
        ...TagFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TagFieldsFragmentDoc}
`;
export type AllTagsQueryResult = Apollo.QueryResult<AllTagsQuery, AllTagsQueryVariables>;
export const AutoReplyDataDocument = gql`
  query autoReplyData($id: ShortId!) {
    autoReplyData(id: $id) {
      status
      error {
        ...Error
      }
      data {
        status
        message
        channelId
        autoreplyType
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AutoReplyDataQueryResult = Apollo.QueryResult<
  AutoReplyDataQuery,
  AutoReplyDataQueryVariables
>;
export const GreetingsListDocument = gql`
  query greetingsList($channel: ShortId!, $greetingType: GreetingType) {
    greetings(channel: $channel, greetingType: $greetingType) {
      status
      error {
        ...Error
      }
      data {
        id
        recordingUrl
        greetingType
        recordingType
        source
        voice
        accent
        status
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GreetingsListQueryResult = Apollo.QueryResult<
  GreetingsListQuery,
  GreetingsListQueryVariables
>;
export const LastContactedTimeDocument = gql`
  query lastContactedTime($contact: String!) {
    lastContactedTime(contact: $contact) {
      status
      error {
        ...Error
      }
      data {
        createdAt
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type LastContactedTimeQueryResult = Apollo.QueryResult<
  LastContactedTimeQuery,
  LastContactedTimeQueryVariables
>;
export const GetContactsLogsDocument = gql`
  query getContactsLogs($params: ConnectionInput) {
    getContactsLogs(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            clientNumber
            clientInfo {
              ...ClientInfoFields
            }
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${ClientInfoFieldsFragmentDoc}
`;
export type GetContactsLogsQueryResult = Apollo.QueryResult<
  GetContactsLogsQuery,
  GetContactsLogsQueryVariables
>;
export const AddWorkspaceDocument = gql`
  mutation addWorkspace($data: WorkspaceInputData!) {
    addWorkspace(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        memberId
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddWorkspaceMutationFn = Apollo.MutationFunction<
  AddWorkspaceMutation,
  AddWorkspaceMutationVariables
>;
export type AddWorkspaceMutationResult = Apollo.MutationResult<AddWorkspaceMutation>;
export type AddWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  AddWorkspaceMutation,
  AddWorkspaceMutationVariables
>;
export const WorkspaceSubscriptionDocument = gql`
  mutation workspaceSubscription($data: WorkspaceSubscriptionInputData!) {
    workspaceSubscription(data: $data) {
      status
      error {
        ...Error
      }
      data {
        workspaceId
        memberId
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type WorkspaceSubscriptionMutationFn = Apollo.MutationFunction<
  WorkspaceSubscriptionMutation,
  WorkspaceSubscriptionMutationVariables
>;
export type WorkspaceSubscriptionMutationResult = Apollo.MutationResult<WorkspaceSubscriptionMutation>;
export type WorkspaceSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  WorkspaceSubscriptionMutation,
  WorkspaceSubscriptionMutationVariables
>;
export const EditWorkspaceDocument = gql`
  mutation editWorkspace($data: EditWorkspaceInputData!) {
    editWorkspace(data: $data) {
      status
      data {
        id
        title
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EditWorkspaceMutationFn = Apollo.MutationFunction<
  EditWorkspaceMutation,
  EditWorkspaceMutationVariables
>;
export type EditWorkspaceMutationResult = Apollo.MutationResult<EditWorkspaceMutation>;
export type EditWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  EditWorkspaceMutation,
  EditWorkspaceMutationVariables
>;
export const WorkspaceOrderDocument = gql`
  mutation workspaceOrder($data: WorkspaceOrderInputData!) {
    workspaceOrder(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type WorkspaceOrderMutationFn = Apollo.MutationFunction<
  WorkspaceOrderMutation,
  WorkspaceOrderMutationVariables
>;
export type WorkspaceOrderMutationResult = Apollo.MutationResult<WorkspaceOrderMutation>;
export type WorkspaceOrderMutationOptions = Apollo.BaseMutationOptions<
  WorkspaceOrderMutation,
  WorkspaceOrderMutationVariables
>;
export const EditNotificationDocument = gql`
  mutation editNotification($id: ShortId!, $data: EditNotificationInputData!) {
    editNotification(id: $id, data: $data) {
      status
      data {
        id
        notification
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EditNotificationMutationFn = Apollo.MutationFunction<
  EditNotificationMutation,
  EditNotificationMutationVariables
>;
export type EditNotificationMutationResult = Apollo.MutationResult<EditNotificationMutation>;
export type EditNotificationMutationOptions = Apollo.BaseMutationOptions<
  EditNotificationMutation,
  EditNotificationMutationVariables
>;
export const RemoveWorkspaceDocument = gql`
  mutation removeWorkspace($id: ShortId!) {
    removeWorkspace(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveWorkspaceMutationFn = Apollo.MutationFunction<
  RemoveWorkspaceMutation,
  RemoveWorkspaceMutationVariables
>;
export type RemoveWorkspaceMutationResult = Apollo.MutationResult<RemoveWorkspaceMutation>;
export type RemoveWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkspaceMutation,
  RemoveWorkspaceMutationVariables
>;
export const InviteMemberDocument = gql`
  mutation inviteMember($data: InviteMemberInputData!) {
    inviteMember(data: $data) {
      status
      error {
        ...Error
      }
      data {
        message
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type InviteMemberMutationFn = Apollo.MutationFunction<
  InviteMemberMutation,
  InviteMemberMutationVariables
>;
export type InviteMemberMutationResult = Apollo.MutationResult<InviteMemberMutation>;
export type InviteMemberMutationOptions = Apollo.BaseMutationOptions<
  InviteMemberMutation,
  InviteMemberMutationVariables
>;
export const ResendEmailVerificationLinkDocument = gql`
  mutation resendEmailVerificationLink($data: EmailVerificationInputData!) {
    resendEmailVerificationLink(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ResendEmailVerificationLinkMutationFn = Apollo.MutationFunction<
  ResendEmailVerificationLinkMutation,
  ResendEmailVerificationLinkMutationVariables
>;
export type ResendEmailVerificationLinkMutationResult = Apollo.MutationResult<ResendEmailVerificationLinkMutation>;
export type ResendEmailVerificationLinkMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailVerificationLinkMutation,
  ResendEmailVerificationLinkMutationVariables
>;
export const AcceptInviteDocument = gql`
  mutation acceptInvite($data: InviteAcceptInput!) {
    acceptInvite(data: $data) {
      data {
        authToken
        accessToken
        refreshToken
        workspace
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AcceptInviteMutationFn = Apollo.MutationFunction<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;
export const AcceptUserInviteDocument = gql`
  mutation acceptUserInvite($data: UserInviteAcceptInput!) {
    acceptUserInvite(data: $data) {
      data {
        accessToken
        refreshToken
        workspace
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AcceptUserInviteMutationFn = Apollo.MutationFunction<
  AcceptUserInviteMutation,
  AcceptUserInviteMutationVariables
>;
export type AcceptUserInviteMutationResult = Apollo.MutationResult<AcceptUserInviteMutation>;
export type AcceptUserInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptUserInviteMutation,
  AcceptUserInviteMutationVariables
>;
export const ChangeDefaultLanguageDocument = gql`
  mutation changeDefaultLanguage($data: ChangeDefaultLanguage!) {
    changeDefaultLanguage(data: $data) {
      status
      data {
        defaultLanguage
        defaultWorkspace
        email
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangeDefaultLanguageMutationFn = Apollo.MutationFunction<
  ChangeDefaultLanguageMutation,
  ChangeDefaultLanguageMutationVariables
>;
export type ChangeDefaultLanguageMutationResult = Apollo.MutationResult<ChangeDefaultLanguageMutation>;
export type ChangeDefaultLanguageMutationOptions = Apollo.BaseMutationOptions<
  ChangeDefaultLanguageMutation,
  ChangeDefaultLanguageMutationVariables
>;
export const ChangeDefaultTimezoneDocument = gql`
  mutation changeDefaultTimezone($data: ChangeDefaultTimezone!) {
    changeDefaultTimezone(data: $data) {
      status
      data {
        defaultLanguage
        defaultTimezone
        defaultWorkspace
        email
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangeDefaultTimezoneMutationFn = Apollo.MutationFunction<
  ChangeDefaultTimezoneMutation,
  ChangeDefaultTimezoneMutationVariables
>;
export type ChangeDefaultTimezoneMutationResult = Apollo.MutationResult<ChangeDefaultTimezoneMutation>;
export type ChangeDefaultTimezoneMutationOptions = Apollo.BaseMutationOptions<
  ChangeDefaultTimezoneMutation,
  ChangeDefaultTimezoneMutationVariables
>;
export const EditMemberStatusDocument = gql`
  mutation editMemberStatus($uid: ShortId!, $data: EditMemberStatusInputData!) {
    editMemberStatus(id: $uid, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EditMemberStatusMutationFn = Apollo.MutationFunction<
  EditMemberStatusMutation,
  EditMemberStatusMutationVariables
>;
export type EditMemberStatusMutationResult = Apollo.MutationResult<EditMemberStatusMutation>;
export type EditMemberStatusMutationOptions = Apollo.BaseMutationOptions<
  EditMemberStatusMutation,
  EditMemberStatusMutationVariables
>;
export const EditMemberRoleDocument = gql`
  mutation editMemberRole($uid: ShortId!, $data: EditMemberRoleInputData!) {
    editMemberRole(id: $uid, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        role
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EditMemberRoleMutationFn = Apollo.MutationFunction<
  EditMemberRoleMutation,
  EditMemberRoleMutationVariables
>;
export type EditMemberRoleMutationResult = Apollo.MutationResult<EditMemberRoleMutation>;
export type EditMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  EditMemberRoleMutation,
  EditMemberRoleMutationVariables
>;
export const ResendInviteDocument = gql`
  mutation resendInvite($data: InviteCode!) {
    resendInvite(data: $data) {
      data {
        message
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ResendInviteMutationFn = Apollo.MutationFunction<
  ResendInviteMutation,
  ResendInviteMutationVariables
>;
export type ResendInviteMutationResult = Apollo.MutationResult<ResendInviteMutation>;
export type ResendInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteMutation,
  ResendInviteMutationVariables
>;
export const DeleteInvitationDocument = gql`
  mutation deleteInvitation($link: String!) {
    deleteInvitation(link: $link) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables
>;
export const ChangeWorkspacePhotoDocument = gql`
  mutation changeWorkspacePicture($photoUpload: Upload!) {
    changeWorkspacePicture(photoUpload: $photoUpload) {
      status
      data {
        id
        title
        photo
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangeWorkspacePhotoMutationFn = Apollo.MutationFunction<
  ChangeWorkspacePhotoMutation,
  ChangeWorkspacePhotoMutationVariables
>;
export type ChangeWorkspacePhotoMutationResult = Apollo.MutationResult<ChangeWorkspacePhotoMutation>;
export type ChangeWorkspacePhotoMutationOptions = Apollo.BaseMutationOptions<
  ChangeWorkspacePhotoMutation,
  ChangeWorkspacePhotoMutationVariables
>;
export const RestoreWorkspaceDocument = gql`
  mutation restoreWorkspace($id: ShortId!) {
    restoreWorkspace(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
        title
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RestoreWorkspaceMutationFn = Apollo.MutationFunction<
  RestoreWorkspaceMutation,
  RestoreWorkspaceMutationVariables
>;
export type RestoreWorkspaceMutationResult = Apollo.MutationResult<RestoreWorkspaceMutation>;
export type RestoreWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  RestoreWorkspaceMutation,
  RestoreWorkspaceMutationVariables
>;
export const WorkspaceDocument = gql`
  query workspace {
    workspace {
      status
      error {
        ...Error
      }
      data {
        ...WorkspaceFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${WorkspaceFieldsFragmentDoc}
`;
export type WorkspaceQueryResult = Apollo.QueryResult<WorkspaceQuery, WorkspaceQueryVariables>;
export const WorkspacesDocument = gql`
  query workspaces {
    workspaces {
      status
      error {
        ...Error
      }
      data {
        id
        title
        memberId
        photo
        status
        order
        plan {
          remainingDays
          subscriptionActive
          currentCredit
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type WorkspacesQueryResult = Apollo.QueryResult<WorkspacesQuery, WorkspacesQueryVariables>;
export const InviteMemberEmailExistsDocument = gql`
  query inviteMemberEmailExists($email: String!) {
    inviteMemberEmailExists(email: $email) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type InviteMemberEmailExistsQueryResult = Apollo.QueryResult<
  InviteMemberEmailExistsQuery,
  InviteMemberEmailExistsQueryVariables
>;
export const InviteDetailDocument = gql`
  query inviteDetail($invitationCode: String!) {
    inviteDetail(invitationCode: $invitationCode) {
      status
      data {
        email
        workspace {
          id
          name
          image
          role
        }
        invitationType
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type InviteDetailQueryResult = Apollo.QueryResult<
  InviteDetailQuery,
  InviteDetailQueryVariables
>;
export const AllWorkspaceMembersDocument = gql`
  query allWorkspaceMembers($pageParams: ConnectionInput!) {
    allWorkspaceMembers(pageParams: $pageParams) {
      data {
        edges {
          cursor
          node {
            ...MemberNodeFields
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      error {
        ...Error
      }
      status
    }
  }
  ${MemberNodeFieldsFragmentDoc}
  ${PageInfoFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AllWorkspaceMembersQueryResult = Apollo.QueryResult<
  AllWorkspaceMembersQuery,
  AllWorkspaceMembersQueryVariables
>;
export const AllInvitesDocument = gql`
  query allInvites($pageParams: ConnectionInput) {
    allInvites(pageParams: $pageParams) {
      data {
        edges {
          cursor
          node {
            ...InviteNodeFields
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      error {
        ...Error
      }
      status
    }
  }
  ${InviteNodeFieldsFragmentDoc}
  ${PageInfoFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AllInvitesQueryResult = Apollo.QueryResult<AllInvitesQuery, AllInvitesQueryVariables>;
export const AllTimeZonesDocument = gql`
  query allTimeZones {
    allTimeZones {
      status
      error {
        ...Error
      }
      data {
        value
        abbr
        offset
        isdst
        text
        utc
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AllTimeZonesQueryResult = Apollo.QueryResult<
  AllTimeZonesQuery,
  AllTimeZonesQueryVariables
>;
export const ConfigureGoogleContactDocument = gql`
  mutation configureGoogleContact {
    configureGoogleContact {
      status
      data {
        auth_url
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ConfigureGoogleContactMutationFn = Apollo.MutationFunction<
  ConfigureGoogleContactMutation,
  ConfigureGoogleContactMutationVariables
>;
export type ConfigureGoogleContactMutationResult = Apollo.MutationResult<ConfigureGoogleContactMutation>;
export type ConfigureGoogleContactMutationOptions = Apollo.BaseMutationOptions<
  ConfigureGoogleContactMutation,
  ConfigureGoogleContactMutationVariables
>;
export const IntegrateGoogleContactDocument = gql`
  mutation integrateGoogleContact($data: GoogleOauthResponse!) {
    integrateGoogleContact(data: $data) {
      status
      data {
        totalRecords
        savedRecords
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type IntegrateGoogleContactMutationFn = Apollo.MutationFunction<
  IntegrateGoogleContactMutation,
  IntegrateGoogleContactMutationVariables
>;
export type IntegrateGoogleContactMutationResult = Apollo.MutationResult<IntegrateGoogleContactMutation>;
export type IntegrateGoogleContactMutationOptions = Apollo.BaseMutationOptions<
  IntegrateGoogleContactMutation,
  IntegrateGoogleContactMutationVariables
>;
export const DeleteGoogleContactIntegrationDocument = gql`
  mutation deleteGoogleContactIntegration($id: ShortId!) {
    deleteGoogleContactIntegration(id: $id) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type DeleteGoogleContactIntegrationMutationFn = Apollo.MutationFunction<
  DeleteGoogleContactIntegrationMutation,
  DeleteGoogleContactIntegrationMutationVariables
>;
export type DeleteGoogleContactIntegrationMutationResult = Apollo.MutationResult<DeleteGoogleContactIntegrationMutation>;
export type DeleteGoogleContactIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteGoogleContactIntegrationMutation,
  DeleteGoogleContactIntegrationMutationVariables
>;
export const ResyncGoogleContactIntegrationDocument = gql`
  mutation resyncGoogleContactIntegration($id: ShortId!) {
    resyncGoogleContactIntegration(id: $id) {
      status
      data {
        totalRecords
        savedRecords
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ResyncGoogleContactIntegrationMutationFn = Apollo.MutationFunction<
  ResyncGoogleContactIntegrationMutation,
  ResyncGoogleContactIntegrationMutationVariables
>;
export type ResyncGoogleContactIntegrationMutationResult = Apollo.MutationResult<ResyncGoogleContactIntegrationMutation>;
export type ResyncGoogleContactIntegrationMutationOptions = Apollo.BaseMutationOptions<
  ResyncGoogleContactIntegrationMutation,
  ResyncGoogleContactIntegrationMutationVariables
>;
export const GoogleContactIntegrationListDocument = gql`
  query googleContactIntegrationList {
    googleContactIntegrationList {
      status
      error {
        ...Error
      }
      data {
        id
        email
        totalContactImported
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GoogleContactIntegrationListQueryResult = Apollo.QueryResult<
  GoogleContactIntegrationListQuery,
  GoogleContactIntegrationListQueryVariables
>;
export const AddplanRestrictionDataDocument = gql`
  mutation addplanRestrictionData($data: PlanRestrictionInputData!) {
    addPlanRestriction(data: $data) {
      status
      data
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddplanRestrictionDataMutationFn = Apollo.MutationFunction<
  AddplanRestrictionDataMutation,
  AddplanRestrictionDataMutationVariables
>;
export type AddplanRestrictionDataMutationResult = Apollo.MutationResult<AddplanRestrictionDataMutation>;
export type AddplanRestrictionDataMutationOptions = Apollo.BaseMutationOptions<
  AddplanRestrictionDataMutation,
  AddplanRestrictionDataMutationVariables
>;
export const PlanRestrictionDataDocument = gql`
  query planRestrictionData {
    planRestrictionData {
      status
      error {
        code
        message
      }
      data
    }
  }
`;
export type PlanRestrictionDataQueryResult = Apollo.QueryResult<
  PlanRestrictionDataQuery,
  PlanRestrictionDataQueryVariables
>;
export const UpdateNotificationSettingsDocument = gql`
  mutation updateNotificationSettings($data: UpdateNotificationsInputData!) {
    updateNotificationSettings(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...NotificationFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${NotificationFieldsFragmentDoc}
`;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>;
export const NotificationSettingsDocument = gql`
  query notificationSettings {
    notificationSettings {
      status
      error {
        ...Error
      }
      data {
        ...NotificationFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${NotificationFieldsFragmentDoc}
`;
export type NotificationSettingsQueryResult = Apollo.QueryResult<
  NotificationSettingsQuery,
  NotificationSettingsQueryVariables
>;
export const MemberDetailDocument = gql`
  query memberDetail($id: ShortId!) {
    memberDetail(id: $id) {
      status
      data {
        ...MemberFields
      }
      error {
        ...Error
      }
    }
  }
  ${MemberFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MemberDetailQueryResult = Apollo.QueryResult<
  MemberDetailQuery,
  MemberDetailQueryVariables
>;
export const TeamMembersListDocument = gql`
  query teamMembersList($id: ShortId!, $params: ConnectionInput) {
    teamMembersList(id: $id, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...TeamMemberFields
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
  ${TeamMemberFieldsFragmentDoc}
`;
export type TeamMembersListQueryResult = Apollo.QueryResult<
  TeamMembersListQuery,
  TeamMembersListQueryVariables
>;
export const OnlineMemberStatusDocument = gql`
  subscription onlineMemberStatus($workspace: ShortId!) {
    onlineMemberStatus(workspace: $workspace) {
      event
      message {
        id
        online
      }
    }
  }
`;
export type OnlineMemberStatusSubscriptionResult = Apollo.SubscriptionResult<OnlineMemberStatusSubscription>;
export const AddTeamDocument = gql`
  mutation addTeam($data: TeamInputData!) {
    addTeam(data: $data) {
      status
      data {
        ...TeamFields
      }
      error {
        ...Error
      }
    }
  }
  ${TeamFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AddTeamMutationFn = Apollo.MutationFunction<AddTeamMutation, AddTeamMutationVariables>;
export type AddTeamMutationResult = Apollo.MutationResult<AddTeamMutation>;
export type AddTeamMutationOptions = Apollo.BaseMutationOptions<
  AddTeamMutation,
  AddTeamMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation updateTeam($id: ShortId!, $data: UpdateTeamInputData!) {
    updateTeam(id: $id, data: $data) {
      status
      data {
        ...TeamFields
      }
      error {
        ...Error
      }
    }
  }
  ${TeamFieldsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const RemoveTeamDocument = gql`
  mutation removeTeam($id: ShortId!, $data: RemoveTeamInputData!) {
    removeTeam(data: $data, id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveTeamMutationFn = Apollo.MutationFunction<
  RemoveTeamMutation,
  RemoveTeamMutationVariables
>;
export type RemoveTeamMutationResult = Apollo.MutationResult<RemoveTeamMutation>;
export type RemoveTeamMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeamMutation,
  RemoveTeamMutationVariables
>;
export const RemoveMemberDocument = gql`
  mutation removeMember($id: ShortId!, $data: RemoveTeammemberInputData!) {
    removeMember(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        teamMembers {
          ...TeamMembersNode
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TeamMembersNodeFragmentDoc}
`;
export type RemoveMemberMutationFn = Apollo.MutationFunction<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberMutation,
  RemoveMemberMutationVariables
>;
export const TeamsDocument = gql`
  query teams {
    teams {
      status
      error {
        ...Error
      }
      data {
        ...TeamFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TeamFieldsFragmentDoc}
`;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
export const TeamDocument = gql`
  query team($id: String!) {
    team(id: $id) {
      status
      error {
        ...Error
      }
      data {
        ...TeamFields
      }
    }
  }
  ${ErrorFragmentDoc}
  ${TeamFieldsFragmentDoc}
`;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const AddSetupIntentDocument = gql`
  mutation addSetupIntent {
    addSetupIntent {
      status
      data {
        clientSecret
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddSetupIntentMutationFn = Apollo.MutationFunction<
  AddSetupIntentMutation,
  AddSetupIntentMutationVariables
>;
export type AddSetupIntentMutationResult = Apollo.MutationResult<AddSetupIntentMutation>;
export type AddSetupIntentMutationOptions = Apollo.BaseMutationOptions<
  AddSetupIntentMutation,
  AddSetupIntentMutationVariables
>;
export const CreatePaymentIntentDocument = gql`
  mutation createPaymentIntent($data: PaymentIntentInputData!) {
    createPaymentIntent(data: $data) {
      status
      data {
        clientSecret
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutationVariables
>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutationVariables
>;
export const RemoveCardDocument = gql`
  mutation removeCard($data: RemoveCardInputData!) {
    removeCard(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveCardMutationFn = Apollo.MutationFunction<
  RemoveCardMutation,
  RemoveCardMutationVariables
>;
export type RemoveCardMutationResult = Apollo.MutationResult<RemoveCardMutation>;
export type RemoveCardMutationOptions = Apollo.BaseMutationOptions<
  RemoveCardMutation,
  RemoveCardMutationVariables
>;
export const MakePrimaryCardDocument = gql`
  mutation makePrimaryCard($data: makePrimaryCardInputData!) {
    makePrimaryCard(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MakePrimaryCardMutationFn = Apollo.MutationFunction<
  MakePrimaryCardMutation,
  MakePrimaryCardMutationVariables
>;
export type MakePrimaryCardMutationResult = Apollo.MutationResult<MakePrimaryCardMutation>;
export type MakePrimaryCardMutationOptions = Apollo.BaseMutationOptions<
  MakePrimaryCardMutation,
  MakePrimaryCardMutationVariables
>;
export const AddDefaultCardDocument = gql`
  mutation addDefaultCard($data: DefaultCardInputData!) {
    addDefaultCard(data: $data) {
      status
      data {
        clientSecret
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddDefaultCardMutationFn = Apollo.MutationFunction<
  AddDefaultCardMutation,
  AddDefaultCardMutationVariables
>;
export type AddDefaultCardMutationResult = Apollo.MutationResult<AddDefaultCardMutation>;
export type AddDefaultCardMutationOptions = Apollo.BaseMutationOptions<
  AddDefaultCardMutation,
  AddDefaultCardMutationVariables
>;
export const CardExistDocument = gql`
  mutation cardExist($data: cardExistInputData!) {
    cardExist(data: $data) {
      status
      data {
        cardInfo
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CardExistMutationFn = Apollo.MutationFunction<
  CardExistMutation,
  CardExistMutationVariables
>;
export type CardExistMutationResult = Apollo.MutationResult<CardExistMutation>;
export type CardExistMutationOptions = Apollo.BaseMutationOptions<
  CardExistMutation,
  CardExistMutationVariables
>;
export const StripePublicKeyDocument = gql`
  query stripePublicKey {
    stripePublicKey {
      status
      error {
        ...Error
      }
      data {
        publicKey
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type StripePublicKeyQueryResult = Apollo.QueryResult<
  StripePublicKeyQuery,
  StripePublicKeyQueryVariables
>;
export const CardsDocument = gql`
  query cards {
    cards {
      status
      data {
        id
        name
        expiryMonth
        expiryYear
        lastDigit
        brand
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CardsQueryResult = Apollo.QueryResult<CardsQuery, CardsQueryVariables>;
export const CallRatingsSupportDataDocument = gql`
  query callRatingsSupportData($params: ConnectionInput) {
    callRatingsSupportData(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            date
            number
            contactedNumber
            workspaceId
            workspaceName
            firstName
            lastName
            ratings
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export type CallRatingsSupportDataQueryResult = Apollo.QueryResult<
  CallRatingsSupportDataQuery,
  CallRatingsSupportDataQueryVariables
>;
export const ChangeEnterpriseSupportTicketStatusDocument = gql`
  mutation changeEnterpriseSupportTicketStatus($data: EnterpriseSupportInputData!) {
    changeEnterpriseSupportTicketStatus(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangeEnterpriseSupportTicketStatusMutationFn = Apollo.MutationFunction<
  ChangeEnterpriseSupportTicketStatusMutation,
  ChangeEnterpriseSupportTicketStatusMutationVariables
>;
export type ChangeEnterpriseSupportTicketStatusMutationResult = Apollo.MutationResult<ChangeEnterpriseSupportTicketStatusMutation>;
export type ChangeEnterpriseSupportTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeEnterpriseSupportTicketStatusMutation,
  ChangeEnterpriseSupportTicketStatusMutationVariables
>;
export const SetupPlanRateDocument = gql`
  mutation setupPlanRate($data: SetupPlanRateInputData!) {
    setupPlanRate(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SetupPlanRateMutationFn = Apollo.MutationFunction<
  SetupPlanRateMutation,
  SetupPlanRateMutationVariables
>;
export type SetupPlanRateMutationResult = Apollo.MutationResult<SetupPlanRateMutation>;
export type SetupPlanRateMutationOptions = Apollo.BaseMutationOptions<
  SetupPlanRateMutation,
  SetupPlanRateMutationVariables
>;
export const EnterpriseSupportDataDocument = gql`
  query enterpriseSupportData($params: ConnectionInput) {
    enterpriseSupportData(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ticketNumber
            workspaceId
            workspaceName
            firstName
            lastName
            plan
            ticketStatus
            rate
            issueDate
            closeDate
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export type EnterpriseSupportDataQueryResult = Apollo.QueryResult<
  EnterpriseSupportDataQuery,
  EnterpriseSupportDataQueryVariables
>;
export const ChangePortingSupportTicketStatusDocument = gql`
  mutation changePortingSupportTicketStatus($data: PortingSupportTicketStatusInputData!) {
    changePortingSupportTicketStatus(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangePortingSupportTicketStatusMutationFn = Apollo.MutationFunction<
  ChangePortingSupportTicketStatusMutation,
  ChangePortingSupportTicketStatusMutationVariables
>;
export type ChangePortingSupportTicketStatusMutationResult = Apollo.MutationResult<ChangePortingSupportTicketStatusMutation>;
export type ChangePortingSupportTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangePortingSupportTicketStatusMutation,
  ChangePortingSupportTicketStatusMutationVariables
>;
export const ChangePortingSupportNumberStatusDocument = gql`
  mutation changePortingSupportNumberStatus($data: PortingSupportNumberStatusInputData!) {
    changePortingSupportNumberStatus(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangePortingSupportNumberStatusMutationFn = Apollo.MutationFunction<
  ChangePortingSupportNumberStatusMutation,
  ChangePortingSupportNumberStatusMutationVariables
>;
export type ChangePortingSupportNumberStatusMutationResult = Apollo.MutationResult<ChangePortingSupportNumberStatusMutation>;
export type ChangePortingSupportNumberStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangePortingSupportNumberStatusMutation,
  ChangePortingSupportNumberStatusMutationVariables
>;
export const PortingSupportDataDocument = gql`
  query portingSupportData($params: ConnectionInput) {
    portingSupportData(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ticketNumber
            number
            workspaceId
            workspaceName
            firstName
            lastName
            plan
            ticketStatus
            portingNumberStatus
            issueDate
            closeDate
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export type PortingSupportDataQueryResult = Apollo.QueryResult<
  PortingSupportDataQuery,
  PortingSupportDataQueryVariables
>;
export const ChangePurchaseSupportTicketStatusDocument = gql`
  mutation changePurchaseSupportTicketStatus($data: PurchaseSupportTicketStatusInputData!) {
    changePurchaseSupportTicketStatus(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangePurchaseSupportTicketStatusMutationFn = Apollo.MutationFunction<
  ChangePurchaseSupportTicketStatusMutation,
  ChangePurchaseSupportTicketStatusMutationVariables
>;
export type ChangePurchaseSupportTicketStatusMutationResult = Apollo.MutationResult<ChangePurchaseSupportTicketStatusMutation>;
export type ChangePurchaseSupportTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangePurchaseSupportTicketStatusMutation,
  ChangePurchaseSupportTicketStatusMutationVariables
>;
export const ChangePurchaseSupportNumberStatusDocument = gql`
  mutation changePurchaseSupportNumberStatus($data: PurchaseSupportNumberStatusInputData!) {
    changePurchaseSupportNumberStatus(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangePurchaseSupportNumberStatusMutationFn = Apollo.MutationFunction<
  ChangePurchaseSupportNumberStatusMutation,
  ChangePurchaseSupportNumberStatusMutationVariables
>;
export type ChangePurchaseSupportNumberStatusMutationResult = Apollo.MutationResult<ChangePurchaseSupportNumberStatusMutation>;
export type ChangePurchaseSupportNumberStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangePurchaseSupportNumberStatusMutation,
  ChangePurchaseSupportNumberStatusMutationVariables
>;
export const PurchaseSupportDataDocument = gql`
  query purchaseSupportData($params: ConnectionInput) {
    purchaseSupportData(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ticketNumber
            number
            workspaceId
            workspaceName
            firstName
            lastName
            plan
            ticketStatus
            purchaseNumberStatus
            issueDate
            closeDate
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export type PurchaseSupportDataQueryResult = Apollo.QueryResult<
  PurchaseSupportDataQuery,
  PurchaseSupportDataQueryVariables
>;
export const ToggleUserActivationDocument = gql`
  mutation toggleUserActivation($data: UserActivationToggle!) {
    toggleUserActivation(data: $data) {
      status
      data {
        userId
        firstname
        lastname
        email
        status
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ToggleUserActivationMutationFn = Apollo.MutationFunction<
  ToggleUserActivationMutation,
  ToggleUserActivationMutationVariables
>;
export type ToggleUserActivationMutationResult = Apollo.MutationResult<ToggleUserActivationMutation>;
export type ToggleUserActivationMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserActivationMutation,
  ToggleUserActivationMutationVariables
>;
export const AllUsersDocument = gql`
  query allUsers($params: ConnectionInput) {
    allUsers(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            authId
            firstname
            lastname
            email
            profilePicture
            status
            workspaces {
              id
              title
              photo
              status
            }
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const ArchiveWorkspaceDocument = gql`
  mutation archiveWorkspace($data: ArchiveWorkspacesInputData!) {
    archiveWorkspace(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ArchiveWorkspaceMutationFn = Apollo.MutationFunction<
  ArchiveWorkspaceMutation,
  ArchiveWorkspaceMutationVariables
>;
export type ArchiveWorkspaceMutationResult = Apollo.MutationResult<ArchiveWorkspaceMutation>;
export type ArchiveWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  ArchiveWorkspaceMutation,
  ArchiveWorkspaceMutationVariables
>;
export const AddChannelSupportDocument = gql`
  mutation addChannelSupport($data: ChannelInputDataSupport!) {
    addChannelSupport(data: $data) {
      status
      data {
        id
        number
        numberSid
        sms
        call
        mms
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AddChannelSupportMutationFn = Apollo.MutationFunction<
  AddChannelSupportMutation,
  AddChannelSupportMutationVariables
>;
export type AddChannelSupportMutationResult = Apollo.MutationResult<AddChannelSupportMutation>;
export type AddChannelSupportMutationOptions = Apollo.BaseMutationOptions<
  AddChannelSupportMutation,
  AddChannelSupportMutationVariables
>;
export const ToggleSubscriptionDocument = gql`
  mutation toggleSubscription($data: SubscriptionActionToggle!) {
    toggleSubscription(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ToggleSubscriptionMutationFn = Apollo.MutationFunction<
  ToggleSubscriptionMutation,
  ToggleSubscriptionMutationVariables
>;
export type ToggleSubscriptionMutationResult = Apollo.MutationResult<ToggleSubscriptionMutation>;
export type ToggleSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ToggleSubscriptionMutation,
  ToggleSubscriptionMutationVariables
>;
export const GetAllWorkspacesDocument = gql`
  query getAllWorkspaces($params: ConnectionInput) {
    getAllWorkspaces(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            id
            title
            members {
              id
              name
            }
            status
            plan {
              remainingDays
              subscriptionActive
              currentCredit
              startedAt
              endedAt
              planDetail {
                title
                interval
              }
            }
            owner {
              firstname
              lastname
              email
            }
            channels {
              id
              number
              name
              status
            }
          }
        }
      }
    }
  }
  ${ErrorFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export type GetAllWorkspacesQueryResult = Apollo.QueryResult<
  GetAllWorkspacesQuery,
  GetAllWorkspacesQueryVariables
>;
export const LoginDocument = gql`
  mutation login($data: LoginInputData!) {
    login(data: $data) {
      status
      data {
        token
        intercomIdentity
        details {
          id
          workspaces {
            id
            memberId
            title
            role
            status
            plan {
              remainingDays
              subscriptionActive
            }
          }
          userProfile {
            ...UserProfile
          }
        }
      }
      error {
        ...Error
      }
    }
  }
  ${UserProfileFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const SignupDocument = gql`
  mutation signup($data: RegisterInputData!) {
    signup(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword($data: ChangePasswordInputData!) {
    changePassword(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      status
      error {
        ...Error
      }
      data {
        message
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      status
      data {
        message
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ChangeEmailDocument = gql`
  mutation changeEmail($data: ChangeEmailInputData!) {
    changeEmail(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ChangeEmailMutationFn = Apollo.MutationFunction<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation refreshToken {
    refreshToken {
      status
      error {
        ...Error
      }
      data {
        accessToken
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const MemberLoginDocument = gql`
  mutation memberLogin($data: MemberLoginInputData!) {
    memberLogin(data: $data) {
      status
      data {
        accessToken
        refreshToken
        cardInfo
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MemberLoginMutationFn = Apollo.MutationFunction<
  MemberLoginMutation,
  MemberLoginMutationVariables
>;
export type MemberLoginMutationResult = Apollo.MutationResult<MemberLoginMutation>;
export type MemberLoginMutationOptions = Apollo.BaseMutationOptions<
  MemberLoginMutation,
  MemberLoginMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation verifyEmail($data: VerifyEmailInput!) {
    verifyEmail(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const ChangeProfileNamesDocument = gql`
  mutation changeProfileNames($data: ChangeProfileNames!) {
    changeProfileNames(data: $data) {
      status
      data {
        ...UserProfile
      }
      error {
        ...Error
      }
    }
  }
  ${UserProfileFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ChangeProfileNamesMutationFn = Apollo.MutationFunction<
  ChangeProfileNamesMutation,
  ChangeProfileNamesMutationVariables
>;
export type ChangeProfileNamesMutationResult = Apollo.MutationResult<ChangeProfileNamesMutation>;
export type ChangeProfileNamesMutationOptions = Apollo.BaseMutationOptions<
  ChangeProfileNamesMutation,
  ChangeProfileNamesMutationVariables
>;
export const ChangeProfilePictureDocument = gql`
  mutation changeUserProfilePicture($photoUpload: Upload!) {
    changeUserProfilePicture(photoUpload: $photoUpload) {
      status
      data {
        ...UserProfile
      }
      error {
        ...Error
      }
    }
  }
  ${UserProfileFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ChangeProfilePictureMutationFn = Apollo.MutationFunction<
  ChangeProfilePictureMutation,
  ChangeProfilePictureMutationVariables
>;
export type ChangeProfilePictureMutationResult = Apollo.MutationResult<ChangeProfilePictureMutation>;
export type ChangeProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  ChangeProfilePictureMutation,
  ChangeProfilePictureMutationVariables
>;
export const DeviceRegisterDocument = gql`
  mutation deviceRegister($data: DeviceRegisterInputData!) {
    deviceRegister(data: $data) {
      status
      data {
        id
        version
        fcmToken
        platform
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type DeviceRegisterMutationFn = Apollo.MutationFunction<
  DeviceRegisterMutation,
  DeviceRegisterMutationVariables
>;
export type DeviceRegisterMutationResult = Apollo.MutationResult<DeviceRegisterMutation>;
export type DeviceRegisterMutationOptions = Apollo.BaseMutationOptions<
  DeviceRegisterMutation,
  DeviceRegisterMutationVariables
>;
export const UpdateOnlineStatusDocument = gql`
  mutation updateOnlineStatus($data: EditOnlineStatusInputData!) {
    updateOnlineStatus(data: $data) {
      status
      data {
        id
        onlineStatus
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdateOnlineStatusMutationFn = Apollo.MutationFunction<
  UpdateOnlineStatusMutation,
  UpdateOnlineStatusMutationVariables
>;
export type UpdateOnlineStatusMutationResult = Apollo.MutationResult<UpdateOnlineStatusMutation>;
export type UpdateOnlineStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnlineStatusMutation,
  UpdateOnlineStatusMutationVariables
>;
export const SetUserDndDocument = gql`
  mutation setUserDND($data: UserDNDInput!) {
    setUserDND(data: $data) {
      status
      data {
        dndEnabled
        dndEndtime
        dndRemainingTime
      }
      error {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SetUserDndMutationFn = Apollo.MutationFunction<
  SetUserDndMutation,
  SetUserDndMutationVariables
>;
export type SetUserDndMutationResult = Apollo.MutationResult<SetUserDndMutation>;
export type SetUserDndMutationOptions = Apollo.BaseMutationOptions<
  SetUserDndMutation,
  SetUserDndMutationVariables
>;
export const ProfileDocument = gql`
  query profile {
    profile {
      status
      error {
        ...Error
      }
      data {
        ...UserProfile
      }
    }
  }
  ${ErrorFragmentDoc}
  ${UserProfileFragmentDoc}
`;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const CheckDuplicateLoginDocument = gql`
  query checkDuplicateLogin($data: LoginInputData!) {
    checkDuplicateLogin(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CheckDuplicateLoginQueryResult = Apollo.QueryResult<
  CheckDuplicateLoginQuery,
  CheckDuplicateLoginQueryVariables
>;
export const UserProfileDocument = gql`
  subscription userProfile($user: ShortId!) {
    userProfile(user: $user) {
      event
      message {
        ...UserProfile
      }
    }
  }
  ${UserProfileFragmentDoc}
`;
export type UserProfileSubscriptionResult = Apollo.SubscriptionResult<UserProfileSubscription>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Connection: [
      'CallRatingSupportConnection',
      'CallerIdSupportConnection',
      'ContactConnection',
      'EnterpriseSupportConnection',
      'InviteConnection',
      'MemberConnection',
      'PortingSupportConnection',
      'PurchaseSupportConnection',
      'TeamMemberConnection',
      'UserListConnection',
      'WorkspaceConnection',
    ],
    Edge: [
      'CallRatingSupportEdge',
      'CallerIdSupportEdge',
      'ChatHisoryEdge',
      'ClientRecordingsEdge',
      'ContactEdge',
      'ConversationEdge',
      'EnterpriseSupportEdge',
      'InviteEdge',
      'MacrosEdge',
      'MemberEdge',
      'OffsetContactEdge',
      'PortingSupportEdge',
      'PurchaseSupportEdge',
      'TeamMemberEdge',
      'UserListEdge',
      'WorkspaceEdge',
    ],
    MembersOrInvites: ['MemberNode', 'InviteNode'],
    Node: [
      'ChatMessageNode',
      'ContactNode',
      'InviteNode',
      'MacrosNode',
      'MemberNode',
      'TeamMemberNode',
    ],
    OffsetConnection: ['OffsetContactConnection'],
  },
};
export default result;
