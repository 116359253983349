import { Crisp } from 'crisp-sdk-web';
import { useIntercom } from 'react-use-intercom';
import { datadogLogs } from '@datadog/browser-logs';
import { getAllUrlParams } from 'components/organisms/common/utils';

interface IworkspaceDetailProps {
  workspaceName: string;
  workspaceStatus: boolean;
  workspacePlan: string;
  subscriptionStatus: string;
}

export const useChatBot = () => {
  const crispId = process.env.REACT_APP_CRISP_WEBSITE_ID;
  const { show, hardShutdown } = useIntercom();

  // DEFAULT VALUE 24 HRS
  const timeIntervalToSendMsg =
    parseInt(process.env.REACT_APP_CRISP_PREFILLED_TEXT_SEND_INTERVAL || '86400', 10) * 1000;

  const preventSendMessage = (timestamp: number) => {
    const currentTime = Date.now();
    return currentTime - timestamp < timeIntervalToSendMsg;
  };

  const isCrispInjected = Crisp.isCrispInjected();

  const clearCrispLocalStorage = () => {
    const CRISP_LS_KEYS = Object.keys(localStorage).filter(key => key.includes('crisp'));
    CRISP_LS_KEYS?.forEach(name => localStorage?.removeItem(name));
  };

  const trackUtmCodes = () => {
    const urlParams = getAllUrlParams(window.location.search);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { utm_campaign, utm_content, utm_medium, utm_source } = urlParams || {};
    if (crispId) {
      Crisp.session.setData({
        utm_campaign: utm_campaign || '',
        utm_content: utm_content || '',
        utm_medium: utm_medium || '',
        utm_source: utm_source || '',
      });
    }
  };

  const resetCrispSession = () => {
    if (crispId) {
      if (process.env.NODE_ENV === 'development') {
        console.log('CrispId', crispId);
        console.log('Crisp', Crisp);
        console.log('Crisp session', Crisp.session);
      }
      Crisp?.session?.reset?.();
    }
    clearCrispLocalStorage();
  };
  const initCrispChat = (tokenId: string | undefined) => {
    try {
      if (crispId) {
        // if (process.env.REACT_APP_DEBUG) console.log('Initializing crisp chat');
        if (tokenId && isCrispInjected) {
          resetCrispSession();
        }
        Crisp.configure(crispId, { tokenId });
        trackUtmCodes();
        if (tokenId) {
          Crisp.setTokenId(tokenId);
        }

        if (isCrispInjected) {
          Crisp.session.reset();
        }
      }
    } catch (err: any) {
      console.error(err);
      datadogLogs?.logger?.error(`Unable to configure crisp chat`, {
        dataDogError: err,
        context: 'crisp chat',
      });
    }
  };

  const openChat = () => {
    if (crispId) {
      Crisp.chat.open();
      return;
    }
    show();
  };

  const verifyChat = () => {
    const sessionId = Crisp.session.getIdentifier();
    if (process.env.REACT_APP_DEBUG) {
      console.log('CRISP SESSIONID: ', sessionId);
    }
    const crispSessionSentMsgInfo = localStorage.getItem(`_kc-im-${sessionId}`);
    const parsedData = crispSessionSentMsgInfo && JSON.parse(crispSessionSentMsgInfo);
    const { kycVerifyMsgtSentAt } = parsedData || {};
    if (kycVerifyMsgtSentAt && preventSendMessage(kycVerifyMsgtSentAt)) {
      Crisp.chat.open();
      return;
    }
    Crisp.chat.open();
    Crisp.message.show(
      'text',
      `Hi there!,
        Welcome to KrispCall!
      `,
    );
    Crisp.message.show(
      'text',
      `To verify your identity (KYC), we have specific requirements that must be met for the submitted proof. Please ensure the following details are clearly visible in the photo or screenshot of the proof:

    1] Charge statement showing the transaction done for Krispcall showing the last four digits of card, name of bank and the card owner.
    2] Government issued ID card
    `,
    );
    show();
    localStorage.setItem(
      `_kc-im-${sessionId}`,
      JSON.stringify({ kycVerifyMsgtSentAt: new Date().getTime() }),
    );
  };

  const hideChat = () => {
    if (crispId) {
      Crisp.setVacationMode(true); // to completely hide chatbox
      return;
    }
    hardShutdown();
  };

  const updateCrispEmail = (email: string) => {
    if (crispId && email) Crisp.user.setEmail(email);
  };

  const updateCrispUserName = (name: string) => {
    if (crispId && name) Crisp.user.setNickname(name);
  };
  const updateCrispAvatar = (avatarUrl: string) => {
    if (crispId && avatarUrl) Crisp.user.setAvatar(avatarUrl);
  };
  const updateSessionUserId = (tokenId: string) => {
    if (crispId && tokenId) {
      Crisp.session.setData({
        user_id: tokenId,
      });
    }
  };
  const updateUserRole = (role: string) => {
    if (crispId && role) {
      Crisp.session.setData({
        role,
      });
    }
  };
  const updateUserWorkspaceDetails = ({
    workspaceName,
    workspaceStatus,
    workspacePlan,
    subscriptionStatus,
  }: IworkspaceDetailProps) => {
    if (crispId) {
      Crisp.session.setData({
        workspace_name: workspaceName,
        workspace_status: workspaceStatus,
        plan: workspacePlan,
        subscription_status: subscriptionStatus,
      });
    }
  };
  const updateNumberDetail = (totalNumbers: number) => {
    if (crispId) {
      Crisp.session.setData({
        numbers: totalNumbers,
      });
    }
  };

  const updateActiveNumberList = (allNumbers: string[]) => {
    const hasActiveNumbers = allNumbers?.length > 0;
    if (crispId) {
      Crisp.session.setData({
        active_numbers_list: hasActiveNumbers ? allNumbers.join(', ') : 0,
      });
    }
  };
  const updateMemberDetail = (numberOfMembers: number) => {
    if (crispId) {
      Crisp.session.setData({
        members: numberOfMembers,
      });
    }
  };

  const setCrispUser = (email: string, name: string, avatarUrl: string, tokenId: string) => {
    updateCrispEmail(email);
    updateCrispUserName(name);
    updateCrispAvatar(avatarUrl);
    updateSessionUserId(tokenId);
  };

  const setNonVoipSupportMessage = () => {
    Crisp.chat.open();
    Crisp.message.show(
      'text',
      `Hello! We’re excited to help you get started with your Non-VoIP number. Share your preferences or any questions, and we’ll assist right away!
    `,
    );

    show();
  };

  return {
    Crisp,
    initCrispChat,
    openChat,
    hideChat,
    setCrispUser,
    updateCrispEmail,
    updateCrispUserName,
    updateCrispAvatar,
    resetCrispSession,
    clearCrispLocalStorage,
    updateUserWorkspaceDetails,
    updateNumberDetail,
    updateMemberDetail,
    updateUserRole,
    updateActiveNumberList,
    verifyChat,
    setNonVoipSupportMessage,
  };
};
